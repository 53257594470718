/* eslint-disable no-console */
import * as Sentry from "@sentry/react"
import { EnvHelperClient } from "../envHelper"
import { EventData } from "./events"

export const initMixpanelIfNeeded = (() => {
	let mp: Promise<(import("mixpanel-browser").OverridedMixpanel)> | undefined = undefined
	return async () => {
		if (!mp) {
			mp = (async () => {
				try {
					const mixpanel = (await import("mixpanel-browser")).default
					if (EnvHelperClient.mixpanelToken) {
						mixpanel.init(EnvHelperClient.mixpanelToken)
						mixpanel.identify()
						mixpanel.people.increment("pageload")
					} else {
						console.warn("MixPanel token not provided. MixPanel tracking is disabled.")
					}
					return mixpanel
				} catch (e) {
					console.error("MixPanel failed to initialize", e)
					throw new Error("MixPanel failed to initialize")
				}
			})()
		}
		return await mp
	}
})()

export const trackEvent = async (eventData: EventData) => {
	try {
		const mp = await initMixpanelIfNeeded()

		const eventProps = {
			...eventData,
			buildId: EnvHelperClient.buildId,
		}
		if (EnvHelperClient.mixpanelToken) {
			mp.track(eventData.eventName, eventProps)
		} else {
			console.warn(`Mixpanel event: ${eventData.eventName}`, eventProps)
		}

	} catch (e) {
		console.error("MixPanel failed to track the event", eventData)
		Sentry.captureException(e)
	}
}

type UserInfo = {
	id: string,
	name?: string,
	email?: string,
}

export const addUserInfo = async (info: UserInfo) => {
	if (EnvHelperClient.mixpanelToken) {
		try {
			const mp = await initMixpanelIfNeeded()
			mp.identify(info.id)
			mp.people.set({
				$id: info.id,
				$name: info.name,
				$email: info.email,
			})
			mp.register({
				$id: info.id,
				$name: info.name,
				$email: info.email,
			})

		} catch (e) {
			console.error("MixPanel failed to add user info", e)
			Sentry.captureException(e)
		}
	}
}


export const resetUserInfo = async () => {
	if (EnvHelperClient.mixpanelToken) {
		try {
			const mp = await initMixpanelIfNeeded()

			mp.reset()

		} catch (e) {
			console.error("MixPanel failed to reset user info", e)
			Sentry.captureException(e)
		}
	}
}
//eslint-disable-next-line max-len
