import React from "react"
import Styles from "./RelatedActionTrigger.module.scss"
import { m } from "framer-motion"
import { relatedActionTriggersMotionProps } from "../RelatedActionsManager/Animations"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"

type Props = {
	label: string,
	onTrigger: () => void,
	isBeta?: boolean,
}

const RelatedActionTrigger = (props: Props) => {
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)

	return (
		<m.button
			disabled={areMessagesLoading}
			className={Styles.button} onClick={props.onTrigger} {...relatedActionTriggersMotionProps}>
			<span>{props.label}</span>
			{props.isBeta
				&& <span className={Styles.beta_badge}>Beta</span>
			}
		</m.button>
	)
}

export default RelatedActionTrigger
