export const EnvHelperClient = {
	buildId: import.meta.env.VITE_VERSION,
	envType: import.meta.env.VITE_ENV_TYPE,
	isLocalDev: import.meta.env.VITE_IS_LOCAL_DEV,
	apiUrl: import.meta.env.VITE_API_URL,
	authenticationConfig: JSON.parse(import.meta.env.VITE_AUTHENTICATION_CONFIG!),
	mixpanelToken: import.meta.env.VITE_MIXPANEL,
	assistantBackendRetryLimit: Number(import.meta.env.VITE_ASSISTANT_BACKEND_RETRY_LIMIT) || 10,
	alphaUserModalMessages: Number(import.meta.env.VITE_ALPHA_USER_MODAL_MESSAGES) || 5,
	debugMode: import.meta.env.VITE_DEBUG_MODE,
}

// eslint-disable-next-line no-console
console.log("Build", {
	isLocalDev: EnvHelperClient.isLocalDev,
	envType: EnvHelperClient.envType,
	buildId: EnvHelperClient.buildId,
})
