import React from "react"
import Styles from "./AnswerSelector.module.scss"
import ChevronLeftIcon from "deblank-common/src/assets/images/icon-library/chevron-left.svg"
import ChevronLeftRight from "deblank-common/src/assets/images/icon-library/chevron-right.svg"

type Props = {
	onNextAnswer: () => void,
	onPrevAnswer: () => void,
	selectedAnswerIndex: number,
	answersAmount: number,
}

const AnswerSelector = (props: Props) => {
	return (
		<div className={Styles.container}>
			<button
				type="button"
				onClick={props.onPrevAnswer}
				disabled={props.selectedAnswerIndex === 1}
				className={Styles.arrow_button}
			>
				<ChevronLeftIcon />
			</button>

			{`${props.selectedAnswerIndex} / ${props.answersAmount}`}

			<button
				type="button"
				className={Styles.arrow_button}
				disabled={props.selectedAnswerIndex === props.answersAmount}
				onClick={props.onNextAnswer}
			>
				<ChevronLeftRight />
			</button>
		</div>
	)
}

export default AnswerSelector
