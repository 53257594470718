import React, { useContext } from "react"
import Styles from "./Sidebar.module.scss"
import Button from "@/components/Buttons/Button/Button"
import { AuthenticationContext } from "@/providers/AuthProvider"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import SideBarIcon from "deblank-common/src/assets/images/icon-library/sidebar.svg"
import NewChatIcon from "deblank-common/src/assets/images/icon-library/new-chat.svg"
import ConversationHistory from "./ConversationHistory/ConversationHistory"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"


const Sidebar = () => {
	const toggleSidebar = settersUiTemporary.useToggleIsOpenSidebar()
	const setIsOpenPinned = settersUiTemporary.useSetIsOpenPinned()

	const authenticationContext = useContext(AuthenticationContext)

	const handleLogout = () => {
		authenticationContext.userSignOut()
	}
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const newChat = settersConversations.useNewChat()
	const isNewChat = useRecoilValue(selectorsConversations.isNewChat)

	const handleNewChat = () => {
		setIsOpenPinned({ isOpen: false, })
		newChat()
	}

	return (
		<div className={Styles.container}>
			<div className={Styles.mobile_top_bar}>
				<Logo className={Styles.logo} />
			</div>
			<div className={Styles.top_actions}>
				<ButtonIcon
					type="button"
					onClick={toggleSidebar}
					iconSVGComponent={SideBarIcon}
					customStyles={{
						variant: "ghost",
					}}
				/>
				<Button
					type="button"
					onClick={handleNewChat}
					icon={{
						SVGComponent: NewChatIcon,
						position: "left",
					}}
					disabled={someMessageIsLoading || isNewChat}
					customStyles={{
						variant: "primary",
					}}>
					New chat
				</Button>
			</div>

			<ConversationHistory />

			<div className={Styles.bottom_actions}>
				<Button
					type="button"
					onClick={handleLogout}
					customStyles={{
						variant: "primary",
					}}>
					Logout
				</Button>
			</div>


		</div>
	)
}

export default Sidebar
