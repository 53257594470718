import { useState, Dispatch, SetStateAction, ClipboardEvent, FocusEvent } from "react"

export const sanitizeHTML = (str: string): string => {
	if (typeof DOMParser !== "undefined") {
		const doc = new DOMParser().parseFromString(str, "text/html")
		return doc.body.innerText || ""
	}
	return " "
}

interface SanitizedElementProps {
	dangerouslySetInnerHTML: { __html: string, };
	onPaste: (e: ClipboardEvent) => void;
	onBlur: (e: FocusEvent<HTMLElement>) => void;
}

export const useSanitizedState = (
	initialValue: string
): [Dispatch<SetStateAction<string>>, SanitizedElementProps, string] => {
	const [value, setValue,] = useState(() => sanitizeHTML(initialValue))

	const setSanitizedValue: Dispatch<SetStateAction<string>> = (
		val: string | ((prevVal: string) => string)
	) => {
		const valueToSanitize = typeof val === "function" ? val(value) : val
		const sanitizedValue = sanitizeHTML(valueToSanitize)
		setValue(sanitizedValue)
	}

	const elementProps: SanitizedElementProps = {
		dangerouslySetInnerHTML: { __html: value, },
		onPaste: (e: ClipboardEvent) => {
			e.preventDefault()
			const pastedText = e.clipboardData.getData("text")
			document.execCommand("insertText", false, sanitizeHTML(pastedText))
		},
		onBlur: (e: FocusEvent<HTMLElement>) => {
			const target = e.target as HTMLElement
			setSanitizedValue(target.innerHTML)
		},
	}

	return [setSanitizedValue, elementProps, value,]
}
