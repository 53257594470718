import React from "react"
import Styles from "./CustomSelect.module.scss"
import classNames from "classnames"
import { CustomSelectValue } from "../../../../../RelatedActionsConfigs/RelatedActionsTypes"

type Props = {
	value: CustomSelectValue,
	onSelect: (value: CustomSelectValue,) => void,
	options: {
		label: string,
		value: string,
		thumbnailImage: string,
	}[],
}

const CustomSelect = (props: Props) => {
	const handleSelect = (value: string) => (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		props.onSelect({ value, })
	}

	return (
		<>
			{props.options.map((opt) => {
				const optionThumbnailClass = classNames({
					[Styles.option_thumbnail]: true,
					[Styles.selected]: props.value.value === opt.value,
				})
				return (
					<li key={opt.value} className={Styles.list_item}>
						<button
							onClick={handleSelect(opt.value)}
							className={Styles.option_container}
						>
							<figure className={optionThumbnailClass}>
								<img src={opt.thumbnailImage} className={Styles.thumbnail} alt={`${opt.value} thumbnail`} />
							</figure>
							<span>{opt.label}</span>
						</button>
					</li>
				)
			})}
		</>
	)
}

export default CustomSelect