export const colorFeedbackOptions = {
	likeFeedbackForm: {
		title: "What did you most like about the result?",
		checkOptions: [
			"The style",
			"It followed instructions",
			"Color contrast",
		],
		suggestionOption: "Other (optional)",
	},
	dontLikeFeedbackForm: {
		title: "Tell us more",
		checkOptions: [
			"Don’t like the style",
			"Didn’t follow instructions",
			"Insufficient color contrast",
		],
		suggestionOption: "Other (optional)",
	},
}

export const fontsFeedbackOptions = {
	likeFeedbackForm: {
		title: "What did you most like about the result?",
		checkOptions: [
			"Readability",
			"It followed instructions",
			"Purpose alignment",
		],
		suggestionOption: "Other (optional)",
	},
	dontLikeFeedbackForm: {
		title: "Tell us more",
		checkOptions: [
			"Readability",
			"Didn’t follow instructions",
			"Purpose alignment",
		],
		suggestionOption: "Other (optional)",
	},
}

export const imagesFeedbackOptions = {
	likeFeedbackForm: {
		title: "What did you most like about the result?",
		checkOptions: [
			"Quality",
			"It followed instructions",
			"Customization",
		],
		suggestionOption: "Other (optional)",
	},
	dontLikeFeedbackForm: {
		title: "Tell us more",
		checkOptions: [
			"Quality",
			"Didn’t follow instructions",
			"Customization",
		],
		suggestionOption: "Other (optional)",
	},
}

export const genericFeedbackOptions = {
	likeFeedbackForm: {
		title: "What did you most like about the result?",
		checkOptions: [
			"Consistency",
			"It followed instructions",
			"Purpose alignment",
		],
		suggestionOption: "Other (optional)",
	},
	dontLikeFeedbackForm: {
		title: "Tell us more",
		checkOptions: [
			"Consistency",
			"Didn’t follow instructions",
			"Purpose alignment",
		],
		suggestionOption: "Other (optional)",
	},
}
