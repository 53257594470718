import { ColorsRelatedActions, DisplayWidgetType, EntryAccessibilityColorsResultWithoutId } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"

type Step1 = ColorSelectValue

type RelatedState = OneStepAction<Step1>

export const Color_Related_Action_Config_4
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				const output: EntryAccessibilityColorsResultWithoutId = {
					type: DisplayWidgetType.AccessibilityColors,
					metadata: null,
					data: {
						isPaginated: false,
						results: [
							{
								palette: data.step1.widgetItemDetails.palette,
							},
						],
					},
				}

				await context.onAddCreateNotifications({
					notifications: [
						{
							relatedActions: [
								ColorsRelatedActions.AddContrastingColor,
								ColorsRelatedActions.ModifyColorPaletteLightness,
								ColorsRelatedActions.HowToApplyColorsInUIDesign,
								ColorsRelatedActions.ApplyColorPaletteOnMockup,
							],
							output,
							// eslint-disable-next-line max-len
							internalMessageForAgent: `The user asked for the accessibility for the following colors: ${data.step1.widgetItemDetails.palette}.`,
							userMessage: {
								message: "Check Color Palette Accessibility",
								idRefs: [data.step1.idRef,],
							},
							generatedByWidget: context.idRef,
						},
					],
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
		}

		return {
			id: ColorsRelatedActions.CheckColorPaletteAccessibility,
			label: "Check Color Palette Accessibility",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
