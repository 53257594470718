import React, { useState } from "react"
import Styles from "./AccessibilityCard.module.scss"
import FailIcon from "deblank-common/src/assets/images/icon-library/cross.svg"
import SuccessIcon from "deblank-common/src/assets/images/icon-library/check-filled.svg"
import classNames from "classnames"
import { IdRefWidgetWithPaginationItem, EntryAccessibilityColorsResponse, DisplayWidgetType } from "deblank-api-types"
import { checkColorAccessibility } from "../../_utils/WidgetColorsUtils"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryAccessibilityColorsResponse,
	type: DisplayWidgetType,
}

const AccessibilityCard = (props: Props) => {
	const additionalColors = ["#FFFFFF", "#000000",]
	const [selectedColor, setSelectedColor,] = useState<string>(props.widgetData.palette[0] || "#000000")

	const renderColorButton = (color: string, index: number, keyPrefix: string) => {
		const colorClasses = classNames(
			Styles.color_button,
			{
				[Styles.selected]: selectedColor === color,
			},
		)
		return (
			<button
				className={colorClasses}
				key={`${keyPrefix}-${index}`}
				onClick={() => setSelectedColor(color)}
				style={{ backgroundColor: color, }}
			/>
		)
	}

	const renderColorChecker = (color: string, index: number, keyPrefix: string) => {

		const colorAccessibility = checkColorAccessibility(selectedColor, color)
		const textAccessibilityCheck = checkColorAccessibility(color, "#FFFFFF")

		const resultClasses = classNames(
			Styles.accessibility_result,
			{
				[Styles.text_dark]: textAccessibilityCheck.contrast < 3,
				[Styles.text_light]: textAccessibilityCheck.contrast >= 3,
			},
		)

		return (
			<div
				className={Styles.color}
				key={`${keyPrefix}-${index}`}
				style={{ backgroundColor: color, }}
			>
				<span
					className={Styles.color_text}
					style={{ color: selectedColor, }}
				>
					{color}
				</span>
				<div className={resultClasses}>
					<div className={Styles.level}>
						<div className={Styles.icon}>{colorAccessibility.contrast < 4.5 ? <FailIcon /> : <SuccessIcon />}</div>
						{colorAccessibility.level}
					</div>
					<div>{colorAccessibility.contrast.toFixed(2)}</div>
				</div>
			</div>
		)
	}

	return (
		<section className={Styles.container}>
			<section className={Styles.select_color}>
				<span className={Styles.select_text}>Select text color</span>
				<div className={Styles.colors_select}>
					{props.widgetData.palette.map((color, index) =>
						renderColorButton(color, index, "select-accessibility"))}
					<div className={Styles.separator} />
					{additionalColors.map((color, index) => renderColorButton(color, index, "select-additional"))}
				</div>
			</section>
			<section className={Styles.accessibility_checker_container}>
				<div className={Styles.accessibility_checker_palette}>
					{props.widgetData.palette.map((color, index) =>
						renderColorChecker(color, index, "checker-accessibility"))}
				</div>
				<div className={Styles.accessibility_checker_palette}>
					{additionalColors.map((color, index) => renderColorChecker(color, index, "checker-additional"))}
				</div>
			</section>
		</section>
	)
}

export default AccessibilityCard
