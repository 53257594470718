/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"


const preloadFonts = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props

	// Create mockup container
	const mockupContainer = new PIXI.Container()
	app.stage.addChild(mockupContainer)

	const renderTitleText = async (weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900) => {
		const titleTextSmall = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.title.font,
			fontWeight: weight,
			fontWeights: params.title.weights,
			color: "#164021",
			fontSize: 24,
		})
		const titleTextMedium = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.title.font,
			fontWeight: weight,
			fontWeights: params.title.weights,
			color: "#164021",
			fontSize: 64,
		})
		const titleTextMediumLarge = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.title.font,
			fontWeight: weight,
			fontWeights: params.title.weights,
			color: "#164021",
			fontSize: 120,
		})
		const titleTextMediumExtraLarge = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.title.font,
			fontWeight: weight,
			fontWeights: params.title.weights,
			color: "#164021",
			fontSize: 260,
		})

		mockupContainer.addChild(titleTextSmall)
		mockupContainer.addChild(titleTextMedium)
		mockupContainer.addChild(titleTextMediumLarge)
		mockupContainer.addChild(titleTextMediumExtraLarge)
	}

	const renderBodyText = async (weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900) => {
		const bodyTextSmall = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.body.font,
			fontWeight: weight,
			fontWeights: params.body.weights,
			color: "#164021",
			fontSize: 24,
		})
		const bodyTextMedium = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.body.font,
			fontWeight: weight,
			fontWeights: params.body.weights,
			color: "#164021",
			fontSize: 64,
		})
		const bodyTextLarge = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.body.font,
			fontWeight: weight,
			fontWeights: params.body.weights,
			color: "#164021",
			fontSize: 80,
		})
		const bodyTextExtraLarge = await MockupUtils.createText({
			text: "Preload",
			fontFamily: params.body.font,
			fontWeight: weight,
			fontWeights: params.body.weights,
			color: "#164021",
			fontSize: 120,
		})

		mockupContainer.addChild(bodyTextSmall)
		mockupContainer.addChild(bodyTextMedium)
		mockupContainer.addChild(bodyTextLarge)
		mockupContainer.addChild(bodyTextExtraLarge)

	}

	await renderTitleText(100)
	await renderTitleText(200)
	await renderTitleText(300)
	await renderTitleText(400)
	await renderTitleText(500)
	await renderTitleText(600)
	await renderTitleText(700)
	await renderTitleText(800)
	await renderTitleText(900)

	await renderBodyText(100)
	await renderBodyText(200)
	await renderBodyText(300)
	await renderBodyText(400)
	await renderBodyText(500)
	await renderBodyText(600)
	await renderBodyText(700)
	await renderBodyText(800)
	await renderBodyText(900)
}

export const Fonts_Mockup_Preload = {
	preloadFonts,
}
