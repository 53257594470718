import { DisplayWidgetType, Output, IdRefWidget } from "deblank-api-types"
import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"


const useAddMessageToCurrentConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		messageId: string,
		results: {
			type: DisplayWidgetType,
			message: string | null,
			output: Output,
		},
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationIndex]

			currentConversation.outputs = { ...currentConversation.outputs, [params.results.output.id]: params.results.output, }

			currentConversation.messages[params.messageId].outputsIdsByIterations
				.push({
					outputId: params.results.output.id,
					activePageIndex: params.results.output.data.isPaginated ? 0 : null,
					message: params.results.message,
				})
		}))
	})
}

const useUpdateMessageToCurrentConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		messageId: string,
		results: {
			type: DisplayWidgetType,
			message: string | null,
			output: Output,
		},
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationIndex]
			const originMessage = currentConversation.messages[params.messageId]
			const previousOutputByIteration = originMessage.outputsIdsByIterations.find(
				output => output.outputId === params.results.output.id
			)

			if (!previousOutputByIteration) {
				console.error(`Previous output by iteration with id ${params.results.output.id} not found`)
				return
			}

			// Update active page index if the output is paginated
			if (params.results.output.data.isPaginated && previousOutputByIteration.activePageIndex !== null) {
				previousOutputByIteration.activePageIndex = params.results.output.data.pages.length - 1
			}
			currentConversation.outputs = {
				...currentConversation.outputs,
				[params.results.output.id]: params.results.output,
			}

		}))
	})
}

const useUpdateIterationResponseIndexInMessage = () => {
	return useRecoilCallback(({ set, }) => (params: {
		idRef: IdRefWidget,
		outputsIdsByIterationIndex: number,
		index: number,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationIndex]

			const editIteration = currentConversation.messages[params.idRef.messageId]
				.outputsIdsByIterations[params.outputsIdsByIterationIndex]

			editIteration.activePageIndex = params.index
		}))
	})
}


const useUpdateMessageStateInCurrentConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		messageId: string,
		state: "success" | "error",
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationIndex]
			const editMessage = currentConversation.messages[params.messageId]

			editMessage.state = params.state
			currentConversation.pendingMessage = null
		}))
	})
}

const useUpdateOutputsIdCounts = () => {
	return useRecoilCallback(({ set, }) => (outputsIdCounts: Record<DisplayWidgetType, number>) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationIndex]
			currentConversation.outputsIdCounts = outputsIdCounts
		}))
	})
}


export const settersMessages = {
	useAddMessageToCurrentConversation,
	useUpdateIterationResponseIndexInMessage,
	useUpdateMessageStateInCurrentConversation,
	useUpdateMessageToCurrentConversation,
	useUpdateOutputsIdCounts,
}
