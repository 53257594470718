import React from "react"
import { CommonWidgetColorProps } from "../WidgetColorsTypes"
import { IdRefType } from "deblank-api-types"
import ColorItem from "./ColorItem/ColorItem"


const WidgetConversationColors = (props: {
	activePageIndex: number,
} & CommonWidgetColorProps) => {

	const currentPage = props.widgetDetails.data.pages[props.activePageIndex].results

	return (
		currentPage.map((palette, index) => (
			<ColorItem key={palette.id}
				widgetData={palette}
				widgetType={props.widgetDetails.type}
				idRef={{
					type: IdRefType.WidgetWithPaginationItem,
					messageId: props.idRef.messageId,
					widgetId: props.idRef.widgetId,
					widgetPageIndex: props.activePageIndex,
					widgetItemIndex: index,
					widgetItemId: palette.id,
				}}
			/>
		))
	)
}

export default WidgetConversationColors
