import React from "react"
import FontExplanation from "./FontExplanation/FontExplanation"
import {
	EntryFontsExplanationsResult, IdRefType,
} from "deblank-api-types"
import { CommonWidgetFontsProps } from "../WidgetFontsTypes"



type Props = {
	widgetDetails: EntryFontsExplanationsResult,
} & Pick<CommonWidgetFontsProps, "idRef">

const WidgetExplanationFonts = (props: Props) => {
	return (
		props.widgetDetails.data.results.map((widgetDetails, index) => (
			<FontExplanation
				key={widgetDetails.id}
				type={props.widgetDetails.type}
				widgetData={widgetDetails}
				idRef={{
					type: IdRefType.WidgetWithPaginationItem,
					messageId: props.idRef.messageId,
					widgetId: props.idRef.widgetId,
					widgetPageIndex: 0,
					widgetItemIndex: index,
					widgetItemId: widgetDetails.id,
				}}
			/>
		))
	)
}

export default WidgetExplanationFonts
