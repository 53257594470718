import { Pairing } from "deblank-api-types"

export const FontDetailsByPairing = (params: {
	pairing: Pairing | null,
	baseFontSlug: string | null,
	fontSlug: string,
}) => {

	const renderTitleSlug = () => {
		if (params.pairing === Pairing.paragraph && params.baseFontSlug) {
			return params.baseFontSlug
		}
		return params.fontSlug
	}

	const renderPairingSlug = () => {
		if (params.pairing && params.baseFontSlug) {
			if (params.pairing === Pairing.title) {
				return params.baseFontSlug
			}
			return params.fontSlug
		}
		return null
	}

	return {
		titleSlug: renderTitleSlug(),
		paringSlug: renderPairingSlug(),
	}
}

export const formatFontNamePairing = (params: {
	pairing: Pairing | null,
	pairingFontName: string | undefined,
	mainFontName: string | undefined,
}): string => {

	if (!params.pairing || !params.pairingFontName) {
		return params.mainFontName || ""
	}

	if (params.pairing === Pairing.title) {
		if (!params.mainFontName) {
			return params.pairingFontName
		}
		return `${params.mainFontName} - ${params.pairingFontName}`
	} else if (params.pairing === Pairing.paragraph) {
		if (!params.mainFontName) {
			return params.pairingFontName
		}
		return `${params.pairingFontName} - ${params.mainFontName}`
	}

	return params.mainFontName || ""
}
