import { Application } from "pixi.js"
import {
	ColorsTemplateMockupParams,
} from "./ColorsMockupTypes"
import { ColorMockupID, FontsMockupID } from "deblank-api-types"
import PQueue from "p-queue"
import { FontsTemplateMockupParams } from "./FontsMockupTypes"

export enum MockupParamsType {
	Colors = "Colors",
	Fonts = "Fonts",
}

export enum MockupCategory {
	branding = "Branding",
	packaging = "Packaging",
	posters = "Posters",
	ui = "Ui"
}

export type MockupParams = ColorsTemplateMockupParams | FontsTemplateMockupParams

export type GetMockupImageProps<P extends MockupParams> = {
	params: P,
	app: Application,
	semaphore: PQueue,
}

export type OpenAiCosts = {
	completion_cost: number,
	prompt_cost: number,
}

export type ImageData = {
	original_src: string,
	originalWidth: number,
	originalHeight: number,
}

export type Mockup<P extends MockupParams> = {
	getMockupImage: (
		props: GetMockupImageProps<P>
	) => Promise<ImageData & OpenAiCosts>,
	mockupId: MockupID,
	description: string,
	placeholder: React.ReactNode,
}


export type MockupID = ColorMockupID | FontsMockupID
