import { MockupCategory } from "../../types/MockupTypes"
import { colorBrandingMockups } from "./branding/colorBrandingMockups"
import { colorPackagingMockups } from "./packaging/colorPackagingMockups"
import { colorPostersMockups } from "./posters/colorPostersMockups"
import { colorUIMockups } from "./ui/colorUIMockups"

export const colorMockups = {
	[MockupCategory.branding]: [...colorBrandingMockups,],
	[MockupCategory.packaging]: [...colorPackagingMockups,],
	[MockupCategory.posters]: [...colorPostersMockups,],
	[MockupCategory.ui]: [...colorUIMockups,],
}