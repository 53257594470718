import WidgetBubbleUserMessageFont from "./BubleUserMessage/WidgetBubbleUserMessageFont"
import WidgetConversationFonts from "./Conversation/WidgetConversationFonts"
import WidgetExplanationFonts from "./Explanation/WidgetExplanationFonts"
import WidgetPinnedFonts from "./Pinned/WidgetPinnedFonts"
import WidgetRelatedActionFont from "./RelatedAction/WidgetRelatedActionFont"
import WidgetToolbarThumbFonts from "./ToolbarThumb/WidgetToolbarThumbFonts"

export const WidgetFonts = {
	BubbleUserMesaage: WidgetBubbleUserMessageFont,
	Conversation: WidgetConversationFonts,
	Explanation: WidgetExplanationFonts,
	Pinned: WidgetPinnedFonts,
	RelatedAction: WidgetRelatedActionFont,
	ToolbarThumb: WidgetToolbarThumbFonts,
}
