import WidgetBubbleUserMessageMockups from "./BubleUserMessage/WidgetBubbleUserMessageMockups"
import WidgetConversationMockups from "./Conversation/WidgetConversationMockups"
import WidgetPinnedMockups from "./Pinned/WidgetPinnedMockups"
import WidgetToolbarThumbMockups from "./ToolbarThumb/WidgetToolbarThumbMockups"


export const WidgetMockups = {
	BubbleUserMesaage: WidgetBubbleUserMessageMockups,
	Conversation: WidgetConversationMockups,
	Pinned: WidgetPinnedMockups,
	ToolbarThumb: WidgetToolbarThumbMockups,
}
