import FontFaceObserver from "fontfaceobserver"
import { FontItem, FontVendor } from "deblank-api-types"

export type VendorLinks = { [key in FontVendor]: string };

/*
 * This creates an array of weight codes to pass
 * to the google/fontshare APIs.
 * Maybe we should unify the font weights handling in the future.
 */
const generateFontWeightCodes = (fontWeights: {
	name: string,
	code: number,
}[]): number[] => {
	return fontWeights.map((weight) => (
		weight.name.toLowerCase().includes("italic") ? weight.code + 1 : weight.code
	))
}

const getFontWeights = (font: FontItem) => {
	const fontWeights = generateFontWeightCodes(font.weights)

	const tempWeights: (number | string)[] = [...fontWeights,]

	// Regular weight is always necessary
	if (!fontWeights.includes(400)) {
		tempWeights.push(400)
	}

	/*
	 * Tweak the weights array to match the API requirements
	 * Fontshare goes as-is (400, 401, etc), google needs an extra step
	 */
	if (font.vendor === "google") {
		tempWeights.forEach((weight, index) => {
			if (Number(weight) % 10 === 1) {
				tempWeights[index] = [1, Number(weight) - 1,].join(",")
			} else {
				tempWeights[index] = [0, weight,].join(",")
			}
		})
	}

	// eslint-disable-next-line @typescript-eslint/require-array-sort-compare
	return tempWeights.sort()
}

const isFontLoaded = async (fontName: string, weight: number | undefined) => {
	const font = new FontFaceObserver(fontName, { weight: weight, })
	try {
		await font.load(null, 15000)
		return true
	} catch (error) {
		console.error(`${fontName} is not loading:`, error)
		return false
	}
}

const getFontHref = (font: FontItem) => {
	const weights = getFontWeights(font)
	const href = font.vendor === "google"
		? `https://fonts.googleapis.com/css2?family=${font.slug}:ital,wght@${weights.join(";")}&display=swap`
		: `https://api.fontshare.com/v2/css?f[]=${font.slug}@${weights.join(",")}&display=swap`

	return href
}

const vendorLinks: VendorLinks = {
	google: "https://fonts.google.com/specimen/",
	fontshare: "https://fontshare.com/fonts/",
}

export const FontUtils = {
	getFontWeights,
	isFontLoaded,
	getFontHref,
	vendorLinks,
}
