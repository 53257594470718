import { Variants } from "framer-motion"

const relatedActionTriggersVariants: Variants = {
	show: {
		opacity: 1,
	},
	hide: {
		opacity: 0,
	},
}

export const relatedActionTriggersMotionProps = {
	initial: "hide",
	animate: "show",
	exit: "hide",
	variants: relatedActionTriggersVariants,
}


const relatedActionsContainerVariants: Variants = {
	show: {
		opacity: 1,
		transition: { staggerChildren: 0.01, delayChildren: 0.1, },

	},
	hide: {
		opacity: 0,
		transition: { staggerChildren: 0.01, staggerDirection: -1, },
	},
}


export const relatedActionsContainerMotionProps = {
	initial: "hide",
	animate: "show",
	exit: "hide",
	variants: relatedActionsContainerVariants,
}
