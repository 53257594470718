/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorPosterMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import FrameGlassLight from "./images/frame_glass_light.png"
import FrameLight from "./images/frame_light.png"
import FrameShadow from "./images/frame_shadow.png"
import Frame from "./images/frame.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/TUew0SeCWGow3Z5EUILdGt/Mockups?type=design&node-id=1796-2611&mode=design&t=Z9tPBHPyjbhUrEDs-0

const SCALE = 0.5

const mockupWidth = 4000 * SCALE
const mockupHeight = 3000 * SCALE

const mockupId = ColorPosterMockupID.colors_poster_mockup_3

type ColorAssignmentType = {
	background: { backgroundShape: string, },
	frameDesign: { colorA: string, colorB: string, colorC: string, colorD: string, colorE: string, colorF: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)
	const backgroundColor = "#FFFFFF"

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const paletteFromAi = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	return {
		colorAssignment: {
			background: { backgroundShape: backgroundColor, },
			frameDesign: { colorA: paletteFromAi.groupA!, colorB: paletteFromAi.groupB!, colorC: paletteFromAi.groupC!, colorD: paletteFromAi.groupD!, colorE: paletteFromAi.groupE!, colorF: paletteFromAi.groupF!, },
		},
		openAiCosts: {
			completion_cost: paletteFromAi.usage!.completion_cost,
			prompt_cost: paletteFromAi.usage!.prompt_cost,
		},
	}


}

const getMockupImage = async (
	props: GetMockupImageProps<ColorsTemplateMockupParams>
) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 6,
		})

		const [frameSprite, frameGlassLightSprite,
			frameShadowSprite, frameLightSprite,] = await MockupUtils.loadAssets([
				Frame, FrameGlassLight, FrameShadow, FrameLight,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		mockupContainer.addChild(backgroundShape)

		//Add sprites

		frameSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		frameGlassLightSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		frameShadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		frameLightSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY


		const frameDesignTexture = await PIXI.Texture.fromURL(Mockup_SVG.design(colorAssignment.frameDesign))
		const frameDesignSprite = new PIXI.Sprite(frameDesignTexture)

		const frameContainer = new PIXI.Container()
		frameContainer.addChild(frameDesignSprite)

		const frameProjection = MockupUtils.project({
			app: app,
			container: frameContainer,
			projectionPoints: {
				topLeft: { x: 1292 * SCALE, y: 497 * SCALE, },
				topRight: { x: 2707 * SCALE, y: 497 * SCALE, },
				bottomRight: { x: 2707 * SCALE, y: 2517.76 * SCALE, },
				bottomLeft: { x: 1292 * SCALE, y: 2517.76 * SCALE, },
			},
		})

		mockupContainer.addChild(frameProjection)
		mockupContainer.addChild(frameShadowSprite)
		mockupContainer.addChild(frameSprite)
		mockupContainer.addChild(frameLightSprite)
		mockupContainer.addChild(frameGlassLightSprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!
}

export const Color_Mockup_Posters_3: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
