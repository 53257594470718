import chroma from "chroma-js"

/**
 * Rotate the hue of a color by a specified degree.
 * @param {string} color - The base color in hex format.
 * @param {number} degree - The degree to rotate the hue.
 * @returns {string} The resulting color in hex format.
 */
function rotateHue(color: string, degree: number): string {
	const hslColor = chroma(color).hsl()
	const newHue = (hslColor[0] + degree) % 360
	const newColor = chroma.hsl(newHue, hslColor[1], hslColor[2]).hex()

	return newColor
}

/**
 * Adjust the lightness of a color by a specified amount.
 * @param {string} color - The base color in hex format.
 * @param {number} amount - The amount to adjust the lightness (-1 to 1).
 * @param {boolean} applyThreshold - Whether to apply a threshold to the lightness.
 * @param {boolean} relativeLightness - Adjust the way the lightness is calculated.
 * @returns {string} The resulting color in hex format.
 */
function adjustLightness(color: string, amount: number, applyThreshold: boolean, relativeLightness: boolean): string {
	const hslColor = chroma(color).hsl()
	let updatedLightness
	let updatedLightnessLimit

	// Relative lightness helps to keep
	// the palette variations consistent
	if (relativeLightness) {
		if (hslColor[2] >= 0.75) {
			// Apply a more aggressive transformation for light colors
			updatedLightness = hslColor[2] + ((1 - hslColor[2]) * (amount * 1.4))
		} else {
			// Apply regular interpolation
			updatedLightness = hslColor[2] + ((1 - hslColor[2]) * amount)
		}
	}

	// If relative lightness is not enabled,
	// we just add the amount to the lightness
	const newLightness = Math.min(1, Math.max(0, updatedLightness ?? hslColor[2] + amount))

	// Thresholds prevent the lightness
	// from going too dark or too light
	if (applyThreshold) {
		const darkThreshold = 0.2
		const lightThreshold = 0.8

		// We don't want to modify the lightness
		// if color is already out of the threshold
		if ((amount < 0 && hslColor[2] <= darkThreshold)
			|| (amount > 0 && hslColor[2] >= lightThreshold)) {
			return color
		}

		// If color is updated, we make sure
		// it stays within the threshold
		if (amount < 0) {
			updatedLightnessLimit = newLightness < darkThreshold ? darkThreshold : newLightness
		} else {
			updatedLightnessLimit = newLightness > lightThreshold ? lightThreshold : newLightness
		}
	}

	// If threshold is not applied, we just
	// return the new lightness
	const newColor = chroma.hsl(hslColor[0], hslColor[1], updatedLightnessLimit ?? newLightness).hex()

	return newColor
}

/**
 * Adjust the saturation of a color by a specified amount.
 * @param {string} color - The base color in hex format.
 * @param {number} amount - The amount to adjust the saturation (-1 to 1).
 * @returns {string} The resulting color in hex format.
 */
function adjustSaturation(color: string, amount: number): string {
	const hslColor = chroma(color).hsl()
	const newSaturation = Math.min(1, Math.max(0, hslColor[1] + amount))
	const newColor = chroma.hsl(hslColor[0], newSaturation, hslColor[2]).hex()

	return newColor
}

/**
 * Generate a gradient between two colors or create shades of a single color.
 * @param {number} steps - The number of steps in the gradient.
 * @param {string} color1 - The first color in hex format.
 * @param {string} [color2] - The second color in hex format (optional).
 * @returns {string[]} An array of colors in the gradient in hex format.
 */
function generateGradient(steps: number, color1: string, color2?: string): string[] {
	// eslint-disable-next-line no-negated-condition
	if (!color2) {
		// Create shades of the single color
		const hslColor = chroma(color1).hsl()
		const hue = hslColor[0]
		const saturation = hslColor[1]

		// Generate lightness values evenly distributed
		const lightnessValues = Array.from({ length: steps, }, (_, i) => {
			const factor = i / (steps - 1)
			return 0.1 + (factor * 0.8) // 0.1 to 0.9 range
		})

		// Generate colors with the calculated lightness values
		return lightnessValues
			.map(lightness => chroma.hsl(hue, saturation, lightness).hex())
	} else {
		// Create a gradient between the two colors
		return chroma.scale([color1, color2,])
			.mode("lab")
			.colors(steps)
	}
}

const shuffleArray = <T>(array: T[]): T[] => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j],] = [array[j], array[i],]
	}
	return array
}


const pickRandomElements = <T>(array: T[], count: number): T[] => {
	if (count > array.length) {
		throw new Error("Count is larger than the array length")
	}

	const shuffledArray = shuffleArray([...array,])
	return shuffledArray.slice(0, count)
}

export const VariationUtils = {
	rotateHue,
	adjustLightness,
	adjustSaturation,
	generateGradient,
	pickRandomElements,
}
