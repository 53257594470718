/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from "react"
import { CommonWidgetMockupsProps } from "../WidgetMockupsTypes"
import MockupImage from "./MockupImage/MockupImage"
import { useGetMockupParams } from "@/utils/mockupBuilder/useGetMockupParams"
import { useHandleMockupImages } from "@/utils/mockupBuilder/useHandleMockupImages"
import { DisplayWidgetType, IdRefType, } from "deblank-api-types"
import { settersFontCache } from "@/recoil/FontCache/Setters"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import LoadingMockups from "@/assets/loadingMockups.svg"
import Spinner, { SpinnerSize } from "@/components/Spinner/Spinner"
import Styles from "./WidgetConversationMockups.module.scss"
import { useUploadImage } from "@/hooks/useUploadImage"
import { getMockupGenerationNeedsByType } from "./WidgetConversationMockupsHelper"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import pLimit from "p-limit"
import { produce } from "immer"

const WidgetConversationMockups = (props: {
	activePageIndex: number,
} & CommonWidgetMockupsProps) => {

	const { uploadImage, } = useUploadImage()
	const fontDictionary = useRecoilValue(selectorsFontCache.fontDictionary)
	const addSlugsToPendingSlugs = settersFontCache.useAddSlugsToPendingSlugs()
	const updateNotification = settersAssistantResponseTools.useDispatchUpdateNotifications()
	const { getMockupParams, getMockups, } = useGetMockupParams()
	const { generateMockupImages, isMockupBuilderReady, } = useHandleMockupImages()
	const [isLoading, setLoading,] = useState(true)
	const limit = pLimit(1)

	const currentPage = props.widgetDetails.data.pages.length > 0 && props.widgetDetails.data.pages[props.activePageIndex].results

	const isBodyFontLoaded = props.widgetDetails.type === DisplayWidgetType.MockupFonts
		? fontDictionary[props.widgetDetails.metadata.bodyFontSlug]?.isCSSLoaded
		: true

	const isTitleFontLoaded = props.widgetDetails.type === DisplayWidgetType.MockupFonts
		? fontDictionary[props.widgetDetails.metadata.titleFontSlug]?.isCSSLoaded
		: true


	const { mockups, params, } = useMemo(
		() => getMockupGenerationNeedsByType({
			getMockups,
			widgetDetails: props.widgetDetails,
		})
		, [])

	const output = props.widgetDetails

	const fetchMockupImages = async () => {
		try {
			setLoading(true)
			const mockupParams = getMockupParams(params)
			const mockupImages = await generateMockupImages({ mockupParams, mockups, })
			if (mockupImages && mockupImages.length > 0) {
				const filteredImages = mockupImages.map((im) => {

					return {
						mockupId: im!.mockupId,
						dataUrl: im!.imageData.original_src,
						width: im!.imageData.originalWidth,
						height: im!.imageData.originalHeight,
					}
				})


				const imageUrl = await Promise.all(filteredImages.map((i) => limit(() => uploadImage(i.dataUrl))))

				updateNotification(
					{
						notifications: [{
							outputs: [{
								...produce(output, (draft) => {
									draft.data.pages = filteredImages.map((im, index) => {
										return {
											pageNumber: index,
											results: [{
												height: im.height,
												width: im.width,
												mockupId: im.mockupId,
												imageUrl: imageUrl[index],
												id: `${props.idRef.widgetId}-${index}-1`,
											},],
										}
									})
								}),
							},],
						},],
					}
				)
			}
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.error("Error fetching mockup images", error)
		}
	}

	useMemo(() => {
		if (props.widgetDetails.type === DisplayWidgetType.MockupFonts) {
			addSlugsToPendingSlugs([props.widgetDetails.metadata.bodyFontSlug, props.widgetDetails.metadata.titleFontSlug,])
		}
	}, [])

	useEffect(() => {
		if (isMockupBuilderReady && isBodyFontLoaded && isTitleFontLoaded) {
			fetchMockupImages()
		}
	}, [isMockupBuilderReady, isBodyFontLoaded, isTitleFontLoaded,])

	return (
		<>
			{!isLoading && currentPage
				? <>
					{
						currentPage.map((mockupsPage, index) => (

							<MockupImage
								dataUrl={mockupsPage.imageUrl}
								key={mockupsPage.id}
								mockupId={mockupsPage.mockupId}
								width={mockupsPage.width}
								height={mockupsPage.height}
								idRef={{
									type: IdRefType.WidgetWithPaginationItem,
									messageId: props.idRef.messageId,
									widgetId: props.idRef.widgetId,
									widgetPageIndex: props.activePageIndex,
									widgetItemIndex: index,
									widgetItemId: mockupsPage.id,
								}}
								widgetType={props.widgetDetails.type}
							/>
						))
					}
				</>
				: <figure
					className={Styles.loading_container}>
					<Spinner darkMode size={SpinnerSize.Medium} />
					<LoadingMockups className="disabled_custom_color" />
				</figure>
			}

		</>)
}

export default WidgetConversationMockups
