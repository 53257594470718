import React, { ReactNode } from "react"
import * as Sentry from "@sentry/react"
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"
import Styles from "./ErrorBoundary.module.scss"
import Button from "@/components/Buttons/Button/Button"
import NewChatIcon from "deblank-common/src/assets/images/icon-library/new-chat.svg"
import InfoIcon from "deblank-common/src/assets/images/icon-library/info.svg"

const ErrorMessage = (props: FallbackProps) => {

	const handleClick = () => {
		props.resetErrorBoundary()
	}

	return (
		<section className={Styles.container}>
			<header className={Styles.header}>
				<Logo className={Styles.logo} />
			</header>
			<div className={Styles.content}>
				<h1 className={Styles.title}>
					<InfoIcon />
					Something went wrong
				</h1>
				<p className={Styles.message}>
					Sorry, there was an issue with the last result. Please begin a new chat.
				</p>
				<Button
					type="button"
					onClick={handleClick}
					icon={{
						position: "right",
						SVGComponent: NewChatIcon,
					}}
					customStyles={{
						variant: "primary",
					}}
				>
					New chat
				</Button>
			</div>
		</section >
	)
}


const ErrorBoundary = (props: { children: ReactNode, }) => {

	const logError = (error: Error, info: { componentStack: string, }) => {
		console.error("error", error.message)
		Sentry.captureMessage(error.message, {
			level: "fatal",
			extra: info,
		})
	}

	return (
		<ReactErrorBoundary
			FallbackComponent={ErrorMessage}
			onError={logError}
		>
			{props.children}
		</ReactErrorBoundary>
	)
}

export default ErrorBoundary