import React from "react"
import Styles from "./DebugButton.module.scss"
import { DebugEntry } from "deblank-api-types"
import classNames from "classnames"
import { parseJsonProperties } from "@/utils/parseJsonProperties"

type Props = {
	content: DebugEntry,
}

const DebugButton = (props: Props) => {
	const buttonClasses = classNames(
		Styles.debug_button,
		{
			[Styles.is_user_message]: props.content.message.includes("💬"),
		}
	)

	const handleDebugMessage = (entry: DebugEntry) => {
		const toPrint = parseJsonProperties(entry)
		// eslint-disable-next-line no-console
		console.log(JSON.stringify(toPrint, null, 2), toPrint)
	}

	return (
		<button
			type="button"
			className={buttonClasses}
			onClick={() => handleDebugMessage(props.content)}
		>
			{props.content.message}
		</button>
	)
}

export default DebugButton
