import React from "react"
import { createRootRoute, Outlet } from "@tanstack/react-router"
import { Providers } from "../providers/Providers"

export const Route = createRootRoute({
	component: () => (
		<Providers>
			<Outlet />
		</Providers>
	),
})
