import React, { useContext, useState } from "react"
import Styles from "./ColorItem.module.scss"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/Widgets/WidgetsTypes"
import { ColorUtils } from "@/utils/ColorUtils"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { ExportHelper } from "@/utils/exportHelper"
import classNames from "classnames"
import {
	IdRefWidgetWithPaginationItem, EntryColorsResponse, DisplayWidgetType,
} from "deblank-api-types"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryColorsResponse,
	widgetType: DisplayWidgetType,
}

const ColorItem = (props: Props) => {
	const ref = React.useRef<HTMLDivElement>(null)

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const setColorPlayground = settersCommon.useSetColorPlaygroundIdRef()
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleExport = () => {
		ExportHelper.fromTemplate(ref)
		trackConversationEvent({
			eventName: "Export",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Export",
			icon: ExportIcon,
			onClick: handleExport,
		},
	]

	const handleOpenPlayground = () => {
		trackConversationEvent({
			eventName: "OpenPlayground",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setColorPlayground({ idRef: props.idRef, })
	}

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<div className={wrapperClasses}>
			<article
				id={
					ScreenshotIdManager.assignId({
						widgetUiSection: WidgetUiSectionId.Conversation,
						idRef: props.idRef,
					})
				}
				ref={ref}
				className={Styles.palette}>
				{props.widgetData.palette.map((color, index) => (
					<div key={`${index}-${color}`}
						className={Styles.color_item}
						style={{ "--current_color": color, } as React.CSSProperties}
					>
						<button
							type="button"
							className={Styles.copy_button}
							onClick={handleOpenPlayground}
							style={{ "--fg_color": ColorUtils.getForegroundColor(color), } as React.CSSProperties}
						/>
					</div>
				))}
			</article>

			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "colors",
				}}
			/>
		</div>
	)
}

export default ColorItem
