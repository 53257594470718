import WidgetConversationColors from "./Conversation/WidgetConversationColors"
import WidgetPinnedColors from "./Pinned/WidgetPinnedColors"
import WidgetBubbleUserMessageColor from "./BubleUserMessage/WidgetBubbleUserMessageColor"
import WidgetRelatedActionColor from "./RelatedAction/WidgetRelatedActionColor"
import WidgetAccessibilityColors from "./Accessibility/WidgetAccessibilityColors"
import WidgetToolbarThumbColors from "./ToolbarThumb/WidgetToolbarThumbColors"
import WidgetExplanationColors from "./Explanation/WidgetExplanationColors"


export const WidgetColors = {
	Accessibility: WidgetAccessibilityColors,
	BubbleUserMesaage: WidgetBubbleUserMessageColor,
	Conversation: WidgetConversationColors,
	Explanation: WidgetExplanationColors,
	Pinned: WidgetPinnedColors,
	RelatedAction: WidgetRelatedActionColor,
	ToolbarThumb: WidgetToolbarThumbColors,
}
