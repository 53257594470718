import { useEffect } from "react"

export const usePreventScroll = (params: {
	prevent: boolean,
}) => {

	useEffect(() => {
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

		if (params.prevent) {
			document.body.style.overflow = "hidden"
			document.body.style.paddingRight = `${scrollbarWidth}px`

			return () => {
				document.body.style.overflow = "auto"
				document.body.style.paddingRight = ""
			}
		}
	}, [params.prevent,])

}
