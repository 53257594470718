import React from "react"
import Styles from "./BubbleAssistant.module.scss"
import Markdown from "marked-react"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"

type ChatBubbleProps = {
	title: string,
	message: string,
}

const ChatBubbleAssistant = (props: ChatBubbleProps) => {

	// Add additional logic if needed, example widgets for assistant, etc.

	return (
		<div className={Styles.bubble}>
			<div className={Styles.widget_top_row}>
				<div className={Styles.title_container}>
					<StarIcon />
					<p>{props.title}</p>
				</div>
			</div>

			<div className={Styles.widget_answer_container}>
				<div className={Styles.left_column} />

				<div className={Styles.text_response}>
					<Markdown>{props.message}</Markdown>
				</div>
			</div>
		</div>
	)
}

export default ChatBubbleAssistant
