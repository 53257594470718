import React from "react"
import Styles from "./ModalWrapper.module.scss"
import { m } from "framer-motion"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"

const ModalWrapper = (props: { children: React.ReactNode, }) => {
	usePreventScroll({ prevent: true, })

	return (
		<m.section
			className={Styles.modal_wrapper}
			initial={{ opacity: 0, scale: 0.9, }}
			animate={{ opacity: 1, scale: 1, }}
			exit={{ opacity: 0, scale: 0.9, }}
		>
			{props.children}
		</m.section>
	)
}

export default ModalWrapper
