import React from "react"
import Styles from "./StepsController.module.scss"
import classNames from "classnames"

type Props = {
	onNextStep: () => void,
	selectedStepIndex: number,
	stepsAmount: number,
	isNextActionDisabled?: boolean,
	stepCompleted?: boolean,
}

const StepsController = (props: Props) => {

	const renderStepMark = (stepIndex: number) => {
		const classes = classNames({
			[Styles.step_mark]: true,
			[Styles.is_completed]: props.selectedStepIndex >= stepIndex,
		})

		return (
			<div key={`step-${stepIndex}`} className={classes} />
		)
	}

	return (
		<div className={Styles.steps_control_container}>
			<div className={Styles.steps_control_bar}
				style={{ "--steps_amount": props.stepsAmount, } as React.CSSProperties}
			>
				{new Array(props.stepsAmount).fill(null)
					.map((_, index) => {
						return renderStepMark(index)
					})
				}
			</div>
			<button
				type="button"
				onClick={props.onNextStep}
				className={Styles.skip_button}
				disabled={props.isNextActionDisabled || props.stepCompleted}
			>
				Skip
			</button>
		</div>
	)
}

export default StepsController