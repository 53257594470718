import { MockupCategory } from "../../types/MockupTypes"
import { fontsBrandingMockups } from "./branding/fontsBrandingMockups"
import { fontsPackagingMockups } from "./packaging/fontsPackagingMockups"
import { fontsPostersMockups } from "./posters/fontsPostersMockups"
import { fontsUIMockups } from "./ui/fontsUIMockups"

export const fontsMockups = {
	[MockupCategory.branding]: [...fontsBrandingMockups,],
	[MockupCategory.packaging]: [...fontsPackagingMockups,],
	[MockupCategory.posters]: [...fontsPostersMockups,],
	[MockupCategory.ui]: [...fontsUIMockups,],
}