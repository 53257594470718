import { DebugEntry, PendingNotification } from "deblank-api-types"
import { selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { PendingMessage } from "../Types"
import { prefixRecoilKey } from "./Common"
import { produce } from "immer"


const debugInCurrentConversation = selector<DebugEntry[] | null>({
	key: prefixRecoilKey("debugInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)

		return conversations[activeConversationIndex]
			? conversations[activeConversationIndex].debugHistory
			: null
	},
})

const currentPendingMessage = selector<PendingMessage | null>({
	key: prefixRecoilKey("currentPendingMessages"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)

		return conversations[activeConversationIndex]
			? conversations[activeConversationIndex].pendingMessage
			: null
	},
})

const currentPendingNotifications = selector<PendingNotification[] | null>({
	key: prefixRecoilKey("currentPendingNotifications"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)

		return conversations[activeConversationIndex]
			? conversations[activeConversationIndex].pendingNotifications
			: null
	},
})

const useInternalStateInCurrentConversation = selector<string | undefined>({
	key: prefixRecoilKey("internalState"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]

		return currentConversation ? currentConversation.internalState : undefined
	},
	set: ({ set, }, newValue) => {
		const newInternalState = newValue as string
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.conversations[draft.activeConversationIndex].internalState = newInternalState
		}))
	},
})


export const selectorsAssistantResponseTools = {
	useInternalStateInCurrentConversation,
	debugInCurrentConversation,
	currentPendingMessage,
	currentPendingNotifications,
}
