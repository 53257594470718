import React, { useEffect } from "react"
import { LazyMotion, domAnimation } from "framer-motion"
import { TRPCProvider } from "./TRPCProvider"
import { initMixpanelIfNeeded } from "@/utils/mixpanel/mixpanel"
import { UserProvider } from "./UserProvider"
import { AuthenticationProvider } from "./AuthProvider"
import { Toaster } from "sonner"
import { RecoilRoot } from "recoil"
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary"

export function Providers({ children, }: { children: React.ReactNode, }) {

	useEffect(() => {
		initMixpanelIfNeeded()
	}, [])

	return (
		<ErrorBoundary>
			<RecoilRoot>
				<UserProvider>
					<TRPCProvider>
						<AuthenticationProvider>
							<LazyMotion features={domAnimation}>
								{children}
								<Toaster />
							</LazyMotion>
						</AuthenticationProvider>
					</TRPCProvider>
				</UserProvider>
			</RecoilRoot>
		</ErrorBoundary>
	)
}
