/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Design from "./images/design.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"


const mockupWidth = 1490.31
const mockupHeight = 989.57

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/design/y5UsL35vcLmufMsTJ93EOe/Assistant-Mockups?node-id=221-445&t=EDGfHMVUUeE3jw5g-0

const mockupId = FontsUIMockupID.fonts_ui_mockup_3


const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props

	const ret = await props.semaphore.add(async () => {
		const [
			designSprite,
		] = await MockupUtils.loadAssets([
			Design,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		//Add design
		mockupContainer.addChild(designSprite)

		//Add month text
		const monthText = await MockupUtils.createText({
			text: "August",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 700,
			color: "#000000",
			fontSize: 52,
			padding: 30,
			trim: true,
			fitText: {
				containerWidth: 172,
			},
		})
		monthText.position.set(143.07, 169.03)
		mockupContainer.addChild(monthText)

		//Add task text
		const taskText = await MockupUtils.createText({
			text: "Complete Monthly Financial Report",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 500,
			color: "#000000",
			fontSize: 31,
			trim: true,
			wrapText: true,
			wordWrapWidth: 336,
		})
		taskText.position.set(1022.02, 139)
		mockupContainer.addChild(taskText)

		const addWeekDays = async (text: string, pos: { x: number, y: number, }) => {
			const weekdayText = await MockupUtils.createText({
				text: text,
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 700,
				color: "#000000",
				fontSize: 21,
				trim: true,
			})
			weekdayText.position.set(pos.x, pos.y)
			mockupContainer.addChild(weekdayText)
		}

		const addTaskNames = async (text: string, pos: { x: number, y: number, }) => {
			const taskText = await MockupUtils.createText({
				text: text,
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 700,
				color: "#000000",
				fontSize: 16,
				trim: true,
			})
			taskText.position.set(pos.x, pos.y)
			mockupContainer.addChild(taskText)
		}

		//Add weekdays numbers text
		await addWeekDays("6", { x: 292, y: 361.65, })
		await addWeekDays("7", { x: 422, y: 361.65, })
		await addWeekDays("8", { x: 552, y: 361.65, })
		await addWeekDays("9", { x: 682, y: 361.65, })
		await addWeekDays("10", { x: 807, y: 361.65, })

		await addTaskNames("Review", { x: 266.27, y: 521.28, })
		await addTaskNames("Design Team", { x: 424.57, y: 465.64, })
		await addTaskNames("Planning", { x: 424.57, y: 604.07, })
		await addTaskNames("Kick-off", { x: 582.88, y: 465.64, })
		await addTaskNames("Marketing", { x: 582.88, y: 721.31, })
		await addTaskNames("Review", { x: 741.18, y: 521.28, })



		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: null,
		})

		return imageData
	})

	return ret!




}

export const Fonts_Mockup_UI_3: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
