import React, { useEffect } from "react"
import Styles from "./WidgetRelatedActionColor.module.scss"
import { useRecoilValue } from "recoil"
import classNames from "classnames"
import { ColorSelectValue } from "../WidgetColorsTypes"
import { DisplayWidgetType, IdRefWidget } from "deblank-api-types"
import { selectorsRelatedActions } from "@/recoil/ConversationsRecord/Selectors/RelatedActions"

type Props = {
	value: ColorSelectValue,
	onSelect: (value: ColorSelectValue,) => void,
	onNextAction: () => void,
	idRef: IdRefWidget,
}

const WidgetRelatedActionColor = (props: Props) => {
	const widgetItems = useRecoilValue(selectorsRelatedActions.widgetItemsForRelatedActions(props.idRef))

	// If there is only one option, select it and go to the next action
	// This is going to set the state and activate a flag that executes the next action.
	useEffect(() => {
		if (widgetItems && widgetItems.length === 1 && props.value.widgetItemDetails.palette.length === 0) {
			const widget = widgetItems[0].type === DisplayWidgetType.Colors && widgetItems[0]
			if (widget) {
				props.onSelect({
					idRef: widget.idRef,
					widgetItemDetails: widget.widgetData,
				})
				props.onNextAction()
			}

		}
	}, [widgetItems,])


	const handleSelect = (
		idRef: ColorSelectValue["idRef"],
		widgetItemDetails: ColorSelectValue["widgetItemDetails"],
	) => (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		props.onSelect({
			idRef: idRef,
			widgetItemDetails: widgetItemDetails,
		})
	}

	return (
		<>
			{widgetItems && widgetItems.map((widget) => {
				if (widget.type !== DisplayWidgetType.Colors) {
					return null
				}
				const paletteClass = classNames({
					[Styles.palette]: true,
					[Styles.palette_selected]: props.value.idRef.widgetItemId === widget.idRef.widgetItemId,
				})
				return (
					<li key={widget.idRef.widgetItemId} className={Styles.list_item}>
						<button
							onClick={
								handleSelect(
									widget.idRef,
									widget.widgetData,
								)
							}
							className={paletteClass}
							style={{ "--color_amount": widget.widgetData.palette.length, } as React.CSSProperties}
						>
							{widget.widgetData.palette.map((color, index) => {
								return <div
									className={Styles.color}
									key={`${widget.idRef.widgetItemId}-color-${index}`}
									style={{ backgroundColor: color, }}
								/>
							})}
						</button>
					</li>
				)
			})}
		</>
	)
}

export default WidgetRelatedActionColor
