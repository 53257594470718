import React, { useState } from "react"
import Styles from "./TextPlayground.module.scss"
import { TextState } from "../FontsPlaygroundHelper"
import { FontFromCache } from "@/recoil/FontCache/Types"
import PlaygroundToolbar from "./PlaygroundToolbar/PlaygroundToolbar"

type Props = {
	children: React.ReactElement,
	defaultState: TextState,
	font: FontFromCache,
	messageId: string,
}

const TextPlayground = (props: Props) => {
	const [textState, setTextState,] = useState<TextState>(props.defaultState)

	return (
		<div className={Styles.wrapper}>
			<PlaygroundToolbar
				state={textState}
				font={props.font}
				onChange={setTextState}
				messageId={props.messageId}
			/>
			<div className={Styles.text_container}
				style={{
					minHeight: textState.fontSize.value,
					fontSize: textState.fontSize.value,
					fontWeight: textState.fontWeight,
					lineHeight: `${textState.lineHeight.value}%`,
					letterSpacing: `${textState.letterSpacing.value}px`,
					textAlign: textState.textAlign,
				}}>
				{props.children}
			</div>
			<div className={Styles.divider} />
		</div>
	)
}

export default TextPlayground