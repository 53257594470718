import chroma from "chroma-js"

type AccessibilityResult = {
	level: "AAA" | "AA" | "FAIL",
	contrast: number,
}

const AAA_THRESHOLD = 7
const AA_THRESHOLD = 4.5

export const checkColorAccessibility = (color: string, backgroundColor: string): AccessibilityResult => {
	const contrastRatio = chroma.contrast(color, backgroundColor)

	if (contrastRatio >= AAA_THRESHOLD) {
		return { level: "AAA", contrast: contrastRatio, }
	} else if (contrastRatio >= AA_THRESHOLD) {
		return { level: "AA", contrast: contrastRatio, }
	} else {
		return { level: "FAIL", contrast: contrastRatio, }
	}
}
