/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLMotionProps, Variants } from "framer-motion"
import { LayoutSizes } from "./LayoutSizes"

const variantsRightPanel: Variants = {
	hidden: {
		x: "100%",
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
}

const variantsSidebar: Variants = {
	hidden: {
		x: "-100%",
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
}

const variantsOpacityContainer: Variants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
}
const variantsContainerGrid: Variants = {
	onlySidebarOpen: {
		"--sidebar_column_width": `${LayoutSizes.sidebar}px`,
		"--pinned_column_width": "0px",
	} as any,
	onlyPinnedOpen: {
		"--sidebar_column_width": "0px",
		"--pinned_column_width": `${LayoutSizes.pinnedbar}px`,
	} as any,
	bothOpen: {
		"--sidebar_column_width": `${LayoutSizes.sidebar}px`,
		"--pinned_column_width": `${LayoutSizes.pinnedbar}px`,
	} as any,
	bothClosed: {
		"--sidebar_column_width": "0px",
		"--pinned_column_width": "0px",
	} as any,
}

const transition: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
}

const transitionForOpacity: HTMLMotionProps<"section">["transition"] = {
	ease: "linear",
	duration: 0.1,
}

export const pinnedPanelMotionProps = {
	variants: variantsRightPanel,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transitionForOpacity,
}

export const debugPanelMotionProps = {
	variants: variantsRightPanel,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
}

export const sidebarMotionProps = {
	variants: variantsSidebar,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
}

export const opacityContainerMotionProps = {
	variants: variantsOpacityContainer,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
}

export const containerGridMotionProps = (props: {
	showSidebar: boolean,
	showRightPanel: boolean,
}) => {

	const getActiveVariant = () => {

		if (props.showSidebar && props.showRightPanel) {
			return "bothOpen"
		} else if (!props.showSidebar && !props.showRightPanel) {
			return "bothClosed"
		} else if (props.showSidebar && !props.showRightPanel) {
			return "onlySidebarOpen"
		} else if (props.showRightPanel && !props.showSidebar) {
			return "onlyPinnedOpen"
		} else {
			return "bothOpen"
		}
	}

	return {
		variants: variantsContainerGrid,
		initial: "bothClosed",
		animate: getActiveVariant(),
		transition: transition,
	}
}
