import React from "react"
import Styles from "./WidgetBubbleUserMessageFont.module.scss"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import { CommonWidgetFontsItemProps } from "../WidgetFontsTypes"
import { FontDetailsByPairing, } from "../_helper/FontDetailsByPairing"



const WidgetBubbleUserMessageFont = (props: CommonWidgetFontsItemProps) => {
	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.widgetItemDetails.slug,
	})

	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))


	return (
		<article className={Styles.selected_font}>
			{fontMainDetails
				&& <h3 className={Styles.title}
					style={fontMainDetails.fontCSSProperties}>
					{fontMainDetails.metadata.name}
				</h3>
			}
			{fontPairingDetails
				&& <p className={Styles.paragraph}
					style={fontPairingDetails.fontCSSProperties}>
					{fontPairingDetails.metadata.name}
				</p>
			}
		</article>
	)
}

export default WidgetBubbleUserMessageFont
