/* eslint-disable max-len */
import { FontsRelatedActions } from "deblank-api-types"
import { FontSelectValue } from "@/components/WidgetsByType/Fonts/WidgetFontsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"

type Step1 = FontSelectValue

type RelatedState = OneStepAction<Step1>

export const Font_Related_Action_Config_1
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				// eslint-disable-next-line max-len
				const prompt = data.step1.metadata.baseFont
					? `Give me the characteristics of this font ${data.step1.metadata.baseFont} and this
font ${data.step1.widgetItemDetails.slug} and Explain me how to use this fonts in a UI using ${data.step1.metadata.baseFont} for body and ${data.step1.metadata.baseFont} for the title taking into account
Readability and Legibility, Visual Hierarchy, Consistency, Whitespace and Padding, Alignment and Justification`
					// eslint-disable-next-line max-len
					: "Give me the characteristics of this font and Explain me how to use this fonts in a UI taking into account Readability and Legibility, Visual Hierarchy, Consistency, Whitespace and Padding, Alignment and Justification"
				await context.onAddPendingMessage({
					message: "How to Use These Fonts in a UI",
					// eslint-disable-next-line max-len
					prompt: prompt,
					idRefs: data.step1.idRef
						? [data.step1.idRef,]
						: undefined,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: FontSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
		}

		return {
			id: FontsRelatedActions.HowToUseFontsInUI,
			label: "How to Use These Fonts in a UI",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.fonts,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a font",
						componentType: ConfigStepType.select,
						selectType: SelectType.fonts,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
