import LoadingView from "@/components/LoadingView/LoadingView"
import { getRouteApi, useNavigate, } from "@tanstack/react-router"
import React, { useEffect } from "react"
import { toast } from "sonner"


const useAuthentication = () => {
	const routeApi = getRouteApi("/alpha")

	const searchParams = routeApi.useSearch()
	const navigate = useNavigate()

	useEffect(() => {
		const setAlphaToken = () => {
			try {
				if (searchParams.token) {
					const token = searchParams.token
					navigate({
						to: "/login",
						search: {
							token,
						},
					})
				} else {
					toast.error("Inexistent authentication token")
					navigate({
						to: "/request-access",
					})
				}
			} catch (e) {
				toast.error("Invalid authentication token")
				navigate({
					to: "/request-access",
				})
			}
		}

		setAlphaToken()
	}, [])
}


const AlphaAuthenticationPage = () => {

	useAuthentication()

	return <LoadingView showMessage />
}

export default AlphaAuthenticationPage
