/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from "react"
import Styles from "./MockupImage.module.scss"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/Widgets/WidgetsTypes"
import classNames from "classnames"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DisplayWidgetType, IdRefWidgetWithPaginationItem } from "deblank-api-types"
import { ExportHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"


const MockupImage = (props: {
	mockupId: string,
	width: number,
	height: number,
	widgetType: DisplayWidgetType,
	idRef: IdRefWidgetWithPaginationItem,
	dataUrl: string,
}) => {

	const [actionsOpen, setActionsOpen,] = React.useState(false)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const setImageModal = settersCommon.useSetImageModalIdRef()

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const handleExport = () => {
		ExportHelper.fromImage(props.dataUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
			outputId: props.idRef.messageId,
		})
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Export",
			icon: ExportIcon,
			onClick: () => handleExport(),
		},
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenModal,
		},
	]

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<figure className={wrapperClasses} >
			<img
				id={ScreenshotIdManager.assignId({
					widgetUiSection: WidgetUiSectionId.Conversation,
					idRef: props.idRef,
				})}
				src={props.dataUrl}
				alt="generated by the user"
				className={Styles.image}
				onClick={handleOpenModal}
			/>

			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "images",
				}}
			/>
		</figure>
	)
}

export default MockupImage
