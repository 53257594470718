import React, { useContext } from "react"
import Button from "@/components/Buttons/Button/Button"
import Styles from "./LoginPage.module.scss"
import { AuthenticationContext } from "@/providers/AuthProvider"
import { UserContext } from "@/providers/UserProvider"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"
import { getRouteApi } from "@tanstack/react-router"

const LoginPage = () => {
	const userContext = useContext(UserContext)
	const authenticationContext = useContext(AuthenticationContext)
	const routeApi = getRouteApi("/login")
	const searchParams = routeApi.useSearch()

	const handleLogin = () => {
		authenticationContext.signInWithGoogle(searchParams.token)
	}

	const handleLogout = () => {
		authenticationContext.userSignOut()
	}

	return (
		<section className={Styles.container}>
			<header className={Styles.header}>
				<Logo className={Styles.logo} />
			</header>

			{userContext.user
				? <div className={Styles.button_container}>
					<Button
						type="button"
						onClick={handleLogout}
						customStyles={{
							variant: "primary",
							size: "large",
							fullWidth: true,
						}}>
						Logout
					</Button>
				</div>

				: <div className={Styles.content}>
					<h1>Deblank Assistant</h1>
					<p className={Styles.message}>
						{"We're currently on a private alpha phase. If not registered, request access at "}
						<a
							className={Styles.link}
							href="mailto:hi@deblank.com"
						> hi@deblank.com</a>
					</p>
					<div className={Styles.button_container}>
						<Button
							type="button"
							onClick={handleLogin}
							customStyles={{
								variant: "primary",
								size: "large",
								fullWidth: true,
							}}>
							Login
						</Button>
					</div>

				</div>
			}
			<span className={Styles.gradient} />
		</section>
	)
}

export default LoginPage
