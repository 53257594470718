import LoadingView from "@/components/LoadingView/LoadingView"
import { AuthenticationContext } from "@/providers/AuthProvider"
import { UserContext } from "@/providers/UserProvider"
import { EnvHelperClient } from "@/utils/envHelper"
import { getRouteApi, useNavigate, } from "@tanstack/react-router"
import React, { useContext, useEffect } from "react"
import { toast } from "sonner"


const useAuthentication = () => {
	const routeApi = getRouteApi("/authentication")

	const searchParams = routeApi.useSearch()
	const userContext = useContext(UserContext)
	const authenticationContext = useContext(AuthenticationContext)
	const navigate = useNavigate()


	useEffect(() => {
		if (userContext.user) {
			navigate({
				to: "/",
			})
		}
	}, [userContext.user,])

	useEffect(() => {
		const authenticate = async () => {
			try {
				if (EnvHelperClient.envType?.includes("preview")) {
					if (searchParams.token) {
						const token = searchParams.token
						await authenticationContext.signInWithToken(token!)
					} else {
						toast.error("Inexistent authentication token")
						navigate({
							to: "/",
						})
					}
				} else {
					toast.error("Invalid environment")
					navigate({
						to: "/",
					})
				}
			} catch (e) {
				toast.error("Invalid authentication token")
				navigate({
					to: "/",
				})
			}
		}

		authenticate()
	}, [])
}


const AuthenticationPage = () => {

	useAuthentication()

	return <LoadingView showMessage />
}

export default AuthenticationPage
