/* eslint-disable max-len */
const mockupWidth = 3000 * 0.5
const mockupHeight = 2250 * 0.5

const designSvg = `
<svg width="${mockupWidth}" height="${mockupHeight}" viewBox="0 0 ${mockupWidth} ${mockupHeight}" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_758_90)">
<rect width="${mockupWidth}" height="${mockupHeight}" id="backgroundColor"/>
<rect x="1679.4" y="-684" width="3246.55" height="359" transform="rotate(79.87 1679.4 -684)" id="group_a"/>
<rect x="1679.4" y="-684" width="3246.55" height="359" transform="rotate(79.87 1679.4 -684)" id="group_a"/>
<rect x="1679.4" y="-684" width="3246.55" height="359" transform="rotate(79.87 1679.4 -684)" id="group_a"/>
<rect x="1679.4" y="-684" width="3246.55" height="359" transform="rotate(79.87 1679.4 -684)" id="group_a"/>
<rect x="1640.43" y="-449.895" width="2800" height="200" transform="rotate(79.87 1640.43 -449.895)" id="group_b"/>
<rect x="1640.43" y="-449.895" width="2800" height="200" transform="rotate(79.87 1640.43 -449.895)" id="group_b"/>
<rect x="1640.43" y="-449.895" width="2800" height="200" transform="rotate(79.87 1640.43 -449.895)" id="group_b"/>
<rect x="1640.43" y="-449.895" width="2800" height="200" transform="rotate(79.87 1640.43 -449.895)" id="group_b"/>
<rect width="3576.01" height="365.852" transform="matrix(0.983666 0.180004 -0.258859 0.965915 -81.2969 1212)" id="group_c"/>
<rect width="3576.01" height="365.852" transform="matrix(0.983666 0.180004 -0.258859 0.965915 -81.2969 1212)" id="group_c"/>
<rect width="3576.01" height="365.852" transform="matrix(0.983666 0.180004 -0.258859 0.965915 -81.2969 1212)" id="group_c"/>
<rect width="3576.01" height="365.852" transform="matrix(0.983666 0.180004 -0.258859 0.965915 -81.2969 1212)" id="group_c"/>
<rect width="3084.15" height="203.817" transform="matrix(0.983666 0.180004 -0.258859 0.965915 139.66 1334.86)" id="backgroundColor"/>
<rect width="3084.15" height="203.817" transform="matrix(0.983666 0.180004 -0.258859 0.965915 139.66 1334.86)" id="backgroundColor"/>
<rect width="3084.15" height="203.817" transform="matrix(0.983666 0.180004 -0.258859 0.965915 139.66 1334.86)" id="backgroundColor"/>
<rect width="3084.15" height="203.817" transform="matrix(0.983666 0.180004 -0.258859 0.965915 139.66 1334.86)" id="backgroundColor"/>
<rect x="806" y="2303.27" width="3246.55" height="359" transform="rotate(-48.25 806 2303.27)" id="group_d"/>
<rect x="806" y="2303.27" width="3246.55" height="359" transform="rotate(-48.25 806 2303.27)" id="group_d"/>
<rect x="806" y="2303.27" width="3246.55" height="359" transform="rotate(-48.25 806 2303.27)" id="group_d"/>
<rect x="806" y="2303.27" width="3246.55" height="359" transform="rotate(-48.25 806 2303.27)" id="group_d"/>
<rect x="1014" y="2189.96" width="2800" height="200" transform="rotate(-48.25 1014 2189.96)" id="group_e"/>
<rect x="1014" y="2189.96" width="2800" height="200" transform="rotate(-48.25 1014 2189.96)" id="group_e"/>
<rect x="1014" y="2189.96" width="2800" height="200" transform="rotate(-48.25 1014 2189.96)" id="group_e"/>
<rect x="1014" y="2189.96" width="2800" height="200" transform="rotate(-48.25 1014 2189.96)" id="group_e"/>
</g>
<defs>
<clipPath id="clip0_758_90">
<rect width="${mockupWidth}" height="${mockupHeight}" fill="white"/>
</clipPath>
</defs>
</svg>`

export const Mockup_SVG = {
	designSvg,
}
