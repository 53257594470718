/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useRef, useState } from "react"
import Styles from "./FeedbackAction.module.scss"
import Button from "@/components/Buttons/Button/Button"
import Checkbox from "deblank-common/src/components/legacy/Checkbox/Checkbox"
import classNames from "classnames"
import CloseIcon from "deblank-common/src/assets/images/icons/cross.svg"
import { useClickOutside } from "deblank-common/src/hooks/useClickOutside"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import FeedbackLikeIcon from "deblank-common/src/assets/images/icon-library/feedback-like.svg"
import FeedbackLikeIconFill from "deblank-common/src/assets/images/icon-library/feedback-like-fill.svg"
import FeedbackDislikeIcon from "deblank-common/src/assets/images/icon-library/feedback-no-like.svg"
import FeedbackDislikeIconFill from "deblank-common/src/assets/images/icon-library/feedback-no-like-fill.svg"
import { DisplayWidgetType } from "deblank-api-types"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"

export type FeedbackForm = {
	title: string,
	checkOptions: string[],
	suggestionOption?: string,
}

const maxCharacters = 1000

const FeedbackForm = (props: FeedbackForm & {
	onSubmit: (checkedOptions: string[],
		suggestion: string | undefined,
		email: string | undefined) => void,
	onCloseForm: () => void,
}) => {

	const [checkedOptions, setCheckedOptions,] = useState<string[]>([])
	const [suggestion, setSuggestion,] = useState("")
	const [email, setEmail,] = useState("")

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		props.onSubmit(checkedOptions, suggestion, email)
	}

	const handleCheckOption = (option: string) => () => {
		if (checkedOptions.includes(option)) {
			setCheckedOptions(checkedOptions.filter(o => o !== option))
		} else {
			setCheckedOptions([...checkedOptions, option,])
		}
	}

	const handleChangeSuggestion = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (event.target.value.length <= maxCharacters) {
			setSuggestion(event.target.value)
		}
	}

	const renderCheckOption = (option: string, index: number) => {
		const checked = checkedOptions.includes(option)
		const optionClass = classNames({
			[Styles.form_option]: true,
			[Styles.form_option_checked]: checked,
		})
		return (
			<li key={`feedback-option-${index}`}
				className={optionClass}
				onClick={handleCheckOption(option)}
			>
				<Checkbox
					size="small"
					variant="light"
					checked={checked}
					onChange={handleCheckOption(option)}
					customStyles={{ pointerEvents: "none", }}
				/>
				<p>{option}</p>
			</li>
		)
	}

	return (
		<div className={Styles.form_container}>
			<button
				type="button"
				className={Styles.close_button}
				onClick={props.onCloseForm}
			>
				<CloseIcon />
			</button>
			<form onSubmit={handleSubmit} className={Styles.form}>
				<p className={Styles.form_title}>{props.title}</p>
				<ul className={Styles.form_options_container}>
					{props.checkOptions
						&& props.checkOptions.map((o, i) => renderCheckOption(o, i))}
				</ul>
				{props.suggestionOption && (
					<div className={Styles.textbox_container}>
						<label htmlFor="suggestion" className={Styles.textbox_label}>
							{props.suggestionOption}
						</label>
						<textarea
							name="suggestion"
							value={suggestion}
							placeholder="Additional feedback or suggestions"
							onChange={handleChangeSuggestion}
							className={classNames({
								[Styles.textbox_input]: true,
								[Styles.textbox_textarea]: true,
							})}
							maxLength={maxCharacters}
						/>
					</div>
				)}
				<div className={Styles.textbox_container}>
					<label htmlFor="email" className={Styles.textbox_label}>
						Share your email for a chat with us 😊 (optional)
					</label>
					<input
						name="email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className={classNames({
							[Styles.textbox_input]: true,
						})}
						placeholder="Your email"
					/>

				</div>
				<div className={Styles.submit_button}>
					<Button
						type="submit"
						disabled={checkedOptions.length === 0}
						customStyles={{
							variant: "ghost",
						}}>
						Submit feedback
					</Button>
				</div>
			</form>
		</div>
	)
}

type Props = {
	likeFeedbackForm: FeedbackForm,
	dontLikeFeedbackForm: FeedbackForm,
	messageId: string,
	type: DisplayWidgetType,
}

const FeedbackAction = (props: Props) => {
	const [like, setLike,] = useState<boolean | null>(null)
	const { isOpen, elementRef, setIsOpen, } = useClickOutside()
	const timerRef = useRef<NodeJS.Timeout | null>(null)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current)
			}
		}
	}, [])

	const handleShowForm = (show: boolean) => {
		setIsOpen(show)
	}

	const handleClick = (like: boolean, typeOfInteraction: "like" | "dislike") => (event: React.MouseEvent) => {
		event.stopPropagation()
		setLike(like)
		handleShowForm(true)
		if (typeOfInteraction === "like") {
			trackConversationEvent({
				eventName: "LikeInspiration",
				responseId: props.messageId,
				widgetType: props.type,
			})
		}
		if (typeOfInteraction === "dislike") {
			trackConversationEvent({
				eventName: "DontLikeInspiration",
				responseId: props.messageId,
				widgetType: props.type,
			})
		}
	}

	const handleSubmited = (
		checkedOptions: string[],
		suggestion: string | undefined,
		email: string | undefined
	) => {
		const suggestionSplitted = suggestion
			? suggestion.match(/.{1,250}/g)
			: []


		trackConversationEvent({
			eventName: "SendFeedback",
			widgetType: props.type,
			responseId: props.messageId,
			interactionType: like ? "like" : "dislike",
			checkedOptions: checkedOptions,
			suggestion_part_1: suggestionSplitted![0],
			suggestion_part_2: suggestionSplitted![1],
			suggestion_part_3: suggestionSplitted![2],
			suggestion_part_4: suggestionSplitted![3],
			email: email,
		})
		setIsOpen(false)
	}

	const renderLike = () => {

		const className = classNames({
			[Styles.action_container]: true,
			[Styles.active]: like !== null && like,
		})

		return (
			<div className={className}>
				<ButtonIcon type="button"
					onClick={handleClick(true, "like")}
					customStyles={{
						variant: "ghost",
					}}
					iconSVGComponent={like
						? FeedbackLikeIconFill
						: FeedbackLikeIcon}
				/>
				{like && isOpen
					&& <FeedbackForm
						{...props.likeFeedbackForm}
						onSubmit={handleSubmited}
						onCloseForm={() => handleShowForm(false)}
					/>}
			</div>
		)
	}

	const renderDontLike = () => {
		const className = classNames({
			[Styles.action_container]: true,
			[Styles.active]: like !== null && !like,
		})

		return (
			<div className={className}>
				<ButtonIcon type="button"
					onClick={handleClick(false, "dislike")}
					customStyles={{
						variant: "ghost",
					}}
					iconSVGComponent={like !== null && !like
						? FeedbackDislikeIconFill
						: FeedbackDislikeIcon}
				/>
				{like !== null && !like && isOpen
					&& <FeedbackForm
						{...props.dontLikeFeedbackForm}
						onSubmit={handleSubmited}
						onCloseForm={() => handleShowForm(false)}
					/>}
			</div>
		)
	}

	return (
		<div className={Styles.container} ref={elementRef} >
			{renderLike()}
			{renderDontLike()}
		</div>
	)
}

export default FeedbackAction
