/* eslint-disable no-process-env */
import { initializeApp, } from "firebase/app"
import { getAuth } from "firebase/auth"
import { EnvHelperClient } from "../utils/envHelper"

const app = initializeApp(EnvHelperClient.authenticationConfig)

export const auth = getAuth(app)

export default app
