import React from "react"
import Styles from "./InputRange.module.scss"

type Props = {
	value: number,
	labelValueUnit: string,
	min?: number,
	max: number,
	onChange: (value: number) => void,

}

const InputRange = (props: Props) => {

	return <label className={Styles.wrapper}>
		<div className={Styles.label}>
			{props.value}
			{props.labelValueUnit}
		</div>
		<input type="range"
			className={Styles.input}
			value={props.value}
			min={props.min || 0}
			max={props.max}
			onChange={e => props.onChange(Number(e.target.value))}
		/>
	</label>
}

export default InputRange
