import { selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { Conversation } from "../Types"
import { prefixRecoilKey } from "./Common"

const historyConversations = selector<{
	index: number,
	name: string,
	creationDate: string,
}[]>({
	key: prefixRecoilKey("historyConversations"),
	get: ({ get, }) => {
		const conversationsRecord = get(atomConversationsRecord)

		return conversationsRecord.conversations.map((conversation, index) => {
			const firstMessage = conversation.messages["message-0"]
			const conversationName = (firstMessage && firstMessage.userMessage && firstMessage.userMessage.message)
				? firstMessage.userMessage.message
				: "Untitled Conversation"

			return {
				index: index,
				name: conversationName,
				creationDate: conversation.creationDate,
			}
		})
	},
})

const currentConversation = selector<Conversation | null>({
	key: prefixRecoilKey("currentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		return conversations[activeConversationIndex]
			? conversations[activeConversationIndex]
			: null
	},
})

const currentConversationId = selector<string | null>({
	key: prefixRecoilKey("currentConversationId"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		return conversations[activeConversationIndex]
			? conversations[activeConversationIndex].id
			: null
	},
})

const currentConversationIndex = selector<number | null>({
	key: prefixRecoilKey("currentConversationIndex"),
	get: ({ get, }) => {
		const { activeConversationIndex, } = get(atomConversationsRecord)
		return activeConversationIndex ?? null
	},
})

const isNewChat = selector<boolean>({
	key: prefixRecoilKey("isNewChat"),
	get: ({ get, }) => {
		const { isNewChat, } = get(atomConversationsRecord)
		return isNewChat
	},
})

const chatAmount = selector<number>({
	key: prefixRecoilKey("chatAmount"),
	get: ({ get, }) => {
		const { conversations, } = get(atomConversationsRecord)
		return conversations.length
	},
})


export const selectorsConversations = {
	currentConversation,
	historyConversations,
	currentConversationIndex,
	currentConversationId,
	chatAmount,
	isNewChat,
}
