
import { useState, useEffect } from "react"

type WindowSize = {
	width: number,
	height: number,
	isMobile: boolean,
	isTablet: boolean,
	isDesktopSM: boolean,
	isDesktop: boolean,
	isDesktopMD: boolean,
	isDesktopLG: boolean,
	device: "Mobile" | "Tablet" | "Desktop",
}

const isMobile = (width: number) => {
	return width < 768
}
const isTablet = (width: number) => {
	return width >= 768 && width < 1024
}
const isDesktopSM = (width: number) => {
	return width >= 1024 && width < 1200
}
const isDesktop = (width: number) => {
	return width >= 1200 && width < 1440
}
const isDesktopMD = (width: number) => {
	return width >= 1440 && width < 1900
}
const isDesktopLG = (width: number) => {
	return width >= 1900
}
const getDevice = (width: number): "Tablet" | "Mobile" | "Desktop" => {
	if (isTablet(width)) {
		return "Tablet"
	} else if (isMobile(width)) {
		return "Mobile"
	} else {
		return "Desktop"
	}
}

export const useWindowSize = (): WindowSize => {


	const [_, setRender,] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setRender(r => !r)
		}

		window.addEventListener("resize", handleResize)
		setRender(r => !r)


		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return {
		width: window.innerWidth,
		height: window.innerHeight,
		isMobile: isMobile(window.innerWidth),
		isTablet: isTablet(window.innerWidth),
		isDesktopSM: isDesktopSM(window.innerWidth),
		isDesktop: isDesktop(window.innerWidth),
		isDesktopMD: isDesktopMD(window.innerWidth),
		isDesktopLG: isDesktopLG(window.innerWidth),
		device: getDevice(window.innerWidth),
	}

}
