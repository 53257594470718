/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import Shadow from "./images/shadow.png"
import Lights from "./images/lights.png"
import BodyMask from "./images/body_mask.png"
import Design from "./images/design.png"
import PotTop from "./images/potTop.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/design/y5UsL35vcLmufMsTJ93EOe/Assistant-Mockups?node-id=168-47&t=yQZgo6dQsBDNy0uX-0

const mockupId = FontsPackagingMockupID.fonts_packaging_mockup_2
const mockupWidth = 1987.08
const mockupHeight = 1380.36

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { app, } = props
	const ret = await props.semaphore.add(async () => {

		const [
			backgroundSprite, shadowSprite,
			lightsSprite, bodyMaskSprite,
			designSprite, potTopSprite,
		] = await MockupUtils.loadAssets([
			Background, Shadow, Lights, BodyMask,
			Design, PotTop,
		])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight
		const mockupShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(mockupShape)
		mockupContainer.addChild(backgroundSprite)



		// Pot
		const potContainer = new PIXI.Container()
		const potWidth = 908.76
		const potHeight = 992.22
		potContainer.width = potWidth
		potContainer.height = potHeight
		potContainer.addChild(designSprite)
		const text = await MockupUtils.createText({
			text: "ice masters",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 400,
			color: "#FFFFFF",
			fontSize: 126,
			padding: 100,
			trim: true,
			fitText: {
				containerWidth: 650,
			},
		})

		text.position.set((potWidth - text.width) / 2, 297)
		potContainer.addChild(text)

		const potDesignProjection = MockupUtils.project({
			app: app,
			container: potContainer,
			projectionPoints: {
				topLeft: { x: 653, y: 605, },
				topRight: { x: 1268, y: 605, },
				bottomRight: { x: 1196, y: 1232, },
				bottomLeft: { x: 719, y: 1232, },
			},
		})
		const potBodyContainer = new PIXI.Container()
		potBodyContainer.addChild(potDesignProjection)
		potBodyContainer.addChild(bodyMaskSprite)
		potBodyContainer.mask = bodyMaskSprite
		mockupContainer.addChild(potBodyContainer)
		mockupContainer.addChild(potTopSprite)

		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(lightsSprite)
		mockupContainer.addChild(shadowSprite)

		const imageData = MockupUtils.exportImage({
			app: props.app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!

}

export const Fonts_Mockup_Packaging_2: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
