import React, { useContext } from "react"
import Styles from "./FontExplanation.module.scss"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DisplayWidgetType, FontExplanationsResponse, IdRefWidgetWithPaginationItem } from "deblank-api-types"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	type: DisplayWidgetType,
	widgetData: FontExplanationsResponse,
}

const FontExplanation = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(props.widgetData.slug))

	return (
		<article className={`${Styles.wrapper}`}>
			<div className={Styles.header}>
				<h3 className={Styles.fonts_name}>
					{fontDetails?.metadata.name}
				</h3>

				<a
					className={Styles.link_vendor}
					href={fontDetails?.metadata.linkToVendor}
					target="_blank"
					rel="noreferrer"
					onClick={async () => {
						await trackConversationEvent({
							eventName: "ClickGetFont",
							fontName: fontDetails!.metadata.name,
							responseId: props.idRef.messageId,
						})
					}}
				>
					<LinkIcon />
				</a>
			</div>

			<div className={Styles.content_explanations}>
				<p>
					{`Characteristics: ${props.widgetData.explanation.characteristics}`}
				</p>

				<p>
					{`Usage: ${props.widgetData.explanation.usage}`}
				</p>
			</div>
		</article>
	)
}

export default FontExplanation
