// @ts-expect-error - types declaration missing
import ColorThief from "colorthief"
import chroma from "chroma-js"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { ColorsRelatedActions, DisplayWidgetType, ImagesRelatedActions } from "deblank-api-types"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { getRandomItemFromArray } from "@/utils/arrayUtils"

type Step1 = ImageSelectValue

type Image_Related_1_State = OneStepAction<Step1>

function createPalette(amount: number, img: HTMLImageElement): string[] {
	const colorThief = new ColorThief()
	const colorResult: string[] = []
	const rgbColors = colorThief.getPalette(img, amount, 1)

	rgbColors.forEach((color: number[]) => {
		const hexColor = chroma.rgb(color[0], color[1], color[2]).hex()
		colorResult.push(hexColor)
	})

	return colorResult
}

export const Image_Related_Action_Config_1
	= ((): RelatedActionData<Image_Related_1_State> => {

		const handleProcessAction = (context: RelatedActionContext<Image_Related_1_State>) =>
			// eslint-disable-next-line require-await
			async () => {
				const img = new Image()
				img.crossOrigin = "Anonymous"
				img.src = context.currentValue.step1.widgetItemDetails.imageUrl

				img.addEventListener("load", () => {
					context.onAddCreateNotifications({
						notifications: [
							{
								relatedActions: [
									getRandomItemFromArray([
										ColorsRelatedActions.HowToApplyColorsInUIDesign,
										ColorsRelatedActions.CheckColorPaletteAccessibility,
									]),
									ColorsRelatedActions.ApplyColorPaletteOnMockup,
									getRandomItemFromArray([
										ColorsRelatedActions.GenerateImagesWithColorPalette,
										ColorsRelatedActions.SearchForImagesWithColorPalette,
									]),
									getRandomItemFromArray([
										ColorsRelatedActions.ExpandSaturationLevels,
										ColorsRelatedActions.ExpandColorPaletteHues,
										ColorsRelatedActions.SeeColorPaletteGradients,
										ColorsRelatedActions.ModifyColorPaletteLightness,
									]),
								],
								output: {
									type: DisplayWidgetType.Colors,
									metadata: null,
									data: {
										isPaginated: true,
										pages: [{
											results: [{
												palette: createPalette(6, img),
											},],
											pageNumber: 0,
										},],
									},
								},
								generatedByWidget: context.idRef,
								userMessage: {
									message: "Extract color palette from this image",
									idRefs: [context.currentValue.step1.idRef,],
								},
								// eslint-disable-next-line max-len
								internalMessageForAgent: `The user extracted the colors from the image with ID "${context.currentValue.step1.idRef.widgetItemId}"`,
							},
						],
					})
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Image_Related_1_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Image_Related_1_State>) =>
				(data: ImageSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
		}

		return {
			id: ImagesRelatedActions.ExtractColorPalette,
			label: "Extract Color Palette",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.images,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select an image",
						componentType: ConfigStepType.select,
						selectType: SelectType.images,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
