import React from "react"
import Styles from "./WidgetBubbleUserMessageMockups.module.scss"
import { CommonWidgetMockupsItemProps } from "../WidgetMockupsTypes"
import { getImage } from "@/utils/ImageHelper"


const WidgetBubbleUserMessageMockups = (props: CommonWidgetMockupsItemProps) => {

	const { dataUrl, } = getImage({
		id: props.widgetItemDetails.mockupId,
	})

	return (
		<figure className={Styles.selected_image}>
			<img src={dataUrl} alt="ai generated" />
		</figure>
	)
}

export default WidgetBubbleUserMessageMockups
