import { useEffect } from "react"


export const useEscKey = (params: {
	onPress: () => void,
	disable?: boolean,
}) => {
	useEffect(() => {

		const close = (e: KeyboardEvent) => {
			if (e.code === "Escape") {
				if (!params.disable) {
					params.onPress()
				}
			}
		}
		window.addEventListener("keydown", close)
		return () => window.removeEventListener("keydown", close)


	}, [params.disable,])
}
