import React, { useContext, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import AssistantLayout from "./components/AssistantLayout/AssistantLayout"
import CurrentConversation from "./components/CurrentConversation/CurrentConversation"
import Sidebar from "./components/Sidebar/Sidebar"
import PinnedList from "./components/PinnedList/PinnedList"
import DebugPanel from "./components/DebugPanel/DebugPanel"
import ConversationsRecordManager from "./managers/ConversationsRecordManager/ConversationsRecordManager"
import FontsCacheManager from "./managers/FontsCacheManager/FontsCacheManager"
import { PortalRootLayoutAssistantId } from "@/components/Portal/Portal"
import { TrackConversationEventProvider } from "./TrackConversationEventProvider"
import { UserContext } from "@/providers/UserProvider"
import { trackEvent } from "@/utils/mixpanel/mixpanel"
import { ColorsPlayground } from "./components/ColorsPlayground/ColorsPlayground"
import { FontsPlayground } from "./components/FontsPlayground/FontsPlayground"
import { ViewImageModal } from "./components/ViewImageModal/ViewImageModal"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


const AssistantPage = () => {
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const windowsSize = useWindowSize()
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)
	const userContext = useContext(UserContext)

	useEffect(() => {
		trackEvent({
			eventName: "AccessProduct",
			user: userContext.user?.displayName || userContext.user?.email || "",
			device: windowsSize.device,
			deviceWidth: window.innerWidth,
		})
	}, [])

	usePreventScroll({
		prevent: (isSidebarOpen || isPinnedListOpen)
			&& (windowsSize.isMobile || windowsSize.isTablet),
	})

	return (
		<TrackConversationEventProvider activeConversationId={currentConversation?.id}>
			<ConversationsRecordManager />
			<FontsCacheManager />
			<AssistantLayout
				sidebar={<Sidebar />}
				currentConversation={<CurrentConversation />}
				pinned={<PinnedList />}
				debug={<DebugPanel />}
				colorsPlayground={<ColorsPlayground />}
				fontsPlayground={<FontsPlayground />}
				imageModal={<ViewImageModal />}
			/>
			<div id={PortalRootLayoutAssistantId} />
		</TrackConversationEventProvider>
	)
}

export default AssistantPage
