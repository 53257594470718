import React from "react"
import Styles from "./FontSelectCard.module.scss"
import { FontResponse } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"

type Props = {
	fontData: FontResponse,
}

const FontSelectCard = (props: Props) => {

	const fontFromCache = useRecoilValue(selectorsFontCache.fontFromCache(props.fontData.slug))

	return (
		fontFromCache
			? <div style={fontFromCache.fontCSSProperties} className={Styles.title} >
				{fontFromCache.metadata.name}
			</div>
			: <></>
	)
}

export default FontSelectCard
