import React from "react"
import Styles from "./SearchBadget.module.scss"
import { EntrySearchesResult, SearchFrom, IdRefWidget } from "deblank-api-types"
import DribbbleIcon from "deblank-common/src/assets/images/external-brands/dribbble.svg"
import FreefloIcon from "deblank-common/src/assets/images/external-brands/freeflo.svg"
import { useRecoilValue } from "recoil"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"

type Props = {
	idRef: IdRefWidget,
}

const SearchBadget = (props: Props) => {

	const widget = useRecoilValue(
		selectorsWidgets.outputById(props.idRef.widgetId)
	) as EntrySearchesResult

	const searchFrom = widget.data.results[0].searchFrom

	return <div className={Styles.external_search_wrapper}>

		<div className={Styles.external_search_icon_wrapper}>
			{searchFrom === SearchFrom.Dribbble
				? <DribbbleIcon
					className={`${Styles.external_search_icon} disabled_custom_color`}
				/>
				: <FreefloIcon
					className={`${Styles.external_search_icon} disabled_custom_color`}
				/>
			}
		</div>

		<p className={Styles.external_search_text}>
			Images from <b className={Styles.external_search_text_bold}>{searchFrom}</b>
		</p>

	</div>
}

export default SearchBadget
