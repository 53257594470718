import { Variants, HTMLMotionProps } from "framer-motion"

const variantsImage: Variants = {
	hidden: {
		opacity: 0.3,
		filter: "blur(10px)",
	},
	visible: {
		opacity: 1,
		filter: "blur(0px)",
	},
}

const transitionImage: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
	delay: 0.1,
}

export const imageMotionProps = {
	variants: variantsImage,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transitionImage,
}
