import { Output } from "deblank-api-types"
import { selector, selectorFamily } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { Message } from "../Types"
import { prefixRecoilKey } from "./Common"


const messagesInCurrentConversation = selector<Message[] | null>({
	key: prefixRecoilKey("messagesInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]
		const messages = currentConversation
			? Object.values(conversations[activeConversationIndex].messages)
			: null

		return messages && messages.length
			? messages
			: null
	},
})

const someMessageIsLoading = selector<boolean>({
	key: prefixRecoilKey("someMessageIsLoading"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversations = conversations[activeConversationIndex]

		return currentConversations
			? Object.values(currentConversations.messages).some(message => message.state === "loading")
			: false
	},
})

const messageById = selectorFamily<Message, string>({
	key: prefixRecoilKey("messageById"),
	get: (id) => ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]!

		return currentConversation.messages[id]
	},
})

const getMessageDataByMessageId = selector<Message & { outputs: Output[], }>({
	key: prefixRecoilKey("getTypesByMessageId"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const pendingMessageId = conversations[activeConversationIndex]?.pendingMessage?.messageId
		const message = conversations[activeConversationIndex]?.messages[pendingMessageId!]

		return {
			...message,
			outputs: message ? message.outputsIdsByIterations
				.flatMap(iteration => conversations[activeConversationIndex]?.outputs![iteration.outputId])
				: [],
		}
	},
})

const isThisMessageLast = selectorFamily<boolean, string>({
	key: prefixRecoilKey("isThisMessageLast"),
	get: (id) => ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]

		const messages = Object.values(currentConversation.messages)
		const lastMessage = messages[messages.length - 1]

		return lastMessage.id === id
	},
})

const getLastMessage = selector<Message | null>({
	key: prefixRecoilKey("getLastMessage"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]
		const messages = currentConversation
			? Object.values(conversations[activeConversationIndex].messages)
			: null

		const lastMessage = messages ? messages.slice(-1) : null

		return lastMessage ? lastMessage[0] : null
	},
})


export const selectorsMessages = {
	messagesInCurrentConversation,
	messageById,
	someMessageIsLoading,
	getLastMessage,
	getMessageDataByMessageId,
	isThisMessageLast,
}
