import React, { useContext } from "react"
import Styles from "./ImageCard.module.scss"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/Widgets/WidgetsTypes"
import { ExportHelper } from "@/utils/exportHelper"
import classNames from "classnames"
import { IdRefWidgetWithPaginationItem, DisplayWidgetType, SearchResponse, ImageResponse, SearchFrom } from "deblank-api-types"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	type: DisplayWidgetType,
	widgetData: SearchResponse | ImageResponse,
}

const ImageCard = (
	props: Props
) => {
	const [actionsOpen, setActionsOpen,] = React.useState<boolean>(false)
	const ref = React.useRef<HTMLImageElement>(null)
	const setImageModal = settersCommon.useSetImageModalIdRef()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.type,
	})


	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const handleExportFromSearch = () => {
		ExportHelper.fromImage(props.widgetData.imageUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Searches,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})

	}

	const handleExportFromGeneratedImage = () => {
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
		ExportHelper.fromImage(
			props.widgetData.imageUrl
		)
	}

	const getActions = () => {
		const searchDetails = props.type === DisplayWidgetType.Searches && props.widgetData as SearchResponse
		const actionList: widgetAction[] = [
			pinPresetAction,
		]

		if (searchDetails) {
			const newActions = [
				{
					text: "Go to site",
					icon: LinkIcon,
					onClick: () => {
						trackConversationEvent({
							eventName: "ClickImageLink",
							widgetType: DisplayWidgetType.Images,
							responseId: props.idRef.messageId,
						})
						window.open(searchDetails.link, "_blank")
					},
				},
			]
			if (searchDetails.searchFrom === SearchFrom.Freeflo) {
				newActions.push(...[{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromSearch(),
				}, {
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
			} else {
				newActions.push({
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				})
			}

			actionList.push(...newActions)

		} else {
			actionList.push(...[
				sendToChatPresetAction,
				{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromGeneratedImage(),
				},
				{
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
		}
		return actionList
	}


	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<figure
			className={wrapperClasses}
		>
			<button type="button"
				onClick={handleOpenModal}
				className={Styles.image}
			>
				<img ref={ref} src={props.widgetData.imageUrl}
					alt="generated by the user"
				/>
			</button>

			<Actions
				actionList={getActions()}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "images",
				}}
			/>
		</figure>
	)
}

export default ImageCard
