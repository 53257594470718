/* eslint-disable max-len */
const mockupHeight = 1615

const simplesideDesignSvg = `
<svg width="${mockupHeight}" height="${mockupHeight}" viewBox="0 0 ${mockupHeight} ${mockupHeight}" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="${mockupHeight}" height="${mockupHeight}" id="backgroundColor"/>
<circle cx="811" cy="717" r="400" id="group_a"/>
<circle cx="808" cy="717" r="200" id="group_b"/>
<circle cx="811" cy="717" r="150" id="group_c"/>
<circle cx="808" cy="717" r="100" id="group_d"/>
<circle cx="808" cy="717" r="50" id="group_c"/>
</svg>
`

const simpleMiddleDesignSvg = `
<svg width="${mockupHeight}" height="${mockupHeight}" viewBox="0 0 ${mockupHeight} ${mockupHeight}" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="${mockupHeight}" height="${mockupHeight}" id="backgroundColor"/>
<circle cx="808" cy="743" r="400" id="group_a"/>
<circle cx="805" cy="743" r="200" id="group_b"/>
<circle cx="808" cy="743" r="150" id="group_c"/>
<path d="M905 743C905 798.228 860.228 843 805 843C749.772 843 705 798.228 705 743C705 687.772 749.772 643 805 643C860.228 643 905 687.772 905 743Z" id="group_d"/>
</svg>
`

const middleDesignSVG = (params: { colorA: string, colorB: string, colorC: string, colorD: string, }) => `
<svg width="1072" height="1068" viewBox="0 0 1072 1068" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1071.57" height="1068" fill="${params.colorA}"/>
<path d="M479.182 398.316C360.704 431.2 289.89 552.101 317.707 670.779L360.947 658.775C339.715 563.981 396.573 467.816 491.181 441.556C585.837 415.287 684.078 468.397 714.743 560.577L757.983 548.573C720.7 432.528 597.708 365.418 479.182 398.316Z" fill="${params.colorB}"/>
<path d="M491.196 441.553C396.583 467.813 339.725 563.978 360.962 658.772L404.33 646.735C389.665 575.777 432.414 504.439 503.195 484.793C574.024 465.132 647.424 504.235 671.427 572.601L714.753 560.578C684.093 468.394 585.852 415.279 491.196 441.553Z" fill="${params.colorC}"/>
<path d="M503.177 484.796C432.391 504.442 389.646 575.78 404.312 646.738L447.68 634.7C439.509 587.646 468.265 541.059 515.181 528.036C562.139 515.003 610.788 540.106 628.04 584.642L671.408 572.604C647.405 504.238 574.005 465.135 503.177 484.796Z" fill="${params.colorD}"/>
</svg>
`

const sideDesignSVG = (params: { colorA: string, colorB: string, colorC: string, colorD: string, }) => `
<svg width="1065" height="1068" viewBox="0 0 1065 1068" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1064.43" height="1068" fill="${params.colorA}"/>
<path opacity="0.5" d="M523.98 689.035C515.824 689.035 508.162 684.912 502.42 677.422C497.73 671.313 494.411 663.323 492.468 653.776C482.259 665.403 471.17 671.846 460.128 671.846C449.728 671.846 441.086 665.975 437.015 656.133C433.191 646.9 433.696 635.31 438.1 622.311C430.996 624.706 424.287 625.949 418.116 625.949C398.641 625.949 390.156 613.974 388.804 603.722C387.104 590.808 394.28 578.633 406.693 567.924C381.052 562.791 371.391 548.783 371.391 536.441C371.391 524.099 381.052 510.099 406.688 504.966C394.28 494.258 387.099 482.077 388.799 469.164C390.147 458.912 398.636 446.937 418.116 446.937C424.282 446.937 430.996 448.18 438.1 450.575C433.696 437.576 433.187 425.991 437.01 416.758C441.086 406.916 449.728 401.04 460.128 401.04C471.165 401.04 482.255 407.482 492.468 419.11C494.411 409.563 497.73 401.573 502.42 395.464C508.162 387.979 515.819 383.855 523.98 383.855C532.137 383.855 539.798 387.979 545.541 395.464C550.231 401.573 553.555 409.563 555.493 419.11C565.702 407.482 576.791 401.04 587.829 401.04C598.228 401.04 606.87 406.911 610.946 416.753C614.77 425.986 614.265 437.576 609.86 450.575C616.965 448.18 623.679 446.937 629.85 446.937C649.324 446.937 657.81 458.912 659.157 469.164C660.857 482.077 653.681 494.253 641.273 504.962C666.914 510.095 676.57 524.103 676.57 536.445C676.57 548.787 666.909 562.786 641.273 567.924C653.681 578.628 660.862 590.808 659.162 603.722C657.81 613.974 649.324 625.949 629.845 625.949C623.679 625.949 616.96 624.706 609.86 622.311C614.265 635.305 614.774 646.895 610.951 656.128C606.875 665.97 598.233 671.846 587.833 671.846C576.796 671.846 565.706 665.408 555.497 653.776C553.559 663.323 550.236 671.313 545.546 677.422C539.798 684.912 532.142 689.035 523.98 689.035Z" fill="${params.colorB}"/>
<path d="M532.152 680.855C523.996 680.855 516.334 676.731 510.592 669.246C505.902 663.137 502.583 655.147 500.64 645.6C490.431 657.228 479.342 663.67 468.299 663.67C457.9 663.67 449.258 657.799 445.187 647.957C441.363 638.724 441.868 627.134 446.272 614.135C439.168 616.53 432.459 617.773 426.288 617.773C406.813 617.773 398.328 605.798 396.976 595.546C395.276 582.633 402.451 570.457 414.865 559.748C389.224 554.615 379.562 540.607 379.562 528.265C379.562 515.923 389.224 501.924 414.86 496.791C402.451 486.082 395.271 473.902 396.971 460.988C398.318 450.737 406.808 438.761 426.288 438.761C432.454 438.761 439.168 440.004 446.272 442.399C441.868 429.4 441.358 417.815 445.182 408.582C449.258 398.74 457.9 392.864 468.299 392.864C479.337 392.864 490.427 399.307 500.64 410.935C502.583 401.388 505.902 393.398 510.592 387.288C516.334 379.803 523.991 375.68 532.152 375.68C540.309 375.68 547.97 379.803 553.713 387.288C558.403 393.398 561.727 401.388 563.665 410.935C573.873 399.307 584.963 392.864 596.005 392.864C606.405 392.864 615.047 398.735 619.118 408.578C622.942 417.81 622.437 429.4 618.032 442.399C625.137 440.004 631.85 438.761 638.021 438.761C657.496 438.761 665.982 450.737 667.329 460.988C669.029 473.902 661.853 486.077 649.445 496.786C675.086 501.919 684.742 515.928 684.742 528.27C684.742 540.616 675.081 554.611 649.445 559.748C661.853 570.452 669.034 582.633 667.334 595.546C665.982 605.798 657.496 617.773 638.017 617.773C631.85 617.773 625.132 616.53 618.032 614.135C622.437 627.13 622.946 638.719 619.123 647.952C615.047 657.794 606.405 663.67 596.005 663.67C584.968 663.67 573.878 657.232 563.665 645.6C561.727 655.147 558.403 663.137 553.713 669.246C547.97 676.731 540.314 680.855 532.152 680.855Z" fill="${params.colorC}"/>
<path d="M543.585 485.716C577.792 358.058 486.574 358.058 520.782 485.716C486.574 358.058 407.58 403.665 501.03 497.12C407.575 403.665 361.968 482.664 489.626 516.867C361.968 482.659 361.968 573.877 489.626 539.67C361.968 573.877 407.575 652.872 501.03 559.421C407.575 652.876 486.574 698.483 520.782 570.825C486.574 698.483 577.792 698.483 543.585 570.825C577.792 698.483 656.787 652.876 563.336 559.421C656.792 652.876 702.398 573.877 574.74 539.67C702.403 573.877 702.403 482.659 574.74 516.867C702.403 482.659 656.792 403.665 563.336 497.12C656.787 403.665 577.792 358.053 543.585 485.716Z" fill="${params.colorD}"/>
<path d="M532.17 564.817C552.354 564.817 568.715 548.455 568.715 528.272C568.715 508.088 552.354 491.727 532.17 491.727C511.987 491.727 495.625 508.088 495.625 528.272C495.625 548.455 511.987 564.817 532.17 564.817Z" fill="${params.colorC}"/>
</svg>
`

export const Mockup_SVG = {
	simplesideDesignSvg,
	simpleMiddleDesignSvg,
	middleDesignSVG,
	sideDesignSVG,
}
