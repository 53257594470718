/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useRef, useState } from "react"
import Styles from "./CurrentConversation.module.scss"
import { useRecoilValue } from "recoil"
import ChatBubbleUser from "@/components/Chat/BubbleUser/BubbleUser"
import PromptToolbar from "./PromptToolbar/PromptToolbar"
import Suggestions from "./Suggestions/Suggestions"
import classNames from "classnames"
import WidgetSelector from "./Widgets/WidgetSelector"
import WidgetWrapperSelector from "./Widgets/WidgetWrapper/WidgetWrapperSelector"
import { IdRefType } from "deblank-api-types"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import ArrowDownIcon from "deblank-common/src/assets/images/icon-library/arrow-down.svg"
import { AnimatePresence, m, motion } from "framer-motion"
import { UserContext } from "@/providers/UserProvider"
import InitialMessage from "./InitialMessage/InitialMessage"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import AlphaUsersModal from "./AlphaUsersComponents/AlphaUsersModal/AlphaUsersModal"
import { EnvHelperClient } from "@/utils/envHelper"
import InstagramFooter from "./AlphaUsersComponents/InstagramFooter/InstagramFooter"
import { getAccessors } from "@/utils/Accessor"

const [
	getUserInteractAlphaModal,
	setUserInteractAlphaModal,
] = getAccessors<boolean | null>(
	"deblankUserInteractAlphaModal",
	() => localStorage,
	() => null
)

const CurrentConversation = () => {
	const conversationId = useRecoilValue(selectorsConversations.currentConversationId)
	const messages = useRecoilValue(selectorsMessages.messagesInCurrentConversation)
	const isNewChat = useRecoilValue(selectorsConversations.isNewChat)
	const chatAmount = useRecoilValue(selectorsConversations.chatAmount)
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const userContext = useContext(UserContext)
	const chatContainerRef = useRef<null | HTMLDivElement>(null)
	const [showScrollButton, setShowScrollButton,] = useState(false)
	const [showAlphaUsersModal, setShowAlphaUsersModal,] = useState(false)
	const [showInstagramFooter, setShowInstagramFooter,] = useState(true)

	const spaceChatClasses = classNames(
		Styles.space_chat_area,
		{
			[Styles.not_empty]: !(isNewChat),
		}
	)

	const conversationClases = classNames(
		Styles.conversation_container,
		{
			[Styles.not_new_chat]: !(isNewChat),
		}
	)

	const loadingTextVariants = {
		hidden: { opacity: 0, y: 20, },
		visible: { opacity: 1, y: 0, },
		exit: { opacity: 0, y: -20, },
	}

	useEffect(() => {
		const userInteractWithModal = getUserInteractAlphaModal()
		if (!userInteractWithModal && messages && (messages.length >= EnvHelperClient.alphaUserModalMessages)) {
			setShowAlphaUsersModal(true)
		}
	}, [messages,])

	useEffect(() => {
		const handleScroll = () => {
			if (chatContainerRef.current) {
				const headerElement = document.getElementById("assistant-header")
				const headerHeight = headerElement ? headerElement.offsetHeight : 0
				// eslint-disable-next-line max-len
				const promptHeight = (document.getElementById("assistant-prompt-toolbar") as HTMLDivElement)?.offsetHeight || 0
				const windowHeight = window.innerHeight
				const chatHeight = chatContainerRef.current.scrollHeight

				const chatVisibleHeight = windowHeight - headerHeight - promptHeight
				const shouldShowButton = chatHeight > chatVisibleHeight

				const rect = chatContainerRef.current.getBoundingClientRect()
				//Se calcula con el top del chatContainerRef y no con el scrollTop porque siempre es 0, ademas el 9 es por el gap
				//que hay entre el prompt y el space_chat_area
				const isAtBottom = Math.abs(rect.top) + chatVisibleHeight + headerHeight - 9 >= chatHeight - 30

				setShowScrollButton(shouldShowButton && !isAtBottom && !areMessagesLoading)
			}
		}

		handleScroll()
		window.addEventListener("scroll", handleScroll)
		window.addEventListener("resize", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
			window.removeEventListener("resize", handleScroll)
		}
	}, [chatContainerRef, messages, areMessagesLoading,])

	useEffect(() => {
		if (chatContainerRef
			&& chatContainerRef.current
			&& areMessagesLoading
		) {
			window.scrollTo({
				top: chatContainerRef.current.scrollHeight,
				behavior: "smooth",
			})
		}

	}, [messages, areMessagesLoading,])

	const handleScrollToBottom = () => {
		if (chatContainerRef.current) {
			window.scrollTo({
				top: chatContainerRef.current.scrollHeight,
				behavior: "smooth",
			})
			setShowScrollButton(false)
		}
	}

	const showInitialMessage = userContext.isNewUser && chatAmount === 0

	return (
		<section className={conversationClases}>
			<AnimatePresence>
				{showAlphaUsersModal && <AlphaUsersModal
					onClose={() => setShowAlphaUsersModal(false)}
					onInteract={() => setUserInteractAlphaModal(true)}
				/>}
			</AnimatePresence>
			<section className={spaceChatClasses}
				key={conversationId}
				ref={chatContainerRef}
			>
				{
					(messages && messages.length > 0) && !isNewChat
						? messages.map((message, i) => (
							<section key={message.id}>
								{message.userMessage
									&& <div className={Styles.bubble_container}>
										<ChatBubbleUser
											message={message.userMessage.message}
											idRefs={message.userMessage.idRefs}

										/>
									</div>
								}

								{message.outputsIdsByIterations
									&& message.outputsIdsByIterations.map((iteration, iterationIndex) => (
										<WidgetWrapperSelector
											key={`${message.id}`}
											idRef={{
												type: IdRefType.Widget,
												messageId: message.id,
												widgetId: iteration.outputId,
											}}
											activePageIndex={iteration.activePageIndex}
											iterationIndex={iterationIndex}
											message={iteration.message}
											onScrollToBottom={handleScrollToBottom}
											containerWidth={chatContainerRef
												.current!.getBoundingClientRect().width}
											isLastOutputMessage={(i === messages.length - 1)
												&& (iterationIndex === message.outputsIdsByIterations.length - 1)}
										>
											<WidgetSelector
												key={iteration.outputId}
												activePageIndex={iteration.activePageIndex || 0}
												idRef={{
													type: IdRefType.Widget,
													messageId: message.id,
													widgetId: iteration.outputId,
												}}
											/>
										</WidgetWrapperSelector>
									))
								}
							</section>
						))
						: showInitialMessage
							? <InitialMessage />
							: <Suggestions />
				}
				{showScrollButton && <div className={Styles.scroll_button}>
					<ButtonIcon
						type="button"
						onClick={handleScrollToBottom}
						customStyles={{ variant: "secondary", }}
						iconSVGComponent={ArrowDownIcon}
					/>
				</div>}
				<AnimatePresence>
					{areMessagesLoading && (
						<div className={Styles.loading_message}>
							<motion.p
								className={Styles.loading_text}
								initial="hidden"
								animate="visible"
								exit="exit"
								variants={loadingTextVariants}
								transition={{ duration: 0.5, ease: "easeOut", }}
							>
								Loading results
							</motion.p>
						</div>
					)}
				</AnimatePresence>
			</section >

			<section className={Styles.space_prompt_toolbar} id="assistant-prompt-toolbar">
				<section className={Styles.wrapper_toolbar}>
					<section className={Styles.content_toolbar}>
						<AnimatePresence>
							{!showInitialMessage && <m.div
								initial={{ opacity: 0, y: 100, }}
								animate={{ opacity: 1, y: 0, }}
								transition={{ duration: 0.2, ease: "easeOut", }}
							>
								<PromptToolbar />
							</m.div>}
						</AnimatePresence>
					</section>
				</section>

				{isNewChat && (<section className={Styles.terms_container}>
					By sending a message to Deblank, you agree to our&nbsp;
					<a href="https://deblank.com/legal/user-terms" target="_blank" rel="noreferrer">Terms</a> and acknowledge
					that you have read our&nbsp;
					<a href="https://www.envato.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.
				</section>)}
			</section>
			<AnimatePresence>
				{showInstagramFooter && <InstagramFooter onClose={() => setShowInstagramFooter(false)} />}
			</AnimatePresence>
		</section>
	)
}

export default CurrentConversation
