/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLMotionProps, Variants } from "framer-motion"

const variantsStepsManagerDesktop: Variants = {
	hidden: {
		y: "100%",
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
}

const variantsStepsManagerMobile: Variants = {
	hidden: {
		y: "100%",
		x: 0,
		opacity: 0,
	},
	visible: {
		y: 0,
		x: 0,
		opacity: 1,
	},
}

const variantsOverlay: Variants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
}

const transition: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
}

export const overlayMotionProps = {
	variants: variantsOverlay,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
}

export const stepsManagerMotionPropsDesktop = {
	variants: variantsStepsManagerDesktop,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
	style: { originX: 0.5, originY: 1, },
}

export const stepsManagerMotionPropsMobile = {
	variants: variantsStepsManagerMobile,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
}