import { VariationUtils } from "./ColorVariationUtils"

const generateSaturationVariations = (palette: string[]): string[][] => {
	const saturationValues = [0.2, 0.6, 1, 0.4, 0.8,]

	return saturationValues.map(value => {
		return palette.map(color => VariationUtils.adjustSaturation(color, value))
	})
}

const generateHueVariations = (palette: string[]): string[][] => {
	const hueValues = [40, 100, 160, 220, 280,]

	return hueValues.map(value => {
		return palette.map(color => VariationUtils.rotateHue(color, value))
	})
}

const generateLightVariations = (palette: string[]): string[][] => {

	const lightValues = [0.75, 0.55, 0.40, -0.4, -0.55,]

	return lightValues.map(value => {
		return palette.map(color => VariationUtils.adjustLightness(color, value, true, true))
	})
}

const generateGradientVariations = (palette: string[]): string[][] => {

	return palette.map(color => VariationUtils.generateGradient(6, color))

}

export const ModifyColorPalettesUtils = {
	generateSaturationVariations,
	generateHueVariations,
	generateLightVariations,
	generateGradientVariations,
}
