import { Variants, HTMLMotionProps } from "framer-motion"

const actionsListVariants: Variants = {
	hidden: {
		opacity: 0,
		scale: 0.9,
		top: 36,
		left: 0,
	},
	visible: {
		opacity: 1,
		scale: 1,
		top: 36,
		left: 0,
	},
}

const actionsListTransition: HTMLMotionProps<"div">["transition"] = {
	type: "tween",
	duration: 0.15,
	ease: [0.4, 0.14, 0.3, 1,],
}


export const actionListMotionProps = {
	variants: actionsListVariants,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: actionsListTransition,
}
