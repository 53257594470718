import React from "react"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"
import Styles from "./Header.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import SideBarIcon from "deblank-common/src/assets/images/icon-library/sidebar.svg"
import NewChatIcon from "deblank-common/src/assets/images/icon-library/new-chat.svg"
import PinIcon from "deblank-common/src/assets/images/icon-library/pin.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"

const Header = () => {
	const toggleSidebar = settersUiTemporary.useToggleIsOpenSidebar()
	const togglePinned = settersUiTemporary.useToggleIsOpenPinned()

	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const newChat = settersConversations.useNewChat()
	const isNewChat = useRecoilValue(selectorsConversations.isNewChat)

	return (
		<header className={Styles.header} id="assistant-header">
			<div className={Styles.mobile_actions}>
				<ButtonIcon
					type="button"
					onClick={toggleSidebar}
					iconSVGComponent={SideBarIcon}
					customStyles={{
						variant: "ghost",
					}}
				/>
				<ButtonIcon
					type="button"
					onClick={newChat}
					iconSVGComponent={NewChatIcon}
					customStyles={{
						variant: "ghost",
						background: true,
					}}
					disabled={someMessageIsLoading || isNewChat}
				/>
			</div>
			<div className={Styles.logo_container}>
				<Logo className={Styles.logo} />
			</div>
			<div className={Styles.mobile_actions}>
				<ButtonIcon
					type="button"
					onClick={togglePinned}
					iconSVGComponent={PinIcon}
					customStyles={{
						variant: "ghost",
					}}
				/>
			</div>
		</header>
	)
}

export default Header
