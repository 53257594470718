import React from "react"
import Styles from "./WidgetToolbarThumbColors.module.scss"
import { CommonWidgetColorItemProps } from "../WidgetColorsTypes"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"


const WidgetToolbarThumbColors = (props: CommonWidgetColorItemProps) => {

	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()

	const handleClick = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.palette}>
				{props.widgetItemDetails.palette.map((color, index) => (
					<span key={`${index}-${color}`}
						className={Styles.color_item}
						style={{
							"--background_color": color,
						} as React.CSSProperties}
					/>
				))}
			</div>
			<div className={Styles.overlay}>
				<ButtonIcon type="button"
					iconSVGComponent={DeleteIcon}
					onClick={handleClick}
					customStyles={{
						variant: "secondary",
					}}
				/>
			</div>
		</div>
	)
}

export default WidgetToolbarThumbColors
