import { Variants } from "framer-motion"

const shadesModalVariants: Variants = {
	open: {
		transition: { staggerChildren: 0.01, delayChildren: 0.1, },
	},
	closed: {
		transition: { staggerChildren: 0.01, staggerDirection: -1, },
	},
}

const shadesModalItemVariants: Variants = {
	open: {
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100, },
		},
	},
	closed: {
		opacity: 0,
		transition: {
			y: { stiffness: 1000, },
		},
	},
}

export const shadesModalMotionProps = {
	initial: "closed",
	animate: "open",
	exit: "closed",
	variants: shadesModalVariants,
}

export const shadesModalItemMotionProps = {
	variants: shadesModalItemVariants,
}