import chroma from "chroma-js"

const getBackgroundColor = (params: {
	colorPalette: string[],
	backgroundLuminance: number,
	random?: boolean,
}) => {
	if (params.random) {
		const randomIndex = Math.floor(Math.random() * params.colorPalette.length)
		return params.colorPalette[randomIndex]
	} else {
		let bestColor = params.colorPalette[0]
		let lowestSaturationDifference = Infinity

		params.colorPalette.forEach(color => {
			const [_, saturation,] = chroma(color).hsl()

			const saturationDifference = Math.abs(saturation - params.backgroundLuminance)
			const luminanceDifference = Math.abs(chroma(color).luminance() - params.backgroundLuminance)

			if (luminanceDifference + saturationDifference < lowestSaturationDifference) {
				bestColor = color
				lowestSaturationDifference = luminanceDifference + saturationDifference
			}
		})

		return bestColor
	}

}

const selectSequentialColorsFromPalettes = (params: {
	colorsPalette: string[],
	numOfGenerations: number,
	backgroundColor: string,
}): string[] => {
	const { colorsPalette, numOfGenerations, } = params
	if (colorsPalette.length === 0 || numOfGenerations <= 0) {
		return []
	}

	const shades: string[] = []
	const shadesPerColor = Math.ceil(numOfGenerations / colorsPalette.length)

	for (const color of colorsPalette) {
		for (let i = 1; i <= shadesPerColor; i++) {
			const modifiedShade = modifyColorBasedOnLuminance(color, params.backgroundColor, i * 0.8)
			shades.push(modifiedShade)

			if (shades.length === numOfGenerations) {
				return colorsPalette.concat(shades)
			}
		}
	}

	return colorsPalette.concat(shades)
}

const getColorPalette = (params: {
	colors: string[],
	backgroundColor: string,
	minColors: number,
}) => {
	return params.colors.length < params.minColors
		? selectSequentialColorsFromPalettes({
			colorsPalette: params.colors,
			backgroundColor: params.backgroundColor,
			numOfGenerations: params.minColors - params.colors.length,
		})
		: params.colors
}

const modifyColorBasedOnLuminance = (color: string, backgroundColor: string, modificationFactor: number): string => {

	return chroma(backgroundColor).luminance() >= 0.6
		? chroma(color).darken(modificationFactor)
			.hex()
		: chroma(color).brighten(modificationFactor)
			.hex()
}


// https://github.com/bgrins/TinyColor/blob/b49018c9f2dbca313d80d7a4dad25e26143cfe01/npm/tinycolor.js#L710
const getReadability = (color1: string, color2: string): number => {
	const luminance1 = chroma(color1).luminance()
	const luminance2 = chroma(color2).luminance()

	return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05)
}

// https://github.com/bgrins/TinyColor/blob/b49018c9f2dbca313d80d7a4dad25e26143cfe01/npm/tinycolor.js#L726
const isReadable = (color1: string, color2: string, lowerContrast?: boolean) => {
	const readability = chroma.contrast(color1, color2)
	const minimumReadableContrast = lowerContrast ? 2 : 3

	if (readability >= minimumReadableContrast) {
		return true
	}

	return false
}

// https://github.com/bgrins/TinyColor/blob/b49018c9f2dbca313d80d7a4dad25e26143cfe01/npm/tinycolor.js#L755C10-L755C10
const getTextColor = (params: {
	backgroundColor: string,
	colorPalette: string[],
	lowerContrast?: boolean,
	random?: boolean,
}): string => {
	if (params.random) {
		const palette = params.colorPalette.filter(color => color !== params.backgroundColor)
		const randomIndex = Math.floor(Math.random() * palette.length)
		return palette[randomIndex]
	} else {
		let bestColor = null
		let bestScore = 0
		let readability

		for (let i = 0; i < params.colorPalette.length; i++) {
			readability = getReadability(params.backgroundColor, params.colorPalette[i])
			if (readability > bestScore) {
				bestScore = readability
				bestColor = params.colorPalette[i]
			}
		}

		if (!!bestColor && isReadable(params.backgroundColor, bestColor, params.lowerContrast)) {
			return bestColor!
		} else {
			return getTextColor({
				backgroundColor: params.backgroundColor,
				colorPalette: ["#ffffff", "#000000",],
				lowerContrast: params.lowerContrast,
			})
		}
	}
}


export const ColorUtils = {
	getBackgroundColor,
	getColorPalette,
	getTextColor,
}
