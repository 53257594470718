/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import { trpc } from "@/providers/TRPCProvider"
import { Mockup, MockupCategory, MockupParams, MockupParamsType } from "./types/MockupTypes"
import { GroupedColorPalette } from "./types/ColorsMockupTypes"
import { colorMockups } from "./mockups/colorMockups/colorMockups"
import { fontsMockups } from "./mockups/fontsMockups/fontsMockups"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { getRandomItemFromArray } from "../arrayUtils"

export type GetMockupParams = {
	category: MockupCategory,
	numberOfMockups: number,
} & (
		{
			type: MockupParamsType.Colors,
			palette: string[],
		} | {
			type: MockupParamsType.Fonts,
			titleFontSlug: string,
			bodyFontSlug: string,
		}
	)


export const useGetMockupParams = <P extends MockupParams>(): {
	getMockupParams: (params: GetMockupParams) => P,
	getMockups: (params: GetMockupParams) => Mockup<P>[],
} => {
	const alphabet = "abcdefghijklmnopqrstuvwxyz"

	const getGroupedColors = trpc.colors.getPaletteDistributionBySvg.useMutation().mutateAsync
	const fontDictionary = useRecoilValue(selectorsFontCache.fontDictionary)

	const getMockupParams = (params: GetMockupParams) => {
		let mockupParams
		switch (params.type) {
			case MockupParamsType.Colors:
				let colorPalette: GroupedColorPalette = {}
				params.palette.forEach((c, i) => {
					colorPalette = {
						...colorPalette,
						[`group${alphabet[i].toUpperCase()}`]: c,
					}
				})
				mockupParams = {
					...colorPalette,
					type: MockupParamsType.Colors,
					getGroupedColors,
				}
				break
			case MockupParamsType.Fonts:
				mockupParams = {
					title: {
						font: fontDictionary[params.titleFontSlug].name,
						weights: fontDictionary[params.titleFontSlug].weights.map(w => w.code),
					},
					body: {
						font: fontDictionary[params.bodyFontSlug].name,
						weights: fontDictionary[params.bodyFontSlug].weights.map(w => w.code),
					},
					type: MockupParamsType.Fonts,
				}
				break
			default:
				throw new Error(`Invalid mockup type: ${params}`)
		}

		return mockupParams as P

	}

	const getMockups = (params: GetMockupParams): Mockup<P>[] => {
		switch (params.type) {
			case MockupParamsType.Colors:
				const selectedColorMockups: Mockup<P>[] = []
				const colorMockupsOfSelectedCategory = colorMockups[params.category] as Mockup<P>[]
				for (let i = 0; i < params.numberOfMockups; i++) {
					const mockup = getRandomItemFromArray(colorMockupsOfSelectedCategory.filter(f => !selectedColorMockups.includes(f)))
					if (mockup) {
						selectedColorMockups.push(mockup)
					}
				}
				return selectedColorMockups
			case MockupParamsType.Fonts:
				const selectedFontsMockups: Mockup<P>[] = []
				const fontsMockupsOfSelectedCategory = fontsMockups[params.category] as Mockup<P>[]
				for (let i = 0; i < params.numberOfMockups; i++) {
					const mockup = getRandomItemFromArray(fontsMockupsOfSelectedCategory.filter(f => !selectedFontsMockups.includes(f)))
					if (mockup) {
						selectedFontsMockups.push(mockup)
					}
				}
				return selectedFontsMockups
			default:
				throw new Error(`Invalid mockup type: ${params}`)
		}
	}




	return {
		getMockupParams,
		getMockups,
	}
}
