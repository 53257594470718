import React from "react"
import {
	ConfigStepType, RelatedActionConfigStep,
	RelatedActionContext, RelatedActionData, SelectValue
} from "../../RelatedActionsConfigs/RelatedActionsTypes"
import * as Sentry from "@sentry/react"
import Select from "./StepComponents/Select/Select"
import { IdRefWidget } from "deblank-api-types"

type Props<T,> = {
	stepIndex: number,
	step: RelatedActionConfigStep<T>,
	relatedActionData: RelatedActionData<T>,
	relatedActionContext: RelatedActionContext<T>,
	isLoading: boolean,
	idRef: IdRefWidget,
	onNextAction: () => void,
	isNextActionDisabled: boolean,
}



const StepSelector = <T,>(props: Props<T>) => {

	const renderSelectedStep = () => {
		switch (props.step.componentType) {
			case ConfigStepType.select:
				return <Select
					key={`${props.stepIndex}-${props.step.title}`}
					relatedActionTitle={props.relatedActionData.label}
					stepConfig={props.step}
					idRef={props.idRef}
					value={props.step.onGetValue(props.relatedActionContext)}
					isLastStep={props.stepIndex === props.relatedActionData.config.steps.length - 1}
					isLoading={props.isLoading}
					onSelect={(value: SelectValue,) => {
						props.step.onSelect(props.relatedActionContext)(value)
					}}
					nextAction={{
						onNextAction: props.onNextAction,
						isDisabled: props.isNextActionDisabled,
					}}
				/>
			default: {
				const errorMessage = `Unknown step component type in StepsManager: ${props.step.componentType}`
				Sentry.captureMessage(errorMessage)
				throw new Error(errorMessage)
			}
		}
	}

	return renderSelectedStep()
}

export default StepSelector
