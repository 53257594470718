import React from "react"
import Styles from "./Button.module.scss"
import Spinner from "../../Spinner/Spinner"
import classNames from "classnames"
import { ButtonCommonProps } from "../ButtonCommonTypes"

type CustomStyles = {
	variant?: "primary" | "secondary" | "ghost" | "tertiary" | "danger" | "suggestion",
	size?: "small" | "large",
	fullWidth?: boolean,
}

type Props = ButtonCommonProps & {
	children: React.ReactNode,
	icon?: {
		SVGComponent: React.FunctionComponent,
		position?: "left" | "right",
	},
	customStyles?: CustomStyles,
	isActive?: boolean,
}

const Button = React.forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLButtonElement>,
) => {

	const buttonStyles = classNames(
		Styles.button,
		{
			[Styles.is_active]: props.isActive,
			[Styles.is_loading]: props.isLoading,
			[Styles.is_loading_without_icon]: props.isLoading && !props.icon,
			[Styles.has_left_icon]: !!props.icon && (props.icon.position === "left" || props.icon?.position === undefined),
			[Styles.has_right_icon]: !!props.icon && props.icon.position === "right",
			[Styles.variant_primary]: (props.customStyles?.variant || "primary") === "primary",
			[Styles.variant_secondary]: props.customStyles?.variant === "secondary",
			[Styles.variant_ghost]: props.customStyles?.variant === "ghost",
			[Styles.variant_danger]: props.customStyles?.variant === "danger",
			[Styles.variant_suggestion]: props.customStyles?.variant === "suggestion",
			[Styles.variant_tertiary]: props.customStyles?.variant === "tertiary",

			[Styles.size_small]: (props.customStyles?.size || "small") === "small",
			[Styles.size_large]: props.customStyles?.size === "large",
			[Styles.full_width]: props.customStyles?.fullWidth,

			[Styles.invert_icon_position]: props.icon?.position === "right",
		}
	)

	return (
		<button type={props.type}
			className={buttonStyles}
			ref={ref}
			disabled={props.disabled || props.isLoading}
			tabIndex={props.accessibility?.tabIndex}
			onClick={props.type === "submit"
				? undefined
				: props.onClick
			}
			onMouseEnter={props.type === "submit"
				? undefined
				: props.onMouseEnter
			}
			onMouseLeave={props.type === "submit"
				? undefined
				: props.onMouseLeave
			}
		>
			{props.icon
				&& <span className={Styles.icon_container}>
					{props.isLoading
						? <Spinner />
						: <props.icon.SVGComponent />
					}
				</span>
			}

			{props.children}

			{!props.icon && props.isLoading
				&& <span className={Styles.loading_container}>
					<Spinner />
				</span>
			}
		</button>
	)
})

Button.displayName = "Button"

export default Button
