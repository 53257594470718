import React, { useContext } from "react"
import Styles from "./Suggestions.module.scss"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import { suggestionsList } from "./utils/suggestionsList"
import Button from "@/components/Buttons/Button/Button"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"

const Suggestions = () => {
	const addNewConversation = settersConversations.useAddNewConversation()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleClickedSuggestion = async (suggestion: string) => {
		await trackConversationEvent({
			eventName: "SubmitInitialRecommendation",
			prompt: suggestion,
		})
		addNewConversation(suggestion)
	}

	return (
		<section className={Styles.container}>
			<div className={Styles.star}><StarIcon /></div>
			<section className={Styles.suggestions_container}>
				{suggestionsList.map((suggestion, index) => (
					<div key={index}>
						<Button
							onClick={() => handleClickedSuggestion(suggestion)}
							type="button"
							customStyles={{
								variant: "suggestion",
							}}
						>
							{suggestion}
						</Button>
					</div>
				))}
			</section>
		</section>
	)
}

export default Suggestions
