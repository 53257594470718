import WidgetBubbleUserMessageImage from "./BubleUserMessage/WidgetBubbleUserMessageImage"
import WidgetConversationImages from "./Conversation/WidgetConversationImages"
import WidgetPinnedImages from "./Pinned/WidgetPinnedImages"
import WidgetRelatedActionImage from "./RelatedAction/WidgetRelatedActionImage"
import WidgetToolbarThumbImages from "./ToolbarThumb/WidgetToolbarThumbImages"
import WidgetImagesMultiSelect from "./RelatedAction/WidgetRelatedActionImageMultiSelect"



export const WidgetImages = {
	BubbleUserMesaage: WidgetBubbleUserMessageImage,
	Conversation: WidgetConversationImages,
	Pinned: WidgetPinnedImages,
	RelatedAction: WidgetRelatedActionImage,
	RelatedActionMultiSelect: WidgetImagesMultiSelect,
	ToolbarThumb: WidgetToolbarThumbImages,
}
