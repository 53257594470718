/* eslint-disable react/no-unescaped-entities */
import React from "react"
import Styles from "./RequestAccessPage.module.scss"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"

const RequestAccessPage = () => {

	return (
		<section className={Styles.container}>
			<header className={Styles.header}>
				<Logo className={Styles.logo} />
			</header>
			<div className={Styles.content}>
				<StarIcon className={Styles.star} />
				<h1 className={Styles.title}>Request access to Alpha version</h1>
				<p className={Styles.message}>
					{"It looks like you don't have access to this feature. To test the alpha version, please reach out to us at "}
					<a
						className={Styles.link}
						href="mailto:hi@deblank.com"
					> hi@deblank.com</a> to request access. Thank you for your interest!
				</p>
			</div>
		</section >
	)
}

export default RequestAccessPage
