import React, { useContext, useMemo, useState } from "react"
import RelatedActionTrigger from "../RelatedActionTrigger/RelatedActionTrigger"
import StepsManager from "../StepsManager/StepsManager"
import { RelatedActions, RelatedActionsId } from "../RelatedActionsConfigs/relatedActions"
import Styles from "./RelatedActionsManager.module.scss"
import RelatedIcon from "deblank-common/src/assets/images/icon-library/related.svg"
import { useRecoilValue } from "recoil"
import { RelatedActionData } from "../RelatedActionsConfigs/RelatedActionsTypes"
import { AnimatePresence, m } from "framer-motion"
import { RelatedActionsType } from "../RelatedActionsConfigs/relatedActions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { IdRefWidget, } from "deblank-api-types"
import { relatedActionsContainerMotionProps } from "./Animations"

type RelatedActionsManagerProps = {
	idRef: IdRefWidget,
	containerWidth: number,
}

const RelatedActionsManager = (props: RelatedActionsManagerProps) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const message = useRecoilValue(selectorsMessages.messageById(props.idRef.messageId))
	const [selectedRelatedActionId, setSelectedRelatedActionId,] = useState<RelatedActionsId | null>(null)

	const cleanUpRelatedState = () => {
		setSelectedRelatedActionId(null)
	}

	const getRelatedActions = useMemo((): RelatedActionsType => {
		let related = {}

		if (message?.relatedActions.length) {
			message.relatedActions.forEach((relatedAction) => {
				related = {
					...related,
					[relatedAction]: RelatedActions[relatedAction],
				}
			})

			trackConversationEvent({
				eventName: "ShowedRelateds",
				relateds: Object.values(related).map((relatedAction) => (relatedAction as RelatedActionData<unknown>).label),
				prompt: message!.userMessage!.message,
			})
		}

		return related
	}, [message,])

	const handleTrigger = (id: RelatedActionsId,) => () => {
		const data = getRelatedActions[id]
		trackConversationEvent({
			eventName: "ClickOnRelated",
			relatedId: id as string,
			relatedLabel: data!.label,
			responseId: message!.id,
		})

		setSelectedRelatedActionId(id)
	}



	const renderRelatedActionsTriggers = () => {

		if (getRelatedActions) {
			return Object.keys(getRelatedActions).map((relatedActionId) => {
				const id = relatedActionId as RelatedActionsId
				const data = getRelatedActions[id]
				return (
					<RelatedActionTrigger
						key={`related-action-trigger-${relatedActionId}`}
						label={data!.label}
						onTrigger={handleTrigger(id)}
						isBeta={data!.config.isBeta}
					/>
				)
			})
		}
	}


	return (
		getRelatedActions && Object.keys(getRelatedActions).length > 0 ? (
			<m.div className={Styles.container} {...relatedActionsContainerMotionProps}>
				<div className={Styles.title_container}>
					<RelatedIcon />
					Related
				</div>
				<m.ul {...relatedActionsContainerMotionProps}
					style={{ transformOrigin: "50% 0px 0px", }} className={Styles.related_action_list}>
					{renderRelatedActionsTriggers()}
				</m.ul>
				<AnimatePresence>
					{selectedRelatedActionId
						&& <StepsManager
							containerWidth={props.containerWidth}
							idRef={props.idRef}
							onCleanUpRelatedState={cleanUpRelatedState}
							// eslint-disable-next-line max-len
							relatedAction={getRelatedActions[selectedRelatedActionId] as RelatedActionData<unknown>} />
					}
				</AnimatePresence>
			</m.div>
		)
			: <></>
	)
}


export default RelatedActionsManager
