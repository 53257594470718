import { atom } from "recoil"
import { AtomFontsCache } from "./Types"

const defaultValue: AtomFontsCache = {
	fonts: {},
	pendingSlugs: [],
}

export const atomFontsCache = atom<AtomFontsCache>({
	key: "FontsCache",
	default: defaultValue,
})
