import { HTMLMotionProps, Variants } from "framer-motion"

const transitionContainer: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
}

const variantsOpacityContainer: Variants = {
	hidden: {
		opacity: 0,
		visibility: "hidden",
	},
	visible: {
		opacity: 1,
		visibility: "visible",
	},
}


export const containerMotionProps = {
	variants: variantsOpacityContainer,
	transition: transitionContainer,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
}


const transitionWidget: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.15,
}

const variantsWidget: Variants = {
	initial: {
		opacity: 0,
		scale: 0.9,
		y: 20,
	},
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
	},
}


export const widgetMotionProps = {
	variants: variantsWidget,
	transition: transitionWidget,
	initial: "initial",
	animate: "visible",
	exit: "hidden",
}
