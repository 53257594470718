import React from "react"
import Styles from "./WidgetToolbarThumbMockups.module.scss"
import { CommonWidgetMockupsItemProps } from "../WidgetMockupsTypes"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { getImage } from "@/utils/ImageHelper"


const WidgetToolbarThumbMockups = (props: CommonWidgetMockupsItemProps) => {
	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()
	const { dataUrl, } = getImage({ id: props.widgetItemDetails.mockupId, })

	const handleClick = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}

	return (
		<figure className={Styles.wrapper}>
			<img src={dataUrl}
				className={Styles.image}
				alt={"Item sended to chat"}
			/>
			<div className={Styles.overlay}>
				<ButtonIcon type="button"
					iconSVGComponent={DeleteIcon}
					onClick={handleClick}
					customStyles={{
						variant: "secondary",
					}}
				/>
			</div>
		</figure>
	)
}

export default WidgetToolbarThumbMockups
