import { UserContext } from "@/providers/UserProvider"
import { useNavigate } from "@tanstack/react-router"
import React, { useContext, useEffect } from "react"
import LoadingView from "../LoadingView/LoadingView"

export function ProtectedRoute({ children, }: { children: React.ReactNode, }) {
	const userContext = useContext(UserContext)
	const navigation = useNavigate()

	useEffect(() => {
		const checkUser = async () => {
			if (userContext.user === null) {
				await navigation({
					to: "/login",
				})
			}
		}

		checkUser()

	}, [userContext.user, navigation,])

	return userContext.user ? <>{children}</> : <LoadingView />

}

export default ProtectedRoute