/* eslint-disable max-statements */
import {
	Application, Text, TextStyle, TextStyleAlign,
	TextStyleFontStyle, TextStyleFontWeight, Assets, Container, Graphics, Color, Sprite, DisplayObject,
} from "pixi.js"
import { Sprite2d } from "pixi-projection"
import { ImageData, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontUtils } from "@/utils/FontUtils"

const loadAsset = async (params: {
	path: string,
}) => {
	const texture = await Assets.load(params.path)
	const sprite = Sprite.from(texture)
	return sprite

}
const loadAssets = async (assets: string[]) => {
	return await Promise.all(assets.map((asset) => loadAsset({ path: asset, })))
}

type ProjectionPoint = {
	x: number,
	y: number,
}

const project = (params: {
	app: Application,
	projectionPoints: {
		topLeft: ProjectionPoint,
		topRight: ProjectionPoint,
		bottomRight: ProjectionPoint,
		bottomLeft: ProjectionPoint,
	},
	container: Container,
}) => {

	const positions = [
		params.projectionPoints.topLeft,
		params.projectionPoints.topRight,
		params.projectionPoints.bottomRight,
		params.projectionPoints.bottomLeft,
	]

	const texture = params.app.renderer.generateTexture(params.container)

	const sprite = new Sprite2d(texture)
	sprite.proj.mapSprite(sprite, positions)

	return sprite
}


const exportImage = async (params: {
	app: Application,
	container: Container,
	width: number,
	height: number,
	imageCosts: {
		completion_cost: number,
		prompt_cost: number,
	} | null,
}): Promise<ImageData & OpenAiCosts> => {
	const original = params.container
	const original_imageData = await params.app.renderer.extract.image(original)

	return {
		original_src: original_imageData.src,
		originalWidth: params.width,
		originalHeight: params.height,
		completion_cost: params.imageCosts?.completion_cost || 0,
		prompt_cost: params.imageCosts?.prompt_cost || 0,
	}
}

const mask = (params: {
	mask: Sprite,
	elements: DisplayObject[],
}) => {
	const maskContainer = new Container()
	maskContainer.addChild(params.mask)
	params.elements.forEach((element) => {
		maskContainer.addChild(element)
	})
	maskContainer.mask = params.mask
	return maskContainer
}


//Choose fallback font weight if the selected one is not available
const getFontWeight = (selectedWeight: number, fontWeights: number[]): TextStyleFontWeight => {
	if (fontWeights.includes(selectedWeight)) {
		return selectedWeight.toString() as TextStyleFontWeight
	} else if (fontWeights.includes(selectedWeight - 100)) {
		return (selectedWeight - 100).toString() as TextStyleFontWeight
	} else if (fontWeights.includes(selectedWeight + 100)) {
		return (selectedWeight + 100).toString() as TextStyleFontWeight
	} else {
		return fontWeights[Math.ceil(fontWeights.length / 2) - 1].toString() as TextStyleFontWeight
	}
}

// eslint-disable-next-line complexity
const createText = async (params: {
	text: string,
	fontFamily: string,
	fontWeights: number[],
	fontSize: number,
	fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
	fontStyle?: TextStyleFontStyle,
	position?: { x: number, y: number, },
	wrapText?: boolean,
	wordWrapWidth?: number,
	breakWords?: boolean,
	color?: string,
	borderColor?: string,
	borderWidth?: number,
	width?: number,
	height?: number,
	align?: TextStyleAlign,
	padding?: number,
	trim?: boolean,
	fitText?: {
		containerWidth?: number,
		containerHeight?: number,
		respectTextMaxSize?: boolean,
	},
}) => {
	await FontUtils.isFontLoaded(params.fontFamily,
		params.fontWeight ? Number(getFontWeight(params.fontWeight, params.fontWeights)) : undefined)

	// eslint-disable-next-line max-len
	const textStyle = new TextStyle({
		fontFamily: params.fontFamily,
		fontWeight: params.fontWeight ? getFontWeight(params.fontWeight, params.fontWeights) : undefined,
		fontSize: params.fontSize,
		wordWrap: params.wrapText,
		wordWrapWidth: params.wordWrapWidth,
		fontStyle: params.fontStyle || "normal",
		breakWords: params.breakWords,
		align: params.align || "left",
		padding: params.padding || 2,
	})
	if (params.trim) {
		textStyle.trim = true
	}
	if (params.color) {
		textStyle.fill = params.color
	} else {
		textStyle.fill = "transparent"
	}

	if (params.borderColor) {
		textStyle.stroke = params.borderColor
		textStyle.strokeThickness = params.borderWidth || 1
	}

	const text = new Text(params.text, textStyle)

	if (params.width) {
		text.width = params.width
	}
	if (params.height) {
		text.height = params.height
	}

	if (params.fitText) {
		if (params.fitText.containerWidth) {
			const scale = (params.fitText.containerWidth) / text.width
			if (params.fitText.respectTextMaxSize) {
				text.scale.set(scale > 1 ? 1 : scale)
			} else {
				text.scale.set(scale)
			}
		} else if (params.fitText.containerHeight) {
			const scale = (params.fitText.containerHeight) / text.height
			if (params.fitText.respectTextMaxSize) {
				text.scale.set(scale > 1 ? 1 : scale)
			} else {
				text.scale.set(scale)
			}
		}

	}

	if (params.position) {
		text.position.set(params.position.x, params.position.y)
	}

	return text
}

const drawRect = (params: {
	color: string,
	rounding?: number,
	width: number,
	height: number,
}) => {
	const rect = new Graphics()
	rect.beginFill(Color.shared.setValue(params.color))
	if (params.rounding) {
		rect.drawRoundedRect(0, 0, params.width, params.height, params.rounding)

	} else {
		rect.drawRect(0, 0, params.width, params.height)
	}
	rect.endFill()
	return rect
}



export const MockupUtils = {
	project,
	exportImage,
	createText,
	loadAssets,
	drawRect,
	mask,
}
