import AlphaAuthenticationPage from "@/pages/AlphaAuthenticationPage/AlphaAuthenticationPage"
import { createFileRoute } from "@tanstack/react-router"


type AlphaSearchParams = {
	token: string,
}

export const Route = createFileRoute("/alpha")({
	component: AlphaAuthenticationPage,
	validateSearch: (search: Record<string, unknown>): AlphaSearchParams => {
		return {
			token: (search.token as string) || "",
		}
	},
})