/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useClickOutside } from "deblank-common/src/hooks/useClickOutside"
import Styles from "./Select.module.scss"
import { AnimatePresence, m } from "framer-motion"
import { actionListMotionProps } from "./Animations"
import ChevronBottom from "deblank-common/src/assets/images/icon-library/chevron-bottom.svg"
import classNames from "classnames"

type Props = {
	options: {
		element: React.ReactElement,
		onClick: () => void,
	}[],
	selectedValue: string,
}

const Select = (props: Props) => {
	const { isOpen, toggleIsOpen, elementRef, setIsOpen, } = useClickOutside()

	const handleWidgetAction = (onClick: () => void,) => {
		onClick()
		setIsOpen(false)
	}

	const largestOptionLenght = Math.max(...props.options.map(option => option.element.props.children.length))

	const buttonClass = classNames({
		[Styles.button_action]: true,
		[Styles.active]: isOpen,
	})
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div className={Styles.wrapper} ref={elementRef} onClick={e => {
			e.preventDefault()
			e.stopPropagation()
		}}
		>
			<button onClick={toggleIsOpen}
				className={buttonClass}
			>
				<span //Set the width of the select to the largest option
					style={{ width: `${largestOptionLenght}ch`, minWidth: "5ch", }}
				>
					{props.selectedValue}
				</span>
				<ChevronBottom />
			</button>

			<AnimatePresence>
				{isOpen
					&& <m.div className={Styles.action_list}
						{...actionListMotionProps}>
						{props.options.map((opt, index) => {
							const Element = opt.element
							return (
								<button
									key={`option-${index}`}
									type="button"
									className={Styles.action_list_item}
									onClick={() => handleWidgetAction(opt.onClick)}
									style={{
										animationDelay: `${(index) * 30}ms`,
									}}
								>
									{Element}
								</button>
							)
						})}
					</m.div>
				}
			</AnimatePresence>
		</div>
	)
}

export default Select
