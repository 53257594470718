import React from "react"
import Styles from "./WidgetToolbarThumbFonts.module.scss"
import { CommonWidgetFontsItemProps } from "../WidgetFontsTypes"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import { FontDetailsByPairing } from "../_helper/FontDetailsByPairing"


const WidgetToolbarThumbFonts = (props: CommonWidgetFontsItemProps) => {

	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.widgetItemDetails.slug,
	})

	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))

	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()

	const handleClick = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}


	return (
		<article className={Styles.wrapper}>
			<p className={Styles.content_text} >
				{fontMainDetails
					&& <span className={Styles.title_text}
						style={fontMainDetails.fontCSSProperties}>
						{fontMainDetails?.metadata.name}
					</span>
				}
				{fontPairingDetails
					&& <span className={Styles.paraing_text}
						style={fontPairingDetails.fontCSSProperties}>
						{fontPairingDetails.metadata.name}
					</span>
				}
			</p>

			<div className={Styles.overlay}>
				<ButtonIcon type="button"
					iconSVGComponent={DeleteIcon}
					onClick={handleClick}
					customStyles={{
						variant: "secondary",
					}}
				/>
			</div>
		</article>
	)
}

export default WidgetToolbarThumbFonts
