import React from "react"
import { CommonWidgetImageProps } from "../WidgetImagesTypes"
import {
	DisplayWidgetType, IdRefType, ImageResponse, SearchResponse
} from "deblank-api-types"
import ImageCard from "./ImageCard/ImageCard"

const WidgetConversationImages = (
	props: {
		activePageIndex: number,
	} & CommonWidgetImageProps
) => {

	const currentPage: (ImageResponse[] | SearchResponse[]) = props.widgetDetails.type === DisplayWidgetType.Images
		? props.widgetDetails.data.pages[props.activePageIndex].results as ImageResponse[]
		: props.widgetDetails.data.results as SearchResponse[]

	return (
		currentPage.map((image, index) => (
			<ImageCard
				key={image.id}
				idRef={{
					type: IdRefType.WidgetWithPaginationItem,
					messageId: props.idRef.messageId,
					widgetId: props.idRef.widgetId,
					widgetPageIndex: props.activePageIndex,
					widgetItemIndex: index,
					widgetItemId: image.id,
				}}
				type={props.widgetDetails.type}
				widgetData={image}
			/>
		))
	)
}

export default WidgetConversationImages
