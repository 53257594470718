
import React, { CSSProperties } from "react"
import CheckIcon from "../../../assets/images/icons/check.svg"
import Styles from "./Checkbox.module.scss"
import classNames from "classnames"

type CheckboxProps = {
	customStyles?: CSSProperties,
	checked: boolean,
	onChange?: () => void,
	disabled?: boolean,
	size: "small" | "medium",
	variant: "dark" | "light",
}

const Checkbox = (props: CheckboxProps) => {


	const checkboxClasses = classNames({
		[Styles.tick]: true,
		[Styles.tick_active]: props.checked,
		[Styles.tick_disabled]: props.disabled,
		[Styles.size_small]: props.size === "small",
		[Styles.size_medium]: props.size === "medium",
		[Styles.variant_dark]: props.variant === "dark",
		[Styles.variant_light]: props.variant === "light",

	})

	return (
		<label className={Styles.container}
			style={props.customStyles}
		>
			<input
				type="checkbox"
				onChange={() => {
					if (!props.disabled && props.onChange) {
						props.onChange()
					}
				}}
			/>
			<div className={checkboxClasses} >
				{props.checked && <CheckIcon />}
			</div>
		</label>
	)
}

export default Checkbox
