import React from "react"
import { useRecoilValue } from "recoil"
import * as Sentry from "@sentry/react"
import {
	DisplayWidgetType, IdRefWidget,
} from "deblank-api-types"
import { WidgetColors } from "@/components/WidgetsByType/Colors/WidgetColors"
import { WidgetFonts } from "@/components/WidgetsByType/Fonts/WidgetFonts"
import { WidgetImages } from "@/components/WidgetsByType/Images/WidgetImages"
import { WidgetMockups } from "@/components/WidgetsByType/Mockups/WidgetMockups"
import WidgetText from "@/components/WidgetsByType/Text/Conversation/WidgetText"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"


type Props = {
	idRef: IdRefWidget,
	activePageIndex: number,
}

const WidgetSelector = (props: Props) => {
	const output = useRecoilValue(selectorsWidgets.outputById(props.idRef.widgetId))
	const type = output.type

	switch (type) {

		case DisplayWidgetType.Text:
			return (
				<WidgetText
					messageId={props.idRef.messageId}
					widgetDetails={output}
				/>
			)
		case DisplayWidgetType.Colors:
			return (
				<WidgetColors.Conversation
					idRef={props.idRef}
					widgetDetails={output}
					activePageIndex={props.activePageIndex}
				/>
			)
		case DisplayWidgetType.ColorsExplanations:
			return (
				<WidgetColors.Explanation
					idRef={props.idRef}
					widgetDetails={output}
				/>
			)
		case DisplayWidgetType.AccessibilityColors:
			return (
				<WidgetColors.Accessibility
					idRef={props.idRef}
					widgetDetails={output}
				/>
			)
		case DisplayWidgetType.Fonts:
			return (
				<WidgetFonts.Conversation
					idRef={props.idRef}
					widgetDetails={output}
					activePageIndex={props.activePageIndex}
				/>
			)
		case DisplayWidgetType.FontsExplanations:
			return (
				<WidgetFonts.Explanation
					idRef={props.idRef}
					widgetDetails={output}
				/>
			)
		case DisplayWidgetType.Images:
		case DisplayWidgetType.Searches:
			return (
				<WidgetImages.Conversation
					idRef={props.idRef}
					widgetDetails={output}
					activePageIndex={props.activePageIndex}
				/>
			)
		case DisplayWidgetType.MockupColors:
		case DisplayWidgetType.MockupFonts: {

			return (
				<WidgetMockups.Conversation
					idRef={props.idRef}
					widgetDetails={output}
					activePageIndex={props.activePageIndex}
				/>
			)
		}

		default: {
			const _exhaustiveCheck: never = type
			const errorMessage = `Unknown widget type in MainConversation: ${_exhaustiveCheck}`
			Sentry.captureMessage(errorMessage)
			throw new Error(errorMessage)
		}
	}
}

export default WidgetSelector
