import React from "react"
import Styles from "./ButtonIcon.module.scss"
import { ButtonCommonProps } from "../ButtonCommonTypes"
import Spinner from "../../Spinner/Spinner"
import { SpinnerSize } from "../../Spinner/Spinner"
import classNames from "classnames"


type CustomStyles = {
	background?: boolean,
	size?: "large" | "small",
	variant?: "primary" | "ghost" | "secondary",
}

type Props = ButtonCommonProps & {
	iconSVGComponent: React.FunctionComponent,
	customStyles?: CustomStyles,
}

const ButtonIcon = React.forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLButtonElement>,
) => {

	const buttonStyles = classNames(
		Styles.button,
		{
			[Styles.large]: props.customStyles?.size === "large",
			[Styles.variant_primary]: (props.customStyles?.variant || "primary") === "primary",
			[Styles.variant_ghost]: props.customStyles?.variant === "ghost",
			[Styles.variant_secondary]: props.customStyles?.variant === "secondary",
			[Styles.with_background]: props.customStyles?.variant === "ghost" && props.customStyles?.background,
		}
	)

	return (
		<button type={props.type}
			className={buttonStyles}
			ref={ref}
			disabled={props.disabled || props.isLoading}
			tabIndex={props.accessibility?.tabIndex}
			onClick={props.type === "submit"
				? undefined
				: props.onClick
			}
			onMouseEnter={props.type === "submit"
				? undefined
				: props.onMouseEnter
			}
			onMouseLeave={props.type === "submit"
				? undefined
				: props.onMouseLeave
			}
		>
			<span className={Styles.icon_container}>
				{
					props.isLoading
						? <Spinner size={SpinnerSize.Small} />
						: <props.iconSVGComponent />
				}
			</span>
		</button>
	)
})

ButtonIcon.displayName = "ButtonIcon"

export default ButtonIcon
