/* eslint-disable max-lines-per-function */
import React, { useContext, useMemo, useRef, useState } from "react"
import Styles from "./ColorsPlayground.module.scss"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import UnionIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import ColorItem from "./ColorItem/ColorItem"
import { DisplayWidgetType, EntryColorsResult, } from "deblank-api-types"
import chroma from "chroma-js"
import { AnimatePresence, Reorder, } from "framer-motion"
import { ColorUtils } from "@/utils/ColorUtils"
import { v4 } from "uuid"
import { produce } from "immer"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import ModalWrapper from "@/components/ModalWrapper/ModalWrapper"
import { useRecoilValue } from "recoil"
import usePresetActions from "@/components/Actions/usePresetActions"
import { ExportHelper } from "@/utils/exportHelper"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"

export type ColorWithId = {
	id: string,
	value: {
		color: string,
		shades: string[],
	},
}

const getPaletteWithShades = (palette: string[]) => {
	return palette.map((color) => {
		return {
			color: color,
			shades: ColorUtils.getColorShades(color),

		}
	})
}

export const ColorsPlayground = () => {

	const ref = useRef<HTMLDivElement>(null)
	const widget = useRecoilValue(selectorsCommon.colorPlaygroundWidget)
	const widgetSelectData = useMemo(() => (widget), [])
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const updateNotification = settersAssistantResponseTools.useDispatchUpdateNotifications()
	const setColorPlaygroundIdRef = settersCommon.useSetColorPlaygroundIdRef()
	const output = useRecoilValue(selectorsWidgets.outputById(widgetSelectData!.idRef.widgetId)) as EntryColorsResult

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: widgetSelectData!.idRef,
		widgetType: DisplayWidgetType.Colors,
	})

	//The colors need to have an ID to work properly with the Drag feature.
	const [selectedPalette, setSelectedPalette,] = useState<{ palette: ColorWithId[], }>({
		palette: getPaletteWithShades(widgetSelectData!.widgetData.palette).map((c) => {
			return { id: v4(), value: c, }
		}),
	})

	if (!widgetSelectData) {
		return null
	}

	const handleOnClose = () => {
		handleUpdatePalette()
		setColorPlaygroundIdRef({ idRef: null, })
	}

	const handleDeleteColor = (colorId: string) => {

		trackConversationEvent({
			eventName: "DeleteColor",
			colorCode: colorId,
		})

		setSelectedPalette(produce(prev => {
			prev.palette = prev.palette.filter((c) => c.id !== colorId)
		}))
	}

	const handleReOrder = (newPalette: ColorWithId[]) => {
		trackConversationEvent({
			eventName: "DragColor",
			generatedColorPalette: newPalette.map(c => c.value.color),
		})
		setSelectedPalette(produce(prev => {
			prev.palette = newPalette
		}))
	}

	const handleChangeColorShade = (colorId: string) => (newColor: string,) => {
		trackConversationEvent({
			eventName: "ChangeColorShade",
			colorCode: selectedPalette.palette.find((c) => c.id === colorId)!.value.color,
			colorShade: newColor,
		})
		setSelectedPalette(produce(prev => {
			const index = prev.palette.findIndex((c) => c.id === colorId)
			prev.palette[index].value.color = newColor
		}))
	}

	const handleChangeColor = (colorId: string) => (newColor: string,) => {
		trackConversationEvent({
			eventName: "ChangeColor",
			colorCode: selectedPalette.palette.find((c) => c.id === colorId)!.value.color,
			newColorColor: newColor,
		})
		setSelectedPalette(produce(prev => {
			const index = prev.palette.findIndex((c) => c.id === colorId)
			const newColorShades = ColorUtils.getColorShades(newColor)
			prev.palette[index].value.color = newColor
			prev.palette[index].value.shades = newColorShades
		}))
	}

	const handleUpdatePalette = () => {

		const newOutput = produce(output, (draft) => {
			const page = draft.data.pages[widgetSelectData.idRef.widgetPageIndex]
			const item = page.results[widgetSelectData.idRef.widgetItemIndex]

			item.palette = selectedPalette.palette.map((c) => c.value.color)
		})

		updateNotification({
			notifications: [{
				outputs: [newOutput,],
			},],
		})
	}

	const handleAddColor = (params: {
		indexPosition: number,
		colorsBetween: string[],
	}) => () => {
		const newColor = chroma.average(params.colorsBetween).hex()
			.toUpperCase()
		const newColorShades = ColorUtils.getColorShades(newColor)

		trackConversationEvent({
			eventName: "AddColor",
			colorCode: newColor,
		})

		setSelectedPalette(produce(prev => {
			const color = {
				id: v4(),
				value: {
					color: newColor,
					shades: newColorShades,
				},
			}
			prev.palette.splice(params.indexPosition, 0, color)
		}))
	}

	const handleOnPin = () => {
		pinPresetAction.onClick()
		handleOnClose()
	}

	const handleExport = () => {
		ExportHelper.fromTemplate(ref)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Colors,
			responseId: widgetSelectData.idRef.messageId,
			outputId: widgetSelectData.idRef.widgetId,
		})
	}

	const handleOnSendToChat = () => {
		sendToChatPresetAction.onClick()
		handleOnClose()
		trackConversationEvent({
			eventName: "SendToChat",
			outputId: widgetSelectData.idRef.widgetId,
			widgetType: DisplayWidgetType.Images,
			responseId: widgetSelectData.idRef.messageId,
		})
	}

	const commonActions = () => {
		return (
			<ul className={Styles.common_actions_container}>
				<li>
					<ButtonIcon
						customStyles={{ variant: "ghost", }}
						type="button"
						onClick={handleOnSendToChat}
						iconSVGComponent={UnionIcon}
					/>
				</li>
				<li>
					<ButtonIcon
						customStyles={{ variant: "ghost", }}
						type="button"
						onClick={handleExport}
						iconSVGComponent={ExportIcon}
					/>
				</li>
				<li>
					<ButtonIcon
						customStyles={{ variant: "ghost", }}
						type="button"
						onClick={handleOnPin}
						iconSVGComponent={pinPresetAction.icon}
					/>
				</li>
			</ul>
		)
	}

	return (
		<ModalWrapper>
			<nav className={Styles.actions_container}>
				<ul className={Styles.action_list}>
					{commonActions()}
					<li className={Styles.close_button_container}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={handleOnClose}
							iconSVGComponent={CrossIcon}
						/>
					</li>
				</ul>
			</nav>

			<Reorder.Group
				ref={ref}
				as="ul"
				axis="x"
				onReorder={handleReOrder}
				className={Styles.color_list}
				values={selectedPalette.palette}
				initial={{ opacity: 0, }}
				animate={{ opacity: 1, }}
			>
				<AnimatePresence initial={false} >

					{selectedPalette.palette.map((color, index) => {

						const itsAddColorLeftAvailable = (index !== 0
							&& selectedPalette.palette.length < 10)
						const itsAddColorRightAvailable = (index !== selectedPalette.palette.length - 1
							&& selectedPalette.palette.length < 10)

						return <ColorItem
							colorValue={color}
							key={color.id}
							id={color.id}
							deleteDisabled={selectedPalette.palette.length === 2}
							onChangeColor={handleChangeColor(color.id)}
							onChangeColorShade={handleChangeColorShade(color.id)}
							onDeleteColor={() => handleDeleteColor(color.id)}
							onAddColorOnTheLeft={itsAddColorLeftAvailable ? handleAddColor({
								indexPosition: index,
								colorsBetween: [selectedPalette.palette[index - 1].value.color, color.value.color,],
							}) : undefined}
							onAddColorOnTheRight={itsAddColorRightAvailable ? handleAddColor({
								indexPosition: index + 1,
								colorsBetween: [color.value.color, selectedPalette.palette[index + 1].value.color,],
							}) : undefined}
						/>
					})}
				</AnimatePresence>
			</Reorder.Group>
		</ModalWrapper>
	)
}
