import { IdRefWidgetWithPaginationItem, Output } from "deblank-api-types"
import { selector, selectorFamily } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"



const pinListInCurrentConversation = selector<IdRefWidgetWithPaginationItem[] | null>({
	key: prefixRecoilKey("pinListInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)

		return conversations[activeConversationIndex]
			&& conversations[activeConversationIndex].pinnedItems.length > 0
			? conversations[activeConversationIndex].pinnedItems
			: null
	},
})

const pinListOutputsInCurrentConversation = selector<Output[] | null>({
	key: prefixRecoilKey("pinListOutputsInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		if (conversations[activeConversationIndex]
			&& conversations[activeConversationIndex].pinnedItems.length > 0
			&& conversations[activeConversationIndex].outputs) {
			return conversations[activeConversationIndex].pinnedItems
				.map(item => conversations[activeConversationIndex].outputs![item.widgetId]) || []
		}
		return []


	},
})

const isOutputPinned = selectorFamily<boolean, { idRef: IdRefWidgetWithPaginationItem, }>({
	key: prefixRecoilKey("isOutputPinned"),
	get: ({ idRef, }) => ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]

		return currentConversation.pinnedItems.some(item => (
			(item.widgetId === idRef.widgetId)
			&& (item.widgetPageIndex === idRef.widgetPageIndex)
			&& (item.widgetItemIndex === idRef.widgetItemIndex)
		))
	},
})

export const selectorsPinned = {
	isOutputPinned,
	pinListInCurrentConversation,
	pinListOutputsInCurrentConversation,
}
