/* eslint-disable react-refresh/only-export-components */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpLink } from "@trpc/client"
import React, { useContext, useEffect, useState } from "react"
import { AppRouter } from "deblank-api-types"
import { createTRPCReact } from "@trpc/react-query"
import { EnvHelperClient } from "../utils/envHelper"
import { useRouter } from "@tanstack/react-router"
import { UserContext } from "./UserProvider"
import LoadingView from "@/components/LoadingView/LoadingView"

export function getBaseUrl() {
	return EnvHelperClient.apiUrl
}

export const trpc = createTRPCReact<AppRouter>()

interface IError {
	data: {
		code: string,
	};
}

export const TRPCProvider = (props: { children: React.ReactNode, }) => {

	const router = useRouter()
	const userContext = useContext(UserContext)

	const [queryClient,] = useState(() => new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				cacheTime: 0,
			},
			mutations: {
				onError: (error) => {
					console.error("error", error)
					if ((error as IError).data.code === "UNAUTHORIZED") {
						router.history.replace("/")
					}
				},
			},
		},
	}))

	const getClient = () => {
		return trpc.createClient({
			links: [
				httpLink({
					url: `${getBaseUrl()}/api/trpc`,
					// You can pass any HTTP headers you wish here
					async headers(opts) {
						const user = userContext.userRef.current
						const userToken = user
							? await user.getIdToken()
							: ""
						return {
							userToken,
						}
					},
				}),
			],
		})
	}

	const [trpcClient, setTrpcClient,] = useState<ReturnType<typeof getClient> | null>(null)

	useEffect(() => {
		setTrpcClient(getClient())
	}, [])

	return (trpcClient
		? <trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				{props.children}
			</QueryClientProvider>
		</trpc.Provider>
		: <LoadingView />
	)
}
