import { Mockup, MockupParams, MockupParamsType } from "@/utils/mockupBuilder/types/MockupTypes"
import { GetMockupParams } from "@/utils/mockupBuilder/useGetMockupParams"
import { DisplayWidgetType, EntryMockupColorsResult, EntryMockupFontsResult } from "deblank-api-types"

export const getMockupGenerationNeedsByType = (
	props: {
		widgetDetails: EntryMockupColorsResult | EntryMockupFontsResult,
		getMockups: (params: GetMockupParams) => Mockup<MockupParams>[],
	}
) => {
	let params: GetMockupParams | undefined
	if (props.widgetDetails.type === DisplayWidgetType.MockupColors) {
		params = {
			type: MockupParamsType.Colors,
			category: props.widgetDetails.metadata.category,
			palette: props.widgetDetails.metadata.palette,
			numberOfMockups: 3,
		}
	} else {
		params = {
			type: MockupParamsType.Fonts,
			category: props.widgetDetails.metadata.category,
			bodyFontSlug: props.widgetDetails.metadata.bodyFontSlug,
			titleFontSlug: props.widgetDetails.metadata.titleFontSlug,
			numberOfMockups: 3,
		}
	}

	const mockups = props.getMockups(params)
	return { mockups, params, }
}
