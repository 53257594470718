/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedMockups.module.scss"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import classNames from "classnames"
import { widgetAction } from "../../../../pages/AssistantPage/components/CurrentConversation/Widgets/WidgetsTypes"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { CommonWidgetMockupsItemProps } from "../WidgetMockupsTypes"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExportHelper } from "@/utils/exportHelper"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"

const WidgetPinnedMockups = (props: CommonWidgetMockupsItemProps) => {

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)
	const setImageModal = settersCommon.useSetImageModalIdRef()
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})
	const imageUrl = props.widgetItemDetails.imageUrl

	const handleExport = () => {
		ExportHelper.fromImage(imageUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Export",
			icon: ExportIcon,
			onClick: handleExport,
		},
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenModal,
		},
	]

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	return (
		<figure className={wrapperClasses}>
			<img className={Styles.image}
				src={imageUrl}
				alt="mockup generated by deblank"
				onClick={handleOpenModal}
			/>
			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinItem",
				}}
			/>
		</figure>
	)
}

export default WidgetPinnedMockups
