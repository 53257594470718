import { IdRefWidgetWithPaginationItem } from "deblank-api-types"

export enum WidgetUiSectionId {
	Conversation = "conversation",
	Pin = "pin",
}

const assignId = (params: {
	idRef: IdRefWidgetWithPaginationItem,
	widgetUiSection: WidgetUiSectionId,
}) => {
	return `${params.widgetUiSection}-${params.idRef.widgetItemId}`
}

const getElementFromDocument = (params: {
	idRef: IdRefWidgetWithPaginationItem,
	widgetUiSection: WidgetUiSectionId,
}) => {
	return document.getElementById(assignId(params))
}

export const ScreenshotIdManager = {
	assignId,
	getElementFromDocument,
}
