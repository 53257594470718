import React from "react"
import FontCard from "./FontCard/FontCard"
import { CommonWidgetFontsProps } from "../WidgetFontsTypes"
import { IdRefType } from "deblank-api-types"


const WidgetConversationFonts = (
	props: {
		activePageIndex: number,
	} & CommonWidgetFontsProps
) => {

	const currentPage = props.widgetDetails.data.pages[props.activePageIndex].results

	return (
		currentPage.map((font, index) => {
			return (
				<FontCard key={font.id}
					metadata={props.widgetDetails.metadata!}
					widgetType={props.widgetDetails.type}
					font={font}
					idRef={{
						type: IdRefType.WidgetWithPaginationItem,
						messageId: props.idRef.messageId,
						widgetId: props.idRef.widgetId,
						widgetPageIndex: props.activePageIndex,
						widgetItemIndex: index,
						widgetItemId: font.id,
					}}
				/>
			)
		})
	)
}

export default WidgetConversationFonts
