import * as Sentry from "@sentry/react"
import { EnvHelperClient } from "./envHelper"

Sentry.init({
	dsn: "https://20ea4fb27ac462613bb29ad983039180@o256449.ingest.us.sentry.io/4507726063075328",

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.01,

	// Setting this option to true will print useful
	// information to the console while you're setting up Sentry.
	debug: false,
	environment: EnvHelperClient.envType,
	beforeSend: (event) => {
		if (!event.tags) {
			event.tags = {}
		}
		event.tags.project = "deblank-assistant"
		return event
	},
})
