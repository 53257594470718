import { SearchRelatedActions } from "deblank-api-types"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, InputSelectValue, OneStepAction,
} from "../RelatedActionsTypes"

type Step1 = InputSelectValue

type RelatedState = OneStepAction<Step1>

export const Image_Search_Action_Config_1
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				const details = data.step1.value || ""

				await context.onAddPendingMessage({
					message: `Search images considering the previous user prompt sent emphasizing the following
extra information: ${details}`,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: InputSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: {
								...prev.step1,
								value: data.value,
							},
						}
					})
				},
		}

		return {
			id: SearchRelatedActions.RefineSearch,
			label: "Refine search",
			config: {
				defaultState: {
					step1: {
						value: "",
					},
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Describe the type of images you want",
						componentType: ConfigStepType.select,
						selectType: SelectType.input,
						placeholder: "Describe the type like: I want coffee mugs to be present",
						required: false,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
