import { ColorsRelatedActions } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"

type Step1 = ColorSelectValue

type RelatedState = OneStepAction<Step1>

export const Color_Related_Action_Config_12
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {

				await context.onAddPendingMessage({
					message: "Search for images with this color palette",
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
		}

		return {
			id: ColorsRelatedActions.SearchForImagesWithColorPalette,
			label: "Search for images with this color palette",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
