import React from "react"
import Styles from "./ColorPicker.module.scss"
import { HexColorInput, HexColorPicker, RgbColor, RgbColorPicker, } from "react-colorful"
import chroma from "chroma-js"
import Select from "../Select/Select"

type Props = {
	colorHex?: string,
	onChange: (colorHex: string) => void,
	positionStyles: React.CSSProperties,
}

const RgbColorInput = (props: { color: RgbColor, onChange: (newColor: RgbColor) => void, }) => {
	return (
		<div className={Styles.input_rgb}>
			<input
				type="number"
				value={props.color.r}
				onChange={(e) => props.onChange({ ...props.color, r: parseInt(e.target.value, 10), })}
			/>
			<input
				type="number"
				value={props.color.g}
				onChange={(e) => props.onChange({ ...props.color, g: parseInt(e.target.value, 10), })}
			/>
			<input
				type="number"
				value={props.color.b}
				onChange={(e) => props.onChange({ ...props.color, b: parseInt(e.target.value, 10), })}
			/>
		</div>
	)
}

export const ColorInputPicker = (props: Props) => {
	const [inputType, setInputType,] = React.useState<"hex" | "rgb">("hex")

	const handleHEXColorChange = (colorHex: string) => {
		if (chroma.valid(colorHex)) {
			const color = colorHex.toUpperCase()
			props.onChange(color)
		} else {
			props.onChange("")
		}
	}

	const handleRGBColorChange = (newColor: RgbColor) => {
		const rgbaColor = chroma(newColor.r, newColor.g, newColor.b)
		if (chroma.valid(rgbaColor)) {
			const hexColor = rgbaColor.hex()
			props.onChange(hexColor.toUpperCase())
		} else {
			props.onChange("")
		}
	}

	const rgbColor: RgbColor = {
		r: chroma(props.colorHex).rgb()[0],
		g: chroma(props.colorHex).rgb()[1],
		b: chroma(props.colorHex).rgb()[2],
	}

	return (
		<div
			className={Styles.color_picker}
			style={props.positionStyles}
		>
			{inputType === "hex"
				? <HexColorPicker
					color={props.colorHex}
					onChange={handleHEXColorChange}
				/>
				: <RgbColorPicker
					color={rgbColor}
					onChange={handleRGBColorChange}
				/>
			}
			<div className={Styles.color_picker_input}>

				<Select
					selectedValue={inputType.toUpperCase()}
					options={[
						{
							element: <div>HEX</div>,
							onClick: () => setInputType("hex"),
						},
						{
							element: <div>RGB</div>,
							onClick: () => setInputType("rgb"),
						},
					]}
				/>
				{inputType === "hex" ? <HexColorInput prefixed
					color={props.colorHex}
					onChange={handleHEXColorChange}
				/>
					: <RgbColorInput
						color={rgbColor}
						onChange={handleRGBColorChange}
					/>}
			</div>
		</div >
	)
}