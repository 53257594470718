/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react"
import {
	RelatedActionContext, RelatedActionData, SelectValue,
} from "../RelatedActionsConfigs/RelatedActionsTypes"
import Styles from "./StepsManager.module.scss"

import { useWindowSize } from "@/hooks/useWindowsSize"
import { m } from "framer-motion"
import {
	overlayMotionProps,
	stepsManagerMotionPropsDesktop, stepsManagerMotionPropsMobile
} from "./Animations"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import TopBar from "./TopBar/TopBar"
import StepSelector from "./StepSelector/StepSelector"
import { useGetMockupParams } from "@/utils/mockupBuilder/useGetMockupParams"
import { GenerateMockupParams, useHandleMockupImages } from "@/utils/mockupBuilder/useHandleMockupImages"
import { MockupParams } from "@/utils/mockupBuilder/types/MockupTypes"
import {
	IdRefWidget,
	IdRefWidgetWithPaginationItem, PendingNotificationCreateWithoutId
} from "deblank-api-types"
import { saveImage } from "@/utils/ImageHelper"
import { useEscKey } from "deblank-common/src/hooks/useEscKey"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { useRecoilValue } from "recoil"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { LayoutSizes } from "../../../AssistantLayout/LayoutSizes"

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

// Check if all properties from value are falsy or if array is empty
const valueIsEmpty = (value: SelectValue) => {
	return !Object.values(value).some(val => {
		if (Array.isArray(val)) {
			return val.length > 0
		}
		return Boolean(val)
	})
}

type Props<T> = {
	relatedAction: RelatedActionData<T>,
	onCleanUpRelatedState: () => void,
	idRef: IdRefWidget,
	containerWidth: number,
}

const StepsManager = <T,>(props: Props<T>) => {
	const relatedActionData = props.relatedAction
	const [relatedActionState, setRelatedActionState,]
		= useState<T>(relatedActionData.config.defaultState)

	const windowsSize = useWindowSize()
	const [isLoading, setIsLoading,] = useState(false)
	const [selectedStepIndex, setSelectedStepIndex,] = useState(0)
	const [autoAdvanceToNextAction, setAutoAdvanceToNextAction,] = useState(false)
	const addPendingMessage = settersAssistantResponseTools.useAddPendingMessageToConversation()
	const addCreateNotification = settersAssistantResponseTools.useDispatchCreateNotifications()

	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isDebugPanelOpen = useRecoilValue(selectorsUITemporary.isDebugPanelOpen)

	const { getMockupParams, getMockups, } = useGetMockupParams()
	const { generateMockupImages, } = useHandleMockupImages()

	usePreventScroll({
		prevent: true,
	})

	useEscKey({
		onPress: () => {
			props.onCleanUpRelatedState()
		},
	})




	// There are instances when a step needs to be auto - selected and advanced to the next step.
	// To maintain a consistent approach, we chose to always advance to the next step when the flag is set.
	// This decision ensures that the state is updated appropriately before moving to the next step.
	useEffect(() => {
		if (autoAdvanceToNextAction) {
			handleNextAction()
			setAutoAdvanceToNextAction(false)
		}
	}, [autoAdvanceToNextAction,])

	const handleNextAction = async () => {
		if (selectedStepIndex === relatedActionData.config.steps.length - 1) {
			await handleProcessAction()
		} else {
			handleNextStep()
		}
	}


	const handleNextStep = () => {
		setSelectedStepIndex(prev => {
			if (prev < relatedActionData.config.steps.length - 1) {
				return prev + 1
			} else {
				return prev
			}
		})

	}

	const handlePrevStep = () => {
		setSelectedStepIndex(prev => {
			if (prev > 0) {
				return prev - 1
			} else {
				return prev
			}
		})

	}

	const handleSaveImages = async (images: {
		dataUrl: string,
		width: number,
		height: number,
		mockupId: string,
	}[]) => {
		setIsLoading(true)
		//Mock Save images
		await sleep(1000)
		const savedImages = images.map((im) => {
			const id = saveImage({
				dataUrl: im.dataUrl,
			})
			return {
				width: im.width,
				height: im.height,
				mockupId: id,
			}
		})

		setIsLoading(false)

		return savedImages
	}

	const handleGenerateMockupImages = async (params: GenerateMockupParams<MockupParams>) => {
		try {
			setIsLoading(true)
			const ret = await generateMockupImages(params)
			setIsLoading(false)
			return ret
		} catch (e) {
			console.error(e)
			setIsLoading(false)
		}
	}

	const handleProcessAction = async () => {
		await relatedActionData.config
			.processAction(getContext())(relatedActionState)
	}


	const handleCloseMobile = () => {
		if (windowsSize.isMobile || windowsSize.isTablet) {
			props.onCleanUpRelatedState()
		}
	}

	const getContext = (): RelatedActionContext<T> => {
		return {
			idRef: props.idRef,

			currentValue: relatedActionState,
			onAddPendingMessage: (params: {
				message: string,
				prompt?: string,
				idRefs?: IdRefWidgetWithPaginationItem[],
				generatedByWidget?: IdRefWidget,

			}) => {
				addPendingMessage({
					userMessage: params.message,
					prompt: params.prompt,
					idRefs: params.idRefs,
					generatedByWidget: params.generatedByWidget,

				})
				props.onCleanUpRelatedState()
			},
			onAddCreateNotifications: (params: {
				notifications: (Omit<PendingNotificationCreateWithoutId, "type"> & { generatedByWidget: IdRefWidget, })[],
			}) => {
				addCreateNotification({ notifications: params.notifications, })
				props.onCleanUpRelatedState()
			},
			onSaveImages: async (images: {
				dataUrl: string,
				width: number,
				height: number,
				mockupId: string,
			}[]) => {
				return await handleSaveImages(images)
			},
			onSetState: (set: (prevState: T) => T) => {
				setRelatedActionState(set)
			},
			mockupBuilder: {
				getMockupParams: getMockupParams,
				getMockups: getMockups,
				getMockupImages: handleGenerateMockupImages,
			},
		}
	}

	const isNextActionDisabled = (relatedActionData.config.steps[selectedStepIndex].required || false)
		&& valueIsEmpty(relatedActionData.config.steps[selectedStepIndex].onGetValue(getContext()))

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === "Enter" && !isNextActionDisabled) {
				setAutoAdvanceToNextAction(true)
			}
		}

		document.addEventListener("keydown", handleKeyDown)

		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [isNextActionDisabled,])


	const getStepManagerLeftPosition = () => {
		const whiteSpace = windowsSize.width - props.containerWidth
			- (isSidebarOpen ? LayoutSizes.sidebar : 0)
			+ (isPinnedListOpen ? LayoutSizes.pinnedbar : 0)
			+ (isDebugPanelOpen ? LayoutSizes.pinnedbar : 0)

		return (windowsSize.width - props.containerWidth) - (whiteSpace / 2)
	}

	return (
		<>
			<m.div className={Styles.overlay} onClick={handleCloseMobile} {...overlayMotionProps} />
			<m.form className={Styles.container} {...windowsSize.isMobile
				? { ...stepsManagerMotionPropsMobile, }
				: { ...stepsManagerMotionPropsDesktop, }}
				style={{ left: getStepManagerLeftPosition(), }}

			>
				<TopBar
					selectedStepIndex={selectedStepIndex}
					relatedActionData={relatedActionData}
					relatedActionContext={getContext()}
					onCleanUpRelatedState={props.onCleanUpRelatedState}
					onNextStep={handleNextStep}
					onPrevStep={handlePrevStep}
				/>

				<StepSelector
					idRef={props.idRef}
					stepIndex={selectedStepIndex}
					step={relatedActionData.config.steps[selectedStepIndex]}
					relatedActionData={relatedActionData}
					isLoading={isLoading}
					relatedActionContext={getContext()}
					onNextAction={() => setAutoAdvanceToNextAction(true)}
					isNextActionDisabled={isNextActionDisabled}
				/>

			</m.form>
		</>
	)
}

export default StepsManager
