import { useState } from "react"

export const useCopyToClipboard = () => {
	const [contentCopied, setContentCopied,] = useState<string | null>(null)

	const copyToClipboard = async (info: {
		text: string,
		id: string,
		callback?: () => void,
	}) => {
		try {
			await navigator.clipboard.writeText(info.text)
			setContentCopied(info.id)
			info.callback && info.callback()

			setTimeout(() => setContentCopied(null), 3000)
		} catch (e) {
			console.error("Error copying to clipboard")
		}
	}

	return {
		copyToClipboard,
		contentCopied,
	}
}
