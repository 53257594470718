import React from "react"
import Styles from "./Spinner.module.scss"
import classNames from "classnames"
import SpinnerIcon from "deblank-common/src/assets/images/icons/spinner.svg"
import DarkSpinnerIcon from "deblank-common/src/assets/images/icons/dark-spinner.svg"

// eslint-disable-next-line react-refresh/only-export-components
export enum SpinnerSize {
	Small = "SMALL",
	Medium = "MEDIUM",
	Large = "LARGE"

}

export type Props = {
	size?: SpinnerSize,
	darkMode?: boolean,
}

const Spinner = (props: Props) => {

	const buttonSizesClasses = classNames({
		[Styles.size_small]: props.size === SpinnerSize.Small,
		[Styles.size_medium]: props.size === SpinnerSize.Medium,
		[Styles.size_large]: props.size === SpinnerSize.Large,
	})

	return (
		<span className={`${Styles.container} ${buttonSizesClasses}`}>
			{props.darkMode ? <DarkSpinnerIcon /> : <SpinnerIcon />}
		</span>
	)
}

export default Spinner
