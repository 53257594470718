// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from "react"
import { createPortal } from "react-dom"

export const PortalRootLayoutAssistantId = "portal-root-layout-assistant"

const Portal = ({ children, }: { children: ReactNode, }) => {
	const portalNode = document.getElementById(PortalRootLayoutAssistantId)!
	return createPortal(children, portalNode)
}

export default Portal
