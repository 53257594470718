import { Color_Related_Action_Config_1 } from "./Color_Related_Action_Config_1"
import { Color_Related_Action_Config_2 } from "./Color_Related_Action_Config_2"
import { Color_Related_Action_Config_3 } from "./Color_Related_Action_Config_3"
import { Color_Related_Action_Config_4 } from "./Color_Related_Action_Config_4"
import { Color_Related_Action_Config_5 } from "./Color_Related_Action_Config_5"
import { Color_Related_Action_Config_6 } from "./Color_Related_Action_Config_6"
import { Color_Related_Action_Config_7 } from "./Color_Related_Action_Config_7"
import { Color_Related_Action_Config_8 } from "./Color_Related_Action_Config_8"
import { Color_Related_Action_Config_9 } from "./Color_Related_Action_Config_9"
import { Color_Related_Action_Config_10 } from "./Color_Related_Action_Config_10"
import { Color_Related_Action_Config_11 } from "./Color_Related_Action_Config_11"
import { Color_Related_Action_Config_12 } from "./Color_Related_Action_Config_12"

export const ColorRelatedActions = {
	[Color_Related_Action_Config_1.id]: Color_Related_Action_Config_1,
	[Color_Related_Action_Config_3.id]: Color_Related_Action_Config_3,
	[Color_Related_Action_Config_2.id]: Color_Related_Action_Config_2,
	[Color_Related_Action_Config_4.id]: Color_Related_Action_Config_4,
	[Color_Related_Action_Config_5.id]: Color_Related_Action_Config_5,
	[Color_Related_Action_Config_6.id]: Color_Related_Action_Config_6,
	[Color_Related_Action_Config_7.id]: Color_Related_Action_Config_7,
	[Color_Related_Action_Config_8.id]: Color_Related_Action_Config_8,
	[Color_Related_Action_Config_9.id]: Color_Related_Action_Config_9,
	[Color_Related_Action_Config_10.id]: Color_Related_Action_Config_10,
	[Color_Related_Action_Config_11.id]: Color_Related_Action_Config_11,
	[Color_Related_Action_Config_12.id]: Color_Related_Action_Config_12,
}
