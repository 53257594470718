import React, { useEffect, useState } from "react"
import Styles from "./WidgetRelatedActionImage.module.scss"
import { useRecoilValue } from "recoil"
import classNames from "classnames"
import { ImageSelectValue } from "../WidgetImagesTypes"
import { DisplayWidgetType, IdRefWidget } from "deblank-api-types"
import { selectorsRelatedActions } from "@/recoil/ConversationsRecord/Selectors/RelatedActions"

type Props = {
	value: ImageSelectValue[],
	onSelect: (value: ImageSelectValue[]) => void,
	onNextAction: () => void,
	idRef: IdRefWidget,
};

const WidgetRelatedActionImageMultiSelect = (props: Props) => {
	const widgetItems = useRecoilValue(selectorsRelatedActions.widgetItemsForRelatedActions(props.idRef))
	const [selectedImages, setSelectedImages,] = useState<ImageSelectValue[]>(props.value)

	// If there is only one option, select it automatically
	useEffect(() => {
		if (widgetItems && widgetItems.length === 1) {
			const widget = widgetItems[0].type === DisplayWidgetType.Images && widgetItems[0]
			if (widget) {
				const newSelection = [{
					idRef: widget.idRef,
					widgetItemDetails: widget.widgetData,
					widgetType: widget.type,
				},]
				setSelectedImages(newSelection)
				props.onSelect(newSelection)
				props.onNextAction()
			}
		}
	}, [widgetItems,])

	const handleSelect = (
		idRef: ImageSelectValue["idRef"],
		widgetItemDetails: ImageSelectValue["widgetItemDetails"],
		widgetType: ImageSelectValue["widgetType"],
	) => (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()

		const isAlreadySelected = selectedImages.some(
			(image) => image.idRef.widgetItemId === idRef.widgetItemId
		)

		let newSelection
		if (isAlreadySelected) {
			newSelection = selectedImages.filter(
				(image) => image.idRef.widgetItemId !== idRef.widgetItemId
			)
		} else {
			newSelection = [
				...selectedImages,
				{ idRef, widgetItemDetails, widgetType, },
			]
		}

		setSelectedImages(newSelection)
		props.onSelect(newSelection)
	}

	return (
		<>
			{widgetItems
				&& widgetItems.map((widget) => {
					if (widget.type !== DisplayWidgetType.Images) {
						return null
					}

					const isSelected = selectedImages.some(
						(image) => image.idRef.widgetItemId === widget.idRef.widgetItemId
					)

					const wrapperClasses = classNames({
						[Styles.image_button]: true,
						[Styles.is_selected]: isSelected,
					})

					return (
						<li key={widget.idRef.widgetItemId} className={Styles.list_item}>
							<button
								onClick={handleSelect(
									widget.idRef,
									widget.widgetData,
									widget.type
								)}
								className={wrapperClasses}
							>
								<img
									className={Styles.image}
									src={widget.widgetData.imageUrl}
									alt=""
								/>
							</button>
						</li>
					)
				})}
		</>
	)
}

export default WidgetRelatedActionImageMultiSelect
