import { DateTime, Duration } from "luxon"



const itsFromDaysAgo = (dateISOString: string, daysAgo: number): boolean => {
	const luxonDate = DateTime.fromISO(dateISOString)
	const yesterdayDate = DateTime.local().minus(Duration.fromObject({ day: daysAgo, }))

	return luxonDate.toISODate() === yesterdayDate.toISODate()
}

export const DateUtils = {
	itsFromDaysAgo,
}