import React, { useEffect, useRef } from "react"
import Styles from "./DebugPanel.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import HistoryIcon from "deblank-common/src/assets/images/icon-library/history.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { useRecoilValue } from "recoil"
import { DebugEntry } from "deblank-api-types"
import DebugButton from "./DebugButton/DebugButton"
import { selectorsAssistantResponseTools } from "@/recoil/ConversationsRecord/Selectors/AssistantResponseTools"

const DebugPanel = () => {
	const panelRef = useRef<HTMLDivElement>(null)
	const togglePanel = settersUiTemporary.useToggleIsOpenDebugPanel()
	const debugContent = useRecoilValue(selectorsAssistantResponseTools.debugInCurrentConversation)

	useEffect(() => {
		if (panelRef.current) {
			panelRef.current.scrollTop = panelRef.current.scrollHeight
		}
	}, [debugContent,])

	return (
		<div className={Styles.container} ref={panelRef}>
			<div className={Styles.top_actions}>
				<ButtonIcon
					type="button"
					onClick={togglePanel}
					iconSVGComponent={HistoryIcon}
					customStyles={{
						variant: "ghost",
					}}
				/>
			</div>

			<div>
				{debugContent && debugContent.length > 0 ? (
					<ul className={Styles.debug_list}>
						{debugContent.map((content: DebugEntry, index: number) => (
							<li
								key={index}
								className={Styles.debug_list_item}
							>
								<DebugButton content={content} />
							</li>
						))}
					</ul>
				) : (
					<p className={Styles.validation_message}>
						<i>Debug entries will appear here.</i>
					</p>
				)}
			</div>
		</div>
	)
}

export default DebugPanel
