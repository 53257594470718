/* eslint-disable max-len */
const mockupWidth = 1417
const mockupHeight = 1744

const simpleDesignSvg = `
<svg width="${mockupWidth}" height="${mockupHeight}" viewBox="0 0 ${mockupWidth} ${mockupHeight}" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_674_48)">
<rect width="${mockupWidth}" height="${mockupHeight}" id="backgroundColor"/>
<circle cx="700" cy="872" r="449" id="color_a"/>
<rect x="544.582" y="340" width="621" height="1115" transform="rotate(15 544.582 340)" id="color_b"/>
<rect x="641.164" y="859.222" width="275.217" height="502.915" transform="rotate(15 641.164 859.222)" id="color_a"/>
</g>
<defs>
<clipPath id="clip0_674_48">
<rect width="${mockupWidth}" height="${mockupHeight}" fill="white"/>
</clipPath>
</defs>
</svg>
`

const backgroundSVG = (params: { background: string, circle: string, }) => `
<svg width="${mockupWidth}" height="${mockupHeight}" viewBox="0 0 ${mockupWidth} ${mockupHeight}" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="${mockupWidth}" height="${mockupHeight}" fill="${params.background}"/>
<circle cx="709" cy="942" r="449" fill="${params.circle}"/>
</svg>
`

const designSVG = (params: { background: string, text: string, }) => `
<svg width="1215" height="1692" viewBox="0 0 1215 1692" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1215" height="1692" fill="${params.background}"/>
<path d="M744.938 546.812C744.938 567.784 720.973 584.477 692.214 584.477C663.456 584.477 639.491 567.784 639.491 546.812C639.491 526.267 663.456 509.575 692.214 509.575C720.973 509.575 744.938 526.267 744.938 546.812Z" fill="${params.text}"/>
<path d="M746.74 722.704C746.74 766.789 723.973 797.606 679.638 815.154L644.289 770.213C668.255 761.225 678.44 747.101 678.44 725.7C678.44 706.012 667.655 693.599 650.281 693.599C602.351 693.599 643.69 810.018 529.257 810.018C475.935 810.018 433.996 776.633 433.996 724.416C433.996 679.047 463.952 651.654 498.102 638.814L533.451 683.755C511.882 690.603 501.697 706.012 501.697 723.132C501.697 738.112 511.882 749.241 527.46 749.241C575.39 749.241 537.046 632.822 648.483 632.822C708.396 632.822 746.74 674.339 746.74 722.704Z" fill="${params.text}"/>
<path d="M746.138 950.826C746.138 1016.74 682.031 1066.39 587.369 1066.39C501.694 1066.39 430.997 1021.88 430.997 956.39C430.997 889.192 500.495 850.243 581.378 850.243L606.541 850.243L606.541 1007.75C646.083 1003.47 672.445 981.643 672.445 951.254C672.445 928.141 656.868 908.881 629.308 899.037L662.26 852.383C715.582 870.788 746.138 905.885 746.138 950.826ZM559.21 1005.18L559.21 910.593C528.654 913.161 505.288 931.137 505.288 956.818C505.288 983.355 529.254 1000.9 559.21 1005.18Z" fill="${params.text}"/>
<path d="M397.447 1145.48C397.447 1166.02 374.081 1183.14 344.723 1183.14C315.965 1183.14 292 1166.02 292 1145.48C292 1124.93 315.965 1108.24 344.723 1108.24C374.081 1108.24 397.447 1124.93 397.447 1145.48ZM441.782 1175.87L441.782 1115.09L738.35 1115.09L738.35 1175.87L441.782 1175.87Z" fill="${params.text}"/>
<path d="M738.348 1351.66L738.348 1462.08L335.135 1462.08L335.135 1350.8C335.135 1278.89 377.074 1235.66 445.974 1235.66C492.107 1235.66 523.261 1253.21 537.64 1280.61C549.024 1251.93 574.786 1232.67 625.712 1232.67C704.797 1232.67 738.348 1282.75 738.348 1351.66ZM407.031 1399.59L502.891 1399.59L502.891 1347.8C502.891 1313.56 486.115 1298.15 453.163 1298.15C423.207 1298.15 407.031 1315.27 407.031 1347.38L407.031 1399.59ZM574.786 1399.59L666.453 1399.59L666.453 1348.23C666.453 1317.84 656.867 1295.16 619.721 1295.16C585.571 1295.16 574.786 1312.71 574.786 1347.8L574.786 1399.59Z" fill="${params.text}"/>
<line x1="811.656" y1="504" x2="811.656" y2="1477" stroke="${params.text}" stroke-width="8" stroke-linecap="round"/>
<path d="M914.341 519.997C914.341 529.859 903.796 536.775 890.928 536.775C878.238 536.775 867.604 528.832 867.604 518.422C867.604 507.808 877.612 500.001 890.928 500.001L913.179 500.001L913.179 509.246L907.996 509.246C911.928 511.643 914.341 515.341 914.341 519.997ZM902.456 518.422C902.456 513.423 897.272 509.725 891.017 509.725C884.761 509.725 879.578 513.423 879.578 518.422C879.578 523.421 884.761 527.051 891.017 527.051C897.272 527.051 902.456 523.421 902.456 518.422Z" fill="${params.text}"/>
<path d="M914.432 561.623C914.432 572.033 903.887 579.977 891.108 579.977C877.346 579.977 867.694 572.718 867.694 562.171C867.694 558.747 869.303 555.46 871.894 552.995L848.57 552.926L848.57 543.202L891.108 543.202C904.512 543.202 914.432 551.009 914.432 561.623ZM902.457 561.623C902.457 556.624 897.363 552.926 891.108 552.926C884.852 552.926 879.669 556.624 879.669 561.623C879.669 566.623 884.852 570.252 891.108 570.252C897.363 570.252 902.457 566.623 902.457 561.623Z" fill="${params.text}"/>
<path d="M914.341 603.155C914.341 613.564 903.796 621.508 890.928 621.508C878.148 621.508 867.604 613.495 867.604 603.155C867.604 592.677 878.238 584.733 890.928 584.733C903.796 584.733 914.341 592.746 914.341 603.155ZM902.456 603.155C902.456 598.156 897.272 594.458 891.017 594.458C884.761 594.458 879.578 598.156 879.578 603.155C879.578 608.154 884.761 611.783 891.017 611.783C897.272 611.783 902.456 608.154 902.456 603.155Z" fill="${params.text}"/>
<path d="M914.432 640.884C914.432 647.937 911.036 652.868 904.423 655.676L899.151 648.485C902.726 647.047 904.245 644.787 904.245 641.363C904.245 638.213 902.636 636.227 900.045 636.227C892.896 636.227 899.062 654.854 881.993 654.854C874.04 654.854 867.784 649.512 867.784 641.158C867.784 633.899 872.252 629.516 877.346 627.461L882.619 634.652C879.402 635.748 877.882 638.213 877.882 640.952C877.882 643.349 879.402 645.13 881.725 645.13C888.874 645.13 883.155 626.503 899.777 626.503C908.713 626.503 914.432 633.145 914.432 640.884Z" fill="${params.text}"/>
</svg>
`

export const Mockup_SVG = {
	simpleDesignSvg,
	backgroundSVG,
	designSVG,
}
