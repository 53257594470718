import React from "react"
import Styles from "./ConversationHistory.module.scss"
import { DateUtils } from "@/utils/dateUtils"
import HistoryItem from "./HistoryItem/HistoryItem"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"

export type ChatHistory = {
	index: number,
	creationDate: string,
	name: string,
}

const getHistoryGroupedByDate = (history: ChatHistory[]) => {

	const sortedHistory = [...history,].sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())

	const todayItems = sortedHistory.filter(h => DateUtils.itsFromDaysAgo(h.creationDate, 0))
	const yesterdayItems = sortedHistory.filter(h => DateUtils.itsFromDaysAgo(h.creationDate, 1))
	const prev7DaysItems = sortedHistory.filter(h => DateUtils.itsFromDaysAgo(h.creationDate, 7))
	const prev30DaysItems = sortedHistory.filter(h => DateUtils.itsFromDaysAgo(h.creationDate, 30))

	return [
		...(todayItems && todayItems.length > 0 ? [{ label: "Today", items: todayItems, },] : []),
		...(yesterdayItems && yesterdayItems.length > 0 ? [{ label: "Yesterday", items: yesterdayItems, },] : []),
		...(prev7DaysItems && prev7DaysItems.length > 0 ? [{ label: "Previous 7 days", items: prev7DaysItems, },] : []),
		...(prev30DaysItems && prev30DaysItems.length > 0 ? [{ label: "Previous 30 days", items: prev30DaysItems, },] : []),
	]
}

const ConversationHistory = () => {

	const conversationsHistory = useRecoilValue(selectorsConversations.historyConversations)
	const currentConversationIndex = useRecoilValue(selectorsConversations.currentConversationIndex)
	const isNewChat = useRecoilValue(selectorsConversations.isNewChat)

	return (
		<div className={Styles.list}>
			{conversationsHistory.length > 0
				? getHistoryGroupedByDate(conversationsHistory).map((group) => {
					return (
						<div key={`${group.label}-items`} className={Styles.history_group}>
							<p className={Styles.label}>{group.label}</p>
							<ul className={Styles.history_items} >
								{group.items.map((h: ChatHistory, i: number) => {

									return (
										<HistoryItem
											key={`history-item-${h.name}-${i}`}
											data={h}
											selected={(h.index === currentConversationIndex) && !isNewChat}
										/>
									)
								})}
							</ul>
							<hr className={Styles.divider} />
						</div>
					)
				})
				: <p className={Styles.empty_message}>
					Start exploring to watch your creative journey unfold here.
				</p>}
		</div>
	)
}

export default ConversationHistory
