import { useRecoilCallback, useRecoilValue } from "recoil"
import { atomUITemporary } from "./Atom"
import { produce } from "immer"
import { UserPrompt } from "./Types"
import { useContext } from "react"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { selectorsUITemporary } from "./Selectors"


const useToggleIsOpenSidebar = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)

	return useRecoilCallback(({ set, }) => () => {
		trackConversationEvent({
			eventName: "ClickOnPanel",
			panelName: "history",
			command: isSidebarOpen ? "collapse" : "expand",
		})
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.sidebarIsOpen = !draft.sidebarIsOpen
		}))
	})
}

const useToggleIsOpenPinned = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)

	return useRecoilCallback(({ set, }) => () => {
		trackConversationEvent({
			eventName: "ClickOnPanel",
			panelName: "pinned",
			command: isPinnedListOpen ? "collapse" : "expand",
		})
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.pinnedIsOpen = !draft.pinnedIsOpen
		}))
	})
}

const useSetIsOpenPinned = () => {
	return useRecoilCallback(({ set, }) => (params: {
		isOpen: boolean,
	}) => {
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.pinnedIsOpen = params.isOpen
		}))
	})
}

const useToggleIsOpenDebugPanel = () => {
	return useRecoilCallback(({ set, }) => () => {
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.debugPanelIsOpen = !draft.debugPanelIsOpen
		}))
	})
}

const useSetOutputIdSelected = () => {
	return useRecoilCallback(({ set, }) => (params: {
		outputIdSelected: UserPrompt["outputIdSelected"],
	}) => {
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.userPrompt.outputIdSelected = params.outputIdSelected
		}))
	})
}


export const settersUiTemporary = {
	useToggleIsOpenSidebar,
	useToggleIsOpenPinned,
	useSetIsOpenPinned,
	useToggleIsOpenDebugPanel,
	useSetOutputIdSelected,
}
