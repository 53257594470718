import { Color_Mockup_Packaging_1 } from "./Color_Mockup_Packaging_1/Color_Mockup_Packaging_1"
import { Color_Mockup_Packaging_2 } from "./Color_Mockup_Packaging_2/Color_Mockup_Packaging_2"
import { Color_Mockup_Packaging_3 } from "./Color_Mockup_Packaging_3/Color_Mockup_Packaging_3"
import { Color_Mockup_Packaging_4 } from "./Color_Mockup_Packaging_4/Color_Mockup_Packaging_4"

export const colorPackagingMockups = [
	Color_Mockup_Packaging_1,
	Color_Mockup_Packaging_2,
	Color_Mockup_Packaging_3,
	Color_Mockup_Packaging_4,
]