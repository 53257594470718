/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Shadow from "./images/shadow.png"
import Lights from "./images/lights.png"
import Mask from "./images/mask.png"
import Design from "./images/design.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/design/y5UsL35vcLmufMsTJ93EOe/Assistant-Mockups?node-id=136-424&t=CdCBkj8n2MEefEuL-0

const mockupId = FontsPackagingMockupID.fonts_packaging_mockup_3
const mockupWidth = 2076
const mockupHeight = 1557

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { app, } = props
	const ret = await props.semaphore.add(async () => {

		const [
			shadowSprite,
			lightsSprite,
			maskSprite,
			designSprite,
		] = await MockupUtils.loadAssets([
			Shadow, Lights, Mask,
			Design,
		])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight
		const mockupShape = MockupUtils.drawRect({
			color: "#C6DCA1",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(mockupShape)


		// Product container
		const productContainer = new PIXI.Container()
		const productContainerWidth = 682.23
		const productContainerHeight = 1167.41
		productContainer.width = productContainerWidth
		productContainer.height = productContainerHeight
		productContainer.addChild(designSprite)

		const verticalText = await MockupUtils.createText({
			text: "Bies",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 500,
			color: "#FFCEDD",
			fontSize: 260,
			padding: 100,
			trim: true,
			fitText: {
				// containerWidth: 450,
				containerHeight: 180,
			},
		})

		verticalText.angle = -90
		verticalText.position.set(80, productContainerHeight - 120)
		productContainer.addChild(verticalText)

		const productDesignProjection = MockupUtils.project({
			app: app,
			container: productContainer,
			projectionPoints: {
				topLeft: { x: 773, y: 316, },
				topRight: { x: 1299, y: 316, },
				bottomRight: { x: 1299, y: 1276, },
				bottomLeft: { x: 773, y: 1276, },
			},
		})

		const maskContainer = new PIXI.Container()
		maskContainer.addChild(productDesignProjection)
		maskContainer.addChild(maskSprite)
		maskContainer.mask = maskSprite
		mockupContainer.addChild(maskContainer)

		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(lightsSprite)
		mockupContainer.addChild(shadowSprite)

		const imageData = MockupUtils.exportImage({
			app: props.app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!

}

export const Fonts_Mockup_Packaging_3: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
