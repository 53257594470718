/* eslint-disable max-lines-per-function */
import * as Sentry from "@sentry/react"
import { Debug, DisplayElementsResults, DisplayWidgetType, RelatedActions, } from "deblank-api-types"
import { settersFontCache } from "@/recoil/FontCache/Setters"
import { EnvHelperClient } from "@/utils/envHelper"
import { DefaultErrorData, DefaultErrorShape, } from "@trpc/server/dist/error/formatter"
import { Maybe, } from "@trpc/server"
import { PendingMessage } from "@/recoil/ConversationsRecord/Types"
import { useTrackPendingMessages } from "./ConversationRecordHelper"
import { settersRelatedActions } from "@/recoil/ConversationsRecord/Setters/RelatedActions"
import { settersMessages } from "@/recoil/ConversationsRecord/Setters/Messages"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"

export type ConversationState = "off" | "init" | "processing" | "retry" | "loopDetected" | "completed"

const useConversationRecordHandlers = (props: {
	loopRetryCount: number,
	pendingMessage: PendingMessage | null,
	conversationState: ConversationState,
	setConversationState: (state: ConversationState) => void,
	setInternalStateInCurrentConversation: (state: string) => void,
}) => {
	const updateMessageState = settersMessages.useUpdateMessageStateInCurrentConversation()
	const addMessage = settersMessages.useAddMessageToCurrentConversation()
	const addRelatedActions = settersRelatedActions.useAddRelatedActionsToMessage()
	const updateMessage = settersMessages.useUpdateMessageToCurrentConversation()
	const addSlugsToPendingSlugs = settersFontCache.useAddSlugsToPendingSlugs()
	const updateDebugHistory = settersAssistantResponseTools.useUpdateDebugHistory()
	const updateOutputsIdCounts = settersMessages.useUpdateOutputsIdCounts()
	const clearPendingNotifications = settersConversations.useClearPendingNotificationsInActiveConversation()
	const { onTrackEvents, } = useTrackPendingMessages()



	const handleOnErrorLoop = (params: {
		message: string,
		data: Maybe<DefaultErrorData>,
		shape: Maybe<DefaultErrorShape>,
	}) => {
		updateMessageState({
			messageId: props.pendingMessage!.messageId,
			state: "error",
		})
		const errorMessage = `Error in processing conversation: ${params.message}`
		Sentry.captureMessage(errorMessage)
		console.error(errorMessage, params.data)
	}

	const handleOnSuccessLoopResult = async (params: {
		relatedActions: RelatedActions[],
		results: DisplayElementsResults,
		editResults: DisplayElementsResults,
		isCompleted: boolean,
		debug: Debug,
		assistantState: string,
		outputsIdCounts: Record<DisplayWidgetType, number>,
	}) => {

		if (params.relatedActions.length) {
			addRelatedActions({
				messageId: props.pendingMessage!.messageId,
				relatedActions: params.relatedActions,
			})
		}

		if (params.editResults) {
			updateMessage({
				messageId: props.pendingMessage!.messageId,
				results: {
					type: params.editResults.type,
					message: params.editResults.message,
					output: params.editResults.output,
				},
			})
		}

		if (params.results) {
			addMessage({
				messageId: props.pendingMessage!.messageId,
				results: {
					type: params.results.type,
					message: params.results.message,
					output: params.results.output,
				},
			})

			if (params.results.output.type === DisplayWidgetType.Fonts) {
				const fontsToLoad = params.results.output.data.pages.flatMap((page) =>
					page.results.map((result) => result.slug)
				)

				if (params.results.output.metadata?.baseFont) {
					fontsToLoad.push(params.results.output.metadata.baseFont)
				}

				addSlugsToPendingSlugs(fontsToLoad)
			}
		}

		if (params.debug.entries.length > 0) {
			updateDebugHistory({
				debugHistory: params.debug.entries,
			})
		}

		// Update the current outputs counts in the conversation
		updateOutputsIdCounts(params.outputsIdCounts)

		// Reset Pending Notifications
		clearPendingNotifications()

		props.setInternalStateInCurrentConversation(params.assistantState)

		if (props.loopRetryCount >= EnvHelperClient.assistantBackendRetryLimit) {
			props.setConversationState("loopDetected")
		} else if (params.isCompleted) {
			await onTrackEvents()
			props.setConversationState("completed")
		} else if (props.conversationState === "processing") {
			props.setConversationState("retry")
		} else {
			props.setConversationState("processing")
		}
	}

	return {
		handleOnErrorLoop,
		handleOnSuccessLoopResult,
	}
}

export default useConversationRecordHandlers
