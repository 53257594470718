/* eslint-disable max-len */

// #FC9340 - A
// #CA72A4 - B
// #D4D3D3 - C
// #2E975E - D
// #F7D243 - E

const simpleDesignSvg = `<svg width="3000" height="2000" viewBox="0 0 3000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="3000" height="2000" fill="white"/>
<path d="M0 729L3000 1319.26V2000H0V729Z" id="group_a"/>
<rect width="966.5" height="1345.5" transform="translate(1530 218)" id="group_b"/>
<circle cx="1625.32" cy="598.517" r="32.5703" id="group_c"/>
<rect x="1625" y="218" width="710" height="273" id="group_c"/>
<rect width="966.5" height="1345.5" transform="translate(489 206)" id="group_d"/>
<circle cx="584.322" cy="586.517" r="32.5703" id="group_e"/>
<rect x="584" y="206" width="691" height="266" id="group_e"/>
</svg>
`

// #FC9340 - A
// #CA72A4 - B
// #D4D3D3 - C
// #2E975E - D
// #F7D243 - E

const design = (params: {
	backgroundColor: string,
	colorB: string,
	colorC: string,
	colorD: string,
	colorE: string,
}) => `<svg width="3000" height="2000" viewBox="0 0 3000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="3000" height="2000" fill="white"/>
<path d="M0 729L3000 1319.26V2000H0V729Z" fill="${params.backgroundColor}"/>
<g clip-path="url(#clip0_2071_687)">
<rect width="966.5" height="1345.5" transform="translate(489 346)" fill="${params.colorD}"/>
<circle cx="1487.69" cy="872.854" r="272.432" fill="${params.colorE}"/>
<path d="M842.932 1075.96L812.91 1111.32V1104.48L837.104 1075.96H842.932ZM813.723 1075.96V1121.74H808.844V1075.96H813.723ZM822.94 1098.66L826.193 1094.89L843.203 1121.74H837.51L822.94 1098.66Z" fill="${params.colorE}"/>
<path d="M765.418 1121.74H760.539V1075.96H765.486L793.34 1115.73H792.188V1075.96H796.999V1121.74H792.188L764.266 1081.97H765.418V1121.74Z" fill="${params.colorE}"/>
<path d="M716.861 1121.74H711.914L729.738 1075.96H735.769L753.796 1121.74H748.713L743.969 1109.6H721.605L716.861 1121.74ZM732.11 1082.42L723.096 1105.57H742.411L733.329 1082.42C733.239 1082.08 733.126 1081.74 732.991 1081.4C732.855 1081.06 732.765 1080.78 732.719 1080.56C732.674 1080.78 732.584 1081.08 732.448 1081.46C732.358 1081.8 732.245 1082.12 732.11 1082.42Z" fill="${params.colorE}"/>
<path d="M685.926 1075.96V1121.74H681.047V1075.96H685.926ZM681.86 1121.74V1117.52H708.223V1121.74H681.86Z" fill="${params.colorE}"/>
<path d="M643.301 1075.96V1121.74H638.422V1075.96H643.301ZM657.194 1100.83H642.081V1096.81H656.72C659.792 1096.81 662.141 1096.06 663.768 1094.57C665.439 1093.03 666.275 1090.9 666.275 1088.17C666.275 1085.62 665.394 1083.63 663.632 1082.23C661.87 1080.78 659.363 1080.05 656.11 1080.05H640.184V1075.96H656.178C660.876 1075.96 664.558 1077.03 667.224 1079.16C669.935 1081.25 671.29 1084.12 671.29 1087.79C671.29 1090.69 670.545 1093.1 669.054 1095.02C667.608 1096.89 665.417 1098.26 662.48 1099.11V1098.34C665.733 1099.11 668.173 1100.45 669.799 1102.37C671.426 1104.25 672.239 1106.7 672.239 1109.72C672.239 1112.15 671.629 1114.28 670.409 1116.12C669.189 1117.91 667.45 1119.29 665.191 1120.27C662.932 1121.25 660.266 1121.74 657.194 1121.74H640.184V1117.59H657.194C660.357 1117.59 662.819 1116.84 664.581 1115.35C666.388 1113.86 667.292 1111.79 667.292 1109.15C667.292 1106.55 666.388 1104.52 664.581 1103.07C662.819 1101.58 660.357 1100.83 657.194 1100.83Z" fill="${params.colorE}"/>
<path d="M628.519 1121.74H599.445V1075.96H628.451V1080.18H601.275L604.325 1077.69V1096.68H626.214V1100.83H604.325V1120.02L601.275 1117.52H628.519V1121.74Z" fill="${params.colorE}"/>
<path d="M566.368 1121.74H551.594V1075.96H566.232C571.111 1075.96 575.381 1076.92 579.041 1078.84C582.7 1080.76 585.546 1083.44 587.58 1086.9C589.613 1090.31 590.629 1094.29 590.629 1098.85C590.629 1103.41 589.613 1107.42 587.58 1110.87C585.592 1114.28 582.768 1116.95 579.108 1118.87C575.494 1120.79 571.247 1121.74 566.368 1121.74ZM556.473 1078.14V1119.57L554.237 1117.52H566.164C570.14 1117.52 573.574 1116.76 576.465 1115.22C579.357 1113.69 581.593 1111.53 583.175 1108.76C584.801 1105.95 585.614 1102.65 585.614 1098.85C585.614 1095.06 584.801 1091.75 583.175 1088.94C581.593 1086.13 579.334 1083.98 576.398 1082.48C573.506 1080.95 570.05 1080.18 566.029 1080.18H554.237L556.473 1078.14Z" fill="${params.colorE}"/>
<path d="M842.932 989.469L812.91 1024.83V1017.99L837.104 989.469H842.932ZM813.723 989.469V1035.25H808.844V989.469H813.723ZM822.94 1012.17L826.193 1008.4L843.203 1035.25H837.51L822.94 1012.17Z" fill="${params.colorE}"/>
<path d="M1313.87 803.295L1349.23 833.317L1342.39 833.317L1313.87 809.123L1313.87 803.295ZM1313.87 832.503L1359.65 832.503L1359.65 837.383L1313.87 837.383L1313.87 832.503ZM1336.57 823.287L1332.79 820.034L1359.65 803.023L1359.65 808.716L1336.57 823.287Z" fill="${params.colorD}"/>
<path d="M765.418 1035.25H760.539V989.469H765.486L793.34 1029.24H792.188V989.469H796.999V1035.25H792.188L764.266 995.479H765.418V1035.25Z" fill="${params.colorE}"/>
<path d="M1359.65 880.816L1359.65 885.695L1313.87 885.695L1313.87 880.748L1353.64 852.895L1353.64 854.047L1313.87 854.047L1313.87 849.235L1359.65 849.235L1359.65 854.047L1319.88 881.968L1319.88 880.816L1359.65 880.816Z" fill="${params.colorD}"/>
<path d="M716.861 1035.25H711.914L729.738 989.469H735.769L753.796 1035.25H748.713L743.969 1023.1H721.605L716.861 1035.25ZM732.11 995.927L723.096 1019.07H742.411L733.329 995.927C733.239 995.586 733.126 995.245 732.991 994.904C732.855 994.563 732.765 994.286 732.719 994.073C732.674 994.286 732.584 994.584 732.448 994.968C732.358 995.309 732.245 995.629 732.11 995.927Z" fill="${params.colorE}"/>
<path d="M1359.65 929.381L1359.65 934.328L1313.87 916.505L1313.87 910.473L1359.65 892.446L1359.65 897.529L1347.5 902.273L1347.5 924.637L1359.65 929.381ZM1320.33 914.133L1343.47 923.146L1343.47 903.832L1320.33 912.913C1319.98 913.003 1319.64 913.116 1319.3 913.252C1318.96 913.387 1318.68 913.478 1318.47 913.523C1318.68 913.568 1318.98 913.658 1319.37 913.794C1319.71 913.884 1320.03 913.997 1320.33 914.133Z" fill="${params.colorD}"/>
<path d="M685.926 989.469V1035.25H681.047V989.469H685.926ZM681.86 1035.25V1031.03H708.223V1035.25H681.86Z" fill="${params.colorE}"/>
<path d="M1313.87 960.3L1359.65 960.3L1359.65 965.18L1313.87 965.18L1313.87 960.3ZM1359.65 964.366L1355.43 964.366L1355.43 938.004L1359.65 938.004L1359.65 964.366Z" fill="${params.colorD}"/>
<path d="M643.301 989.469V1035.25H638.422V989.469H643.301ZM657.194 1014.34H642.081V1010.31H656.72C659.792 1010.31 662.141 1009.57 663.768 1008.08C665.439 1006.54 666.275 1004.41 666.275 1001.68C666.275 999.124 665.394 997.142 663.632 995.735C661.87 994.286 659.363 993.561 656.11 993.561H640.184V989.469H656.178C660.876 989.469 664.558 990.534 667.224 992.666C669.935 994.755 671.29 997.632 671.29 1001.3C671.29 1004.2 670.545 1006.61 669.054 1008.52C667.608 1010.4 665.417 1011.76 662.48 1012.62V1011.85C665.733 1012.62 668.173 1013.96 669.799 1015.88C671.426 1017.75 672.239 1020.2 672.239 1023.23C672.239 1025.66 671.629 1027.79 670.409 1029.63C669.189 1031.42 667.45 1032.8 665.191 1033.78C662.932 1034.76 660.266 1035.25 657.194 1035.25H640.184V1031.1H657.194C660.357 1031.1 662.819 1030.35 664.581 1028.86C666.388 1027.37 667.292 1025.3 667.292 1022.66C667.292 1020.05 666.388 1018.03 664.581 1016.58C662.819 1015.09 660.357 1014.34 657.194 1014.34Z" fill="${params.colorE}"/>
<path d="M1313.87 1002.92L1359.65 1002.92L1359.65 1007.8L1313.87 1007.8L1313.87 1002.92ZM1338.74 989.025L1338.74 1004.14L1334.71 1004.14L1334.71 989.499C1334.71 986.427 1333.97 984.077 1332.47 982.451C1330.94 980.779 1328.81 979.943 1326.08 979.943C1323.52 979.943 1321.54 980.824 1320.13 982.586C1318.68 984.348 1317.96 986.856 1317.96 990.109L1317.96 1006.03L1313.87 1006.03L1313.87 990.041C1313.87 985.342 1314.93 981.66 1317.06 978.995C1319.15 976.284 1322.03 974.928 1325.7 974.928C1328.6 974.928 1331 975.674 1332.92 977.165C1334.8 978.611 1336.16 980.802 1337.01 983.739L1336.25 983.739C1337.01 980.486 1338.36 978.046 1340.28 976.419C1342.15 974.793 1344.6 973.98 1347.63 973.98C1350.06 973.98 1352.19 974.59 1354.02 975.809C1355.81 977.029 1357.2 978.769 1358.18 981.028C1359.16 983.287 1359.65 985.952 1359.65 989.025L1359.65 1006.03L1355.49 1006.03L1355.49 989.025C1355.49 985.862 1354.75 983.4 1353.26 981.638C1351.76 979.83 1349.7 978.927 1347.05 978.927C1344.45 978.927 1342.43 979.83 1340.98 981.638C1339.49 983.4 1338.74 985.862 1338.74 989.025Z" fill="${params.colorD}"/>
<path d="M628.519 1035.25H599.445V989.469H628.451V993.689H601.275L604.325 991.195V1010.19H626.214V1014.34H604.325V1033.53L601.275 1031.03H628.519V1035.25Z" fill="${params.colorE}"/>
<path d="M1359.65 1017.71L1359.65 1046.78L1313.87 1046.78L1313.87 1017.78L1318.09 1017.78L1318.09 1044.95L1315.59 1041.9L1334.58 1041.9L1334.58 1020.01L1338.74 1020.01L1338.74 1041.9L1357.92 1041.9L1355.43 1044.95L1355.43 1017.71L1359.65 1017.71Z" fill="${params.colorD}"/>
<path d="M566.368 1035.25H551.594V989.469H566.232C571.111 989.469 575.381 990.428 579.041 992.346C582.7 994.264 585.546 996.95 587.58 1000.4C589.613 1003.81 590.629 1007.8 590.629 1012.36C590.629 1016.92 589.613 1020.93 587.58 1024.38C585.592 1027.79 582.768 1030.46 579.108 1032.37C575.494 1034.29 571.247 1035.25 566.368 1035.25ZM556.473 991.643V1033.08L554.237 1031.03H566.164C570.14 1031.03 573.574 1030.26 576.465 1028.73C579.357 1027.2 581.593 1025.04 583.175 1022.27C584.801 1019.46 585.614 1016.15 585.614 1012.36C585.614 1008.57 584.801 1005.26 583.175 1002.45C581.593 999.636 579.334 997.483 576.398 995.991C573.506 994.456 570.05 993.689 566.029 993.689H554.237L556.473 991.643Z" fill="${params.colorE}"/>
<path d="M1359.65 1079.85L1359.65 1094.62L1313.87 1094.62L1313.87 1079.99C1313.87 1075.11 1314.83 1070.84 1316.74 1067.18C1318.66 1063.52 1321.35 1060.67 1324.8 1058.64C1328.21 1056.61 1332.2 1055.59 1336.76 1055.59C1341.32 1055.59 1345.33 1056.61 1348.78 1058.64C1352.19 1060.63 1354.85 1063.45 1356.77 1067.11C1358.69 1070.72 1359.65 1074.97 1359.65 1079.85ZM1316.04 1089.75L1357.48 1089.75L1355.43 1091.98L1355.43 1080.05C1355.43 1076.08 1354.66 1072.64 1353.13 1069.75C1351.59 1066.86 1349.44 1064.63 1346.67 1063.04C1343.86 1061.42 1340.55 1060.6 1336.76 1060.6C1332.96 1060.6 1329.66 1061.42 1326.85 1063.04C1324.03 1064.63 1321.88 1066.88 1320.39 1069.82C1318.85 1072.71 1318.09 1076.17 1318.09 1080.19L1318.09 1091.98L1316.04 1089.75Z" fill="${params.colorD}"/>
<path d="M1276.42 284.25L1096.34 493.217V465.46L1251.96 284.25H1276.42ZM1098.03 284.25V565.78H1077.79V284.25H1098.03ZM1158.76 415.895L1172.68 401.224L1278.53 565.78H1254.91L1158.76 415.895Z" fill="${params.colorE}"/>
<path d="M836.923 284.25V565.78H816.68V284.25H836.923ZM930.967 433.342H833.127V415.895H928.015C948.82 415.895 964.846 410.74 976.092 400.431C987.619 390.121 993.383 375.714 993.383 357.21C993.383 339.763 987.197 326.149 974.827 316.368C962.737 306.587 945.587 301.697 923.376 301.697H816.68V284.25H922.111C950.507 284.25 972.859 290.594 989.165 303.283C1005.75 315.972 1014.05 333.419 1014.05 355.624C1014.05 373.864 1009.41 388.799 1000.13 400.431C991.133 412.062 977.357 420.653 958.801 426.205V421.446C979.606 426.469 995.07 434.928 1005.19 446.824C1015.59 458.719 1020.79 474.052 1020.79 492.82C1020.79 507.359 1017 520.18 1009.41 531.283C1001.82 542.121 991.133 550.58 977.357 556.66C963.862 562.74 947.977 565.78 929.702 565.78H816.68V548.333H930.967C952.616 548.333 969.485 543.178 981.574 532.869C993.945 522.559 1000.13 508.417 1000.13 490.441C1000.13 472.73 993.945 458.852 981.574 448.806C969.485 438.497 952.616 433.342 930.967 433.342Z" fill="${params.colorE}"/>
<path d="M608.603 565.78H524.68V284.25H608.603C638.967 284.25 665.395 290.198 687.887 302.093C710.661 313.725 728.232 330.114 740.603 351.262C753.255 372.146 759.581 396.73 759.581 425.015C759.581 453.036 753.255 477.62 740.603 498.768C728.232 519.916 710.661 536.438 687.887 548.333C665.395 559.965 638.967 565.78 608.603 565.78ZM544.923 293.767V556.264L535.645 548.333H608.603C635.031 548.333 657.945 543.311 677.344 533.265C696.744 522.956 711.785 508.549 722.469 490.045C733.434 471.54 738.916 449.864 738.916 425.015C738.916 399.902 733.574 378.093 722.891 359.589C712.207 341.085 697.165 326.81 677.766 316.765C658.367 306.72 635.312 301.697 608.603 301.697H535.645L544.923 293.767Z" fill="${params.colorE}"/>
<circle cx="569.764" cy="668.404" r="27.5965" stroke="${params.colorE}" stroke-width="3.36543"/>
<circle cx="569.599" cy="732.177" r="16.3223" stroke="${params.colorE}" stroke-width="3.36543"/>
<path d="M586.763 784.853C586.763 794.433 579.071 802.185 569.599 802.185C560.127 802.185 552.435 794.433 552.435 784.853C552.435 775.273 560.127 767.521 569.599 767.521C579.071 767.521 586.763 775.273 586.763 784.853Z" fill="${params.colorE}" stroke="${params.colorE}" stroke-width="1.68272"/>
<path d="M1454.28 1333.76L1639.64 1654.82H1268.91L1454.28 1333.76Z" fill="${params.colorE}"/>
<path d="M806.317 1782.7L988.768 1466.69L1171.22 1782.7H806.317Z" stroke="${params.colorE}" stroke-width="3.36543"/>
<path d="M988.322 1367L1353.22 1367L1170.77 1683.02L988.322 1367Z" stroke="${params.colorE}" stroke-width="3.36543"/>
<path d="M557.876 1520.81L922.778 1520.81L740.327 1836.82L557.876 1520.81Z" fill="${params.colorE}" stroke="${params.colorE}" stroke-width="3.36543"/>
<path d="M977.86 1397.76L1292.54 1397.76L1135.2 1670.28L977.86 1397.76Z" fill="${params.colorE}" stroke="${params.colorE}" stroke-width="3.36543"/>
<path d="M1259.61 1295.44C1258.48 1295.44 1257.49 1295.05 1256.63 1294.29C1255.77 1293.52 1255.34 1292.6 1255.34 1291.54C1255.34 1290.43 1255.77 1289.49 1256.63 1288.72C1257.49 1287.91 1258.48 1287.51 1259.61 1287.51C1260.79 1287.51 1261.78 1287.91 1262.6 1288.72C1263.45 1289.49 1263.88 1290.43 1263.88 1291.54C1263.88 1292.6 1263.45 1293.52 1262.6 1294.29C1261.78 1295.05 1260.79 1295.44 1259.61 1295.44Z" fill="${params.colorE}"/>
<path d="M1215.86 1260.84C1215.86 1258.28 1216.54 1256.07 1217.89 1254.19C1219.25 1252.27 1221.15 1250.78 1223.59 1249.72C1226.03 1248.65 1228.87 1248.12 1232.12 1248.12C1235.06 1248.12 1237.61 1248.61 1239.78 1249.59C1242 1250.57 1243.76 1251.98 1245.07 1253.81C1246.38 1255.64 1247.15 1257.84 1247.37 1260.39H1242.43C1242.15 1257.84 1241.12 1255.85 1239.31 1254.45C1237.5 1253 1235.08 1252.27 1232.06 1252.27C1228.67 1252.27 1225.94 1253.04 1223.86 1254.58C1221.78 1256.11 1220.74 1258.18 1220.74 1260.78C1220.74 1262.78 1221.37 1264.44 1222.64 1265.77C1223.9 1267.04 1225.8 1267.98 1228.33 1268.58L1236.67 1270.5C1240.46 1271.35 1243.33 1272.8 1245.27 1274.85C1247.21 1276.85 1248.19 1279.41 1248.19 1282.52C1248.19 1285.12 1247.51 1287.4 1246.15 1289.36C1244.8 1291.32 1242.88 1292.83 1240.39 1293.9C1237.91 1294.97 1234.99 1295.5 1231.65 1295.5C1228.53 1295.5 1225.75 1295.01 1223.31 1294.03C1220.92 1293.05 1219.02 1291.64 1217.62 1289.81C1216.27 1287.98 1215.48 1285.82 1215.25 1283.35H1220.26C1220.4 1285.78 1221.51 1287.72 1223.59 1289.17C1225.66 1290.58 1228.35 1291.28 1231.65 1291.28C1235.22 1291.28 1238.04 1290.51 1240.12 1288.98C1242.25 1287.44 1243.31 1285.37 1243.31 1282.77C1243.31 1280.69 1242.67 1279 1241.41 1277.72C1240.19 1276.4 1238.27 1275.44 1235.65 1274.85L1227.45 1272.93C1223.65 1272.07 1220.76 1270.65 1218.77 1268.64C1216.83 1266.6 1215.86 1264 1215.86 1260.84Z" fill="${params.colorE}"/>
<path d="M1180.31 1294.73H1175.43V1248.95H1193.52C1198.4 1248.95 1202.24 1250.14 1205.05 1252.53C1207.89 1254.87 1209.31 1258.09 1209.31 1262.18C1209.31 1265.46 1208.43 1268.21 1206.67 1270.43C1204.95 1272.65 1202.54 1274.16 1199.42 1274.97L1209.79 1294.73H1204.37L1194.47 1275.74H1180.31V1294.73ZM1180.31 1253.1V1271.58H1193.66C1197 1271.58 1199.6 1270.75 1201.45 1269.09C1203.35 1267.42 1204.3 1265.17 1204.3 1262.31C1204.3 1259.33 1203.33 1257.04 1201.39 1255.47C1199.44 1253.89 1196.82 1253.1 1193.52 1253.1H1180.31Z" fill="${params.colorE}"/>
<path d="M1166.58 1271.84C1166.58 1276.49 1165.58 1280.58 1163.6 1284.12C1161.65 1287.66 1158.94 1290.45 1155.46 1292.49C1151.99 1294.5 1147.99 1295.5 1143.47 1295.5C1138.95 1295.5 1134.93 1294.5 1131.41 1292.49C1127.93 1290.45 1125.22 1287.66 1123.27 1284.12C1121.33 1280.58 1120.36 1276.49 1120.36 1271.84C1120.36 1267.19 1121.33 1263.1 1123.27 1259.56C1125.26 1255.98 1127.99 1253.19 1131.47 1251.19C1134.95 1249.14 1138.95 1248.12 1143.47 1248.12C1148.03 1248.12 1152.03 1249.14 1155.46 1251.19C1158.94 1253.19 1161.65 1255.98 1163.6 1259.56C1165.58 1263.1 1166.58 1267.19 1166.58 1271.84ZM1161.56 1271.84C1161.56 1268 1160.8 1264.66 1159.26 1261.8C1157.72 1258.9 1155.6 1256.64 1152.89 1255.02C1150.22 1253.4 1147.08 1252.59 1143.47 1252.59C1139.9 1252.59 1136.76 1253.4 1134.05 1255.02C1131.34 1256.64 1129.21 1258.9 1127.68 1261.8C1126.19 1264.66 1125.44 1268 1125.44 1271.84C1125.44 1275.63 1126.19 1278.98 1127.68 1281.88C1129.21 1284.78 1131.34 1287.04 1134.05 1288.66C1136.76 1290.28 1139.9 1291.09 1143.47 1291.09C1147.08 1291.09 1150.22 1290.28 1152.89 1288.66C1155.6 1287.04 1157.72 1284.78 1159.26 1281.88C1160.8 1278.98 1161.56 1275.63 1161.56 1271.84Z" fill="${params.colorE}"/>
<path d="M1103.43 1250.99V1294.73H1098.55V1250.99H1103.43ZM1083.64 1253.17V1248.95H1118.41V1253.17H1083.64Z" fill="${params.colorE}"/>
<path d="M1049.49 1294.73H1044.54L1062.36 1248.95H1068.39L1086.42 1294.73H1081.34L1076.59 1282.58H1054.23L1049.49 1294.73ZM1064.73 1255.4L1055.72 1278.55H1075.04L1065.95 1255.4C1065.86 1255.06 1065.75 1254.72 1065.62 1254.38C1065.48 1254.04 1065.39 1253.76 1065.34 1253.55C1065.3 1253.76 1065.21 1254.06 1065.07 1254.44C1064.98 1254.79 1064.87 1255.11 1064.73 1255.4Z" fill="${params.colorE}"/>
<path d="M1039.57 1294.73H1010.5V1248.95H1039.51V1253.17H1012.33L1015.38 1250.67V1269.66H1037.27V1273.82H1015.38V1293L1012.33 1290.51H1039.57V1294.73Z" fill="${params.colorE}"/>
<path d="M972.036 1294.73H967.156V1248.95H985.251C990.13 1248.95 993.971 1250.14 996.772 1252.53C999.618 1254.87 1001.04 1258.09 1001.04 1262.18C1001.04 1265.46 1000.16 1268.21 998.398 1270.43C996.681 1272.65 994.264 1274.16 991.147 1274.97L1001.52 1294.73H996.094L986.2 1275.74H972.036V1294.73ZM972.036 1253.1V1271.58H985.386C988.73 1271.58 991.328 1270.75 993.18 1269.09C995.077 1267.42 996.026 1265.17 996.026 1262.31C996.026 1259.33 995.055 1257.04 993.112 1255.47C991.169 1253.89 988.549 1253.1 985.251 1253.1H972.036Z" fill="${params.colorE}"/>
<path d="M937.568 1295.43C933.05 1295.43 929.074 1294.45 925.64 1292.49C922.252 1290.49 919.586 1287.72 917.643 1284.18C915.746 1280.64 914.797 1276.53 914.797 1271.84C914.797 1267.15 915.768 1263.04 917.711 1259.5C919.654 1255.92 922.365 1253.15 925.843 1251.19C929.322 1249.18 933.321 1248.18 937.839 1248.18C941.363 1248.18 944.548 1248.8 947.394 1250.03C950.241 1251.27 952.635 1253.02 954.578 1255.28C956.521 1257.49 957.808 1260.14 958.441 1263.21H953.222C952.183 1259.88 950.286 1257.28 947.53 1255.41C944.774 1253.53 941.476 1252.59 937.635 1252.59C934.111 1252.59 930.994 1253.4 928.283 1255.02C925.617 1256.6 923.539 1258.84 922.048 1261.74C920.602 1264.59 919.88 1267.96 919.88 1271.84C919.88 1275.63 920.625 1278.98 922.116 1281.88C923.607 1284.78 925.685 1287.04 928.351 1288.66C931.016 1290.23 934.111 1291.02 937.635 1291.02C941.476 1291.02 944.819 1290.11 947.665 1288.27C950.512 1286.44 952.454 1284.01 953.493 1280.98H958.712C957.989 1283.84 956.611 1286.35 954.578 1288.53C952.59 1290.7 950.128 1292.41 947.191 1293.64C944.254 1294.84 941.046 1295.43 937.568 1295.43Z" fill="${params.colorE}"/>
<path d="M860.52 1294.73H855.641V1248.95H873.735C878.615 1248.95 882.455 1250.14 885.256 1252.53C888.102 1254.87 889.526 1258.09 889.526 1262.18C889.526 1265.46 888.645 1268.21 886.883 1270.43C885.166 1272.65 882.749 1274.16 879.631 1274.97L890 1294.73H884.578L874.684 1275.74H860.52V1294.73ZM860.52 1253.1V1271.58H873.871C877.214 1271.58 879.812 1270.75 881.664 1269.09C883.562 1267.42 884.511 1265.17 884.511 1262.31C884.511 1259.33 883.539 1257.04 881.596 1255.47C879.654 1253.89 877.033 1253.1 873.735 1253.1H860.52Z" fill="${params.colorE}"/>
<path d="M846.789 1271.84C846.789 1276.49 845.795 1280.58 843.808 1284.12C841.865 1287.66 839.154 1290.45 835.675 1292.49C832.196 1294.5 828.198 1295.5 823.68 1295.5C819.162 1295.5 815.141 1294.5 811.617 1292.49C808.138 1290.45 805.427 1287.66 803.484 1284.12C801.542 1280.58 800.57 1276.49 800.57 1271.84C800.57 1267.19 801.542 1263.1 803.484 1259.56C805.472 1255.98 808.206 1253.19 811.685 1251.19C815.163 1249.14 819.162 1248.12 823.68 1248.12C828.243 1248.12 832.241 1249.14 835.675 1251.19C839.154 1253.19 841.865 1255.98 843.808 1259.56C845.795 1263.1 846.789 1267.19 846.789 1271.84ZM841.774 1271.84C841.774 1268 841.006 1264.66 839.47 1261.8C837.934 1258.9 835.811 1256.64 833.1 1255.02C830.434 1253.4 827.294 1252.59 823.68 1252.59C820.111 1252.59 816.971 1253.4 814.26 1255.02C811.549 1256.64 809.426 1258.9 807.889 1261.8C806.399 1264.66 805.653 1268 805.653 1271.84C805.653 1275.63 806.399 1278.98 807.889 1281.88C809.426 1284.78 811.549 1287.04 814.26 1288.66C816.971 1290.28 820.111 1291.09 823.68 1291.09C827.294 1291.09 830.434 1290.28 833.1 1288.66C835.811 1287.04 837.934 1284.78 839.47 1281.88C841.006 1278.98 841.774 1275.63 841.774 1271.84Z" fill="${params.colorE}"/>
<path d="M771.637 1248.95V1294.73H766.758V1248.95H771.637ZM795.357 1253.17H766.961V1248.95H795.357V1253.17ZM791.494 1274.59H766.961V1270.43H791.494V1274.59Z" fill="${params.colorE}"/>
<path d="M739.11 1271.84C739.11 1276.49 738.116 1280.58 736.128 1284.12C734.185 1287.66 731.474 1290.45 727.995 1292.49C724.517 1294.5 720.518 1295.5 716 1295.5C711.482 1295.5 707.461 1294.5 703.937 1292.49C700.458 1290.45 697.747 1287.66 695.805 1284.12C693.862 1280.58 692.891 1276.49 692.891 1271.84C692.891 1267.19 693.862 1263.1 695.805 1259.56C697.793 1255.98 700.526 1253.19 704.005 1251.19C707.484 1249.14 711.482 1248.12 716 1248.12C720.563 1248.12 724.562 1249.14 727.995 1251.19C731.474 1253.19 734.185 1255.98 736.128 1259.56C738.116 1263.1 739.11 1267.19 739.11 1271.84ZM734.095 1271.84C734.095 1268 733.327 1264.66 731.791 1261.8C730.254 1258.9 728.131 1256.64 725.42 1255.02C722.755 1253.4 719.615 1252.59 716 1252.59C712.431 1252.59 709.291 1253.4 706.58 1255.02C703.869 1256.64 701.746 1258.9 700.21 1261.8C698.719 1264.66 697.973 1268 697.973 1271.84C697.973 1275.63 698.719 1278.98 700.21 1281.88C701.746 1284.78 703.869 1287.04 706.58 1288.66C709.291 1290.28 712.431 1291.09 716 1291.09C719.615 1291.09 722.755 1290.28 725.42 1288.66C728.131 1287.04 730.254 1284.78 731.791 1281.88C733.327 1278.98 734.095 1275.63 734.095 1271.84Z" fill="${params.colorE}"/>
<path d="M659.122 1248.95V1294.73H654.242V1248.95H659.122ZM672.743 1277.91H658.105V1273.76H671.795C675.319 1273.76 678.052 1272.8 679.995 1270.88C681.937 1268.92 682.909 1266.38 682.909 1263.27C682.909 1260.11 681.937 1257.64 679.995 1255.85C678.052 1254.02 675.409 1253.1 672.066 1253.1H656.004V1248.95H672.743C675.861 1248.95 678.549 1249.54 680.808 1250.74C683.112 1251.93 684.874 1253.61 686.094 1255.79C687.359 1257.96 687.992 1260.48 687.992 1263.33C687.992 1266.1 687.359 1268.6 686.094 1270.81C684.874 1273.03 683.112 1274.78 680.808 1276.06C678.549 1277.29 675.861 1277.91 672.743 1277.91Z" fill="${params.colorE}"/>
<path d="M613.141 1260.84C613.141 1258.28 613.819 1256.07 615.174 1254.19C616.53 1252.27 618.427 1250.78 620.867 1249.72C623.307 1248.65 626.153 1248.12 629.406 1248.12C632.343 1248.12 634.895 1248.61 637.064 1249.59C639.278 1250.57 641.04 1251.98 642.35 1253.81C643.66 1255.64 644.428 1257.84 644.654 1260.39H639.707C639.436 1257.84 638.397 1255.85 636.59 1254.45C634.782 1253 632.365 1252.27 629.338 1252.27C625.95 1252.27 623.216 1253.04 621.138 1254.58C619.06 1256.11 618.021 1258.18 618.021 1260.78C618.021 1262.78 618.653 1264.44 619.918 1265.77C621.183 1267.04 623.081 1267.98 625.611 1268.58L633.947 1270.5C637.742 1271.35 640.611 1272.8 642.553 1274.85C644.496 1276.85 645.467 1279.41 645.467 1282.52C645.467 1285.12 644.79 1287.4 643.434 1289.36C642.079 1291.32 640.159 1292.83 637.674 1293.9C635.189 1294.97 632.275 1295.5 628.932 1295.5C625.814 1295.5 623.036 1295.01 620.596 1294.03C618.201 1293.05 616.304 1291.64 614.903 1289.81C613.548 1287.98 612.757 1285.82 612.531 1283.35H617.546C617.682 1285.78 618.789 1287.72 620.867 1289.17C622.945 1290.58 625.633 1291.28 628.932 1291.28C632.501 1291.28 635.325 1290.51 637.403 1288.98C639.526 1287.44 640.588 1285.37 640.588 1282.77C640.588 1280.69 639.955 1279 638.69 1277.72C637.471 1276.4 635.55 1275.44 632.93 1274.85L624.73 1272.93C620.935 1272.07 618.043 1270.65 616.055 1268.64C614.113 1266.6 613.141 1264 613.141 1260.84Z" fill="${params.colorE}"/>
<path d="M573.153 1294.73H568.273V1248.95H573.221L601.074 1288.72H599.922V1248.95H604.734V1294.73H599.922L572.001 1254.96H573.153V1294.73Z" fill="${params.colorE}"/>
<path d="M556.473 1248.95V1294.73H551.594V1248.95H556.473Z" fill="${params.colorE}"/>
</g>
<g clip-path="url(#clip1_2071_687)">
<rect width="966.5" height="1345.5" transform="translate(1530 346)" fill="${params.colorB}"/>
<circle cx="2528.69" cy="872.854" r="272.432" fill="${params.colorC}"/>
<path d="M1883.93 1075.96L1853.91 1111.32V1104.48L1878.1 1075.96H1883.93ZM1854.72 1075.96V1121.74H1849.84V1075.96H1854.72ZM1863.94 1098.66L1867.19 1094.89L1884.2 1121.74H1878.51L1863.94 1098.66Z" fill="${params.colorC}"/>
<path d="M1806.42 1121.74H1801.54V1075.96H1806.49L1834.34 1115.73H1833.19V1075.96H1838V1121.74H1833.19L1805.27 1081.97H1806.42V1121.74Z" fill="${params.colorC}"/>
<path d="M1757.86 1121.74H1752.91L1770.74 1075.96H1776.77L1794.8 1121.74H1789.71L1784.97 1109.6H1762.61L1757.86 1121.74ZM1773.11 1082.42L1764.1 1105.57H1783.41L1774.33 1082.42C1774.24 1082.08 1774.13 1081.74 1773.99 1081.4C1773.85 1081.06 1773.76 1080.78 1773.72 1080.56C1773.67 1080.78 1773.58 1081.08 1773.45 1081.46C1773.36 1081.8 1773.25 1082.12 1773.11 1082.42Z" fill="${params.colorC}"/>
<path d="M1726.93 1075.96V1121.74H1722.05V1075.96H1726.93ZM1722.86 1121.74V1117.52H1749.22V1121.74H1722.86Z" fill="${params.colorC}"/>
<path d="M1684.3 1075.96V1121.74H1679.42V1075.96H1684.3ZM1698.19 1100.83H1683.08V1096.81H1697.72C1700.79 1096.81 1703.14 1096.06 1704.77 1094.57C1706.44 1093.03 1707.28 1090.9 1707.28 1088.17C1707.28 1085.62 1706.39 1083.63 1704.63 1082.23C1702.87 1080.78 1700.36 1080.05 1697.11 1080.05H1681.18V1075.96H1697.18C1701.88 1075.96 1705.56 1077.03 1708.22 1079.16C1710.93 1081.25 1712.29 1084.12 1712.29 1087.79C1712.29 1090.69 1711.54 1093.1 1710.05 1095.02C1708.61 1096.89 1706.42 1098.26 1703.48 1099.11V1098.34C1706.73 1099.11 1709.17 1100.45 1710.8 1102.37C1712.43 1104.25 1713.24 1106.7 1713.24 1109.72C1713.24 1112.15 1712.63 1114.28 1711.41 1116.12C1710.19 1117.91 1708.45 1119.29 1706.19 1120.27C1703.93 1121.25 1701.27 1121.74 1698.19 1121.74H1681.18V1117.59H1698.19C1701.36 1117.59 1703.82 1116.84 1705.58 1115.35C1707.39 1113.86 1708.29 1111.79 1708.29 1109.15C1708.29 1106.55 1707.39 1104.52 1705.58 1103.07C1703.82 1101.58 1701.36 1100.83 1698.19 1100.83Z" fill="${params.colorC}"/>
<path d="M1669.52 1121.74H1640.45V1075.96H1669.45V1080.18H1642.28L1645.32 1077.69V1096.68H1667.21V1100.83H1645.32V1120.02L1642.28 1117.52H1669.52V1121.74Z" fill="${params.colorC}"/>
<path d="M1607.37 1121.74H1592.59V1075.96H1607.23C1612.11 1075.96 1616.38 1076.92 1620.04 1078.84C1623.7 1080.76 1626.55 1083.44 1628.58 1086.9C1630.61 1090.31 1631.63 1094.29 1631.63 1098.85C1631.63 1103.41 1630.61 1107.42 1628.58 1110.87C1626.59 1114.28 1623.77 1116.95 1620.11 1118.87C1616.49 1120.79 1612.25 1121.74 1607.37 1121.74ZM1597.47 1078.14V1119.57L1595.24 1117.52H1607.16C1611.14 1117.52 1614.57 1116.76 1617.47 1115.22C1620.36 1113.69 1622.59 1111.53 1624.17 1108.76C1625.8 1105.95 1626.61 1102.65 1626.61 1098.85C1626.61 1095.06 1625.8 1091.75 1624.17 1088.94C1622.59 1086.13 1620.33 1083.98 1617.4 1082.48C1614.51 1080.95 1611.05 1080.18 1607.03 1080.18H1595.24L1597.47 1078.14Z" fill="${params.colorC}"/>
<path d="M1883.93 989.469L1853.91 1024.83V1017.99L1878.1 989.469H1883.93ZM1854.72 989.469V1035.25H1849.84V989.469H1854.72ZM1863.94 1012.17L1867.19 1008.4L1884.2 1035.25H1878.51L1863.94 1012.17Z" fill="${params.colorC}"/>
<path d="M2354.87 803.295L2390.23 833.317L2383.39 833.317L2354.87 809.123L2354.87 803.295ZM2354.87 832.503L2400.65 832.503L2400.65 837.383L2354.87 837.383L2354.87 832.503ZM2377.57 823.287L2373.79 820.034L2400.65 803.023L2400.65 808.716L2377.57 823.287Z" fill="${params.colorB}"/>
<path d="M1806.42 1035.25H1801.54V989.469H1806.49L1834.34 1029.24H1833.19V989.469H1838V1035.25H1833.19L1805.27 995.479H1806.42V1035.25Z" fill="${params.colorC}"/>
<path d="M2400.65 880.816L2400.65 885.695L2354.87 885.695L2354.87 880.748L2394.64 852.895L2394.64 854.047L2354.87 854.047L2354.87 849.235L2400.65 849.235L2400.65 854.047L2360.88 881.968L2360.88 880.816L2400.65 880.816Z" fill="${params.colorB}"/>
<path d="M1757.86 1035.25H1752.91L1770.74 989.469H1776.77L1794.8 1035.25H1789.71L1784.97 1023.1H1762.61L1757.86 1035.25ZM1773.11 995.927L1764.1 1019.07H1783.41L1774.33 995.927C1774.24 995.586 1774.13 995.245 1773.99 994.904C1773.85 994.563 1773.76 994.286 1773.72 994.073C1773.67 994.286 1773.58 994.584 1773.45 994.968C1773.36 995.309 1773.25 995.629 1773.11 995.927Z" fill="${params.colorC}"/>
<path d="M2400.65 929.381L2400.65 934.328L2354.87 916.505L2354.87 910.473L2400.65 892.446L2400.65 897.529L2388.5 902.273L2388.5 924.637L2400.65 929.381ZM2361.33 914.133L2384.47 923.146L2384.47 903.832L2361.33 912.913C2360.98 913.003 2360.64 913.116 2360.3 913.252C2359.96 913.387 2359.68 913.478 2359.47 913.523C2359.68 913.568 2359.98 913.658 2360.37 913.794C2360.71 913.884 2361.03 913.997 2361.33 914.133Z" fill="${params.colorB}"/>
<path d="M1726.93 989.469V1035.25H1722.05V989.469H1726.93ZM1722.86 1035.25V1031.03H1749.22V1035.25H1722.86Z" fill="${params.colorC}"/>
<path d="M2354.87 960.3L2400.65 960.3L2400.65 965.18L2354.87 965.18L2354.87 960.3ZM2400.65 964.366L2396.43 964.366L2396.43 938.004L2400.65 938.004L2400.65 964.366Z" fill="${params.colorB}"/>
<path d="M1684.3 989.469V1035.25H1679.42V989.469H1684.3ZM1698.19 1014.34H1683.08V1010.31H1697.72C1700.79 1010.31 1703.14 1009.57 1704.77 1008.08C1706.44 1006.54 1707.28 1004.41 1707.28 1001.68C1707.28 999.124 1706.39 997.142 1704.63 995.735C1702.87 994.286 1700.36 993.561 1697.11 993.561H1681.18V989.469H1697.18C1701.88 989.469 1705.56 990.534 1708.22 992.666C1710.93 994.755 1712.29 997.632 1712.29 1001.3C1712.29 1004.2 1711.54 1006.61 1710.05 1008.52C1708.61 1010.4 1706.42 1011.76 1703.48 1012.62V1011.85C1706.73 1012.62 1709.17 1013.96 1710.8 1015.88C1712.43 1017.75 1713.24 1020.2 1713.24 1023.23C1713.24 1025.66 1712.63 1027.79 1711.41 1029.63C1710.19 1031.42 1708.45 1032.8 1706.19 1033.78C1703.93 1034.76 1701.27 1035.25 1698.19 1035.25H1681.18V1031.1H1698.19C1701.36 1031.1 1703.82 1030.35 1705.58 1028.86C1707.39 1027.37 1708.29 1025.3 1708.29 1022.66C1708.29 1020.05 1707.39 1018.03 1705.58 1016.58C1703.82 1015.09 1701.36 1014.34 1698.19 1014.34Z" fill="${params.colorC}"/>
<path d="M2354.87 1002.92L2400.65 1002.92L2400.65 1007.8L2354.87 1007.8L2354.87 1002.92ZM2379.74 989.025L2379.74 1004.14L2375.71 1004.14L2375.71 989.499C2375.71 986.427 2374.97 984.077 2373.47 982.451C2371.94 980.779 2369.81 979.943 2367.08 979.943C2364.52 979.943 2362.54 980.824 2361.13 982.586C2359.68 984.348 2358.96 986.856 2358.96 990.109L2358.96 1006.03L2354.87 1006.03L2354.87 990.041C2354.87 985.342 2355.93 981.66 2358.06 978.995C2360.15 976.284 2363.03 974.928 2366.7 974.928C2369.6 974.928 2372 975.674 2373.92 977.165C2375.8 978.611 2377.16 980.802 2378.01 983.739L2377.25 983.739C2378.01 980.486 2379.36 978.046 2381.28 976.419C2383.15 974.793 2385.6 973.98 2388.63 973.98C2391.06 973.98 2393.19 974.59 2395.02 975.809C2396.81 977.029 2398.2 978.769 2399.18 981.028C2400.16 983.287 2400.65 985.952 2400.65 989.025L2400.65 1006.03L2396.49 1006.03L2396.49 989.025C2396.49 985.862 2395.75 983.4 2394.26 981.638C2392.76 979.83 2390.7 978.927 2388.05 978.927C2385.45 978.927 2383.43 979.83 2381.98 981.638C2380.49 983.4 2379.74 985.862 2379.74 989.025Z" fill="${params.colorB}"/>
<path d="M1669.52 1035.25H1640.45V989.469H1669.45V993.689H1642.28L1645.32 991.195V1010.19H1667.21V1014.34H1645.32V1033.53L1642.28 1031.03H1669.52V1035.25Z" fill="${params.colorC}"/>
<path d="M2400.65 1017.71L2400.65 1046.78L2354.87 1046.78L2354.87 1017.78L2359.09 1017.78L2359.09 1044.95L2356.59 1041.9L2375.58 1041.9L2375.58 1020.01L2379.74 1020.01L2379.74 1041.9L2398.92 1041.9L2396.43 1044.95L2396.43 1017.71L2400.65 1017.71Z" fill="${params.colorB}"/>
<path d="M1607.37 1035.25H1592.59V989.469H1607.23C1612.11 989.469 1616.38 990.428 1620.04 992.346C1623.7 994.264 1626.55 996.95 1628.58 1000.4C1630.61 1003.81 1631.63 1007.8 1631.63 1012.36C1631.63 1016.92 1630.61 1020.93 1628.58 1024.38C1626.59 1027.79 1623.77 1030.46 1620.11 1032.37C1616.49 1034.29 1612.25 1035.25 1607.37 1035.25ZM1597.47 991.643V1033.08L1595.24 1031.03H1607.16C1611.14 1031.03 1614.57 1030.26 1617.47 1028.73C1620.36 1027.2 1622.59 1025.04 1624.17 1022.27C1625.8 1019.46 1626.61 1016.15 1626.61 1012.36C1626.61 1008.57 1625.8 1005.26 1624.17 1002.45C1622.59 999.636 1620.33 997.483 1617.4 995.991C1614.51 994.456 1611.05 993.689 1607.03 993.689H1595.24L1597.47 991.643Z" fill="${params.colorC}"/>
<path d="M2400.65 1079.85L2400.65 1094.62L2354.87 1094.62L2354.87 1079.99C2354.87 1075.11 2355.83 1070.84 2357.74 1067.18C2359.66 1063.52 2362.35 1060.67 2365.8 1058.64C2369.21 1056.61 2373.2 1055.59 2377.76 1055.59C2382.32 1055.59 2386.33 1056.61 2389.78 1058.64C2393.19 1060.63 2395.85 1063.45 2397.77 1067.11C2399.69 1070.72 2400.65 1074.97 2400.65 1079.85ZM2357.04 1089.75L2398.48 1089.75L2396.43 1091.98L2396.43 1080.05C2396.43 1076.08 2395.66 1072.64 2394.13 1069.75C2392.59 1066.86 2390.44 1064.63 2387.67 1063.04C2384.86 1061.42 2381.55 1060.6 2377.76 1060.6C2373.96 1060.6 2370.66 1061.42 2367.85 1063.04C2365.03 1064.63 2362.88 1066.88 2361.39 1069.82C2359.85 1072.71 2359.09 1076.17 2359.09 1080.19L2359.09 1091.98L2357.04 1089.75Z" fill="${params.colorB}"/>
<path d="M2317.42 284.25L2137.34 493.217V465.46L2292.96 284.25H2317.42ZM2139.03 284.25V565.78H2118.79V284.25H2139.03ZM2199.76 415.895L2213.68 401.224L2319.53 565.78H2295.91L2199.76 415.895Z" fill="${params.colorC}"/>
<path d="M1877.92 284.25V565.78H1857.68V284.25H1877.92ZM1971.97 433.342H1874.13V415.895H1969.02C1989.82 415.895 2005.85 410.74 2017.09 400.431C2028.62 390.121 2034.38 375.714 2034.38 357.21C2034.38 339.763 2028.2 326.149 2015.83 316.368C2003.74 306.587 1986.59 301.697 1964.38 301.697H1857.68V284.25H1963.11C1991.51 284.25 2013.86 290.594 2030.17 303.283C2046.75 315.972 2055.05 333.419 2055.05 355.624C2055.05 373.864 2050.41 388.799 2041.13 400.431C2032.13 412.062 2018.36 420.653 1999.8 426.205V421.446C2020.61 426.469 2036.07 434.928 2046.19 446.824C2056.59 458.719 2061.79 474.052 2061.79 492.82C2061.79 507.359 2058 520.18 2050.41 531.283C2042.82 542.121 2032.13 550.58 2018.36 556.66C2004.86 562.74 1988.98 565.78 1970.7 565.78H1857.68V548.333H1971.97C1993.62 548.333 2010.48 543.178 2022.57 532.869C2034.94 522.559 2041.13 508.417 2041.13 490.441C2041.13 472.73 2034.94 458.852 2022.57 448.806C2010.48 438.497 1993.62 433.342 1971.97 433.342Z" fill="${params.colorC}"/>
<path d="M1649.6 565.78H1565.68V284.25H1649.6C1679.97 284.25 1706.4 290.198 1728.89 302.093C1751.66 313.725 1769.23 330.114 1781.6 351.262C1794.25 372.146 1800.58 396.73 1800.58 425.015C1800.58 453.036 1794.25 477.62 1781.6 498.768C1769.23 519.916 1751.66 536.438 1728.89 548.333C1706.4 559.965 1679.97 565.78 1649.6 565.78ZM1585.92 293.767V556.264L1576.64 548.333H1649.6C1676.03 548.333 1698.94 543.311 1718.34 533.265C1737.74 522.956 1752.79 508.549 1763.47 490.045C1774.43 471.54 1779.92 449.864 1779.92 425.015C1779.92 399.902 1774.57 378.093 1763.89 359.589C1753.21 341.085 1738.17 326.81 1718.77 316.765C1699.37 306.72 1676.31 301.697 1649.6 301.697H1576.64L1585.92 293.767Z" fill="${params.colorC}"/>
<circle cx="1610.76" cy="668.404" r="27.5965" stroke="${params.colorC}" stroke-width="3.36543"/>
<circle cx="1610.6" cy="732.177" r="16.3223" stroke="${params.colorC}" stroke-width="3.36543"/>
<path d="M1627.76 784.853C1627.76 794.433 1620.07 802.185 1610.6 802.185C1601.13 802.185 1593.44 794.433 1593.44 784.853C1593.44 775.273 1601.13 767.521 1610.6 767.521C1620.07 767.521 1627.76 775.273 1627.76 784.853Z" fill="${params.colorC}" stroke="${params.colorC}" stroke-width="1.68272"/>
<path d="M2495.28 1333.76L2680.64 1654.82H2309.91L2495.28 1333.76Z" fill="${params.colorC}"/>
<path d="M1847.32 1782.7L2029.77 1466.69L2212.22 1782.7H1847.32Z" stroke="${params.colorC}" stroke-width="3.36543"/>
<path d="M2029.32 1367L2394.22 1367L2211.77 1683.02L2029.32 1367Z" stroke="${params.colorC}" stroke-width="3.36543"/>
<path d="M1598.88 1520.81L1963.78 1520.81L1781.33 1836.82L1598.88 1520.81Z" fill="${params.colorC}" stroke="${params.colorC}" stroke-width="3.36543"/>
<path d="M2018.86 1397.76L2333.54 1397.76L2176.2 1670.28L2018.86 1397.76Z" fill="${params.colorC}" stroke="${params.colorC}" stroke-width="3.36543"/>
<path d="M2300.61 1295.44C2299.48 1295.44 2298.49 1295.05 2297.63 1294.29C2296.77 1293.52 2296.34 1292.6 2296.34 1291.54C2296.34 1290.43 2296.77 1289.49 2297.63 1288.72C2298.49 1287.91 2299.48 1287.51 2300.61 1287.51C2301.79 1287.51 2302.78 1287.91 2303.6 1288.72C2304.45 1289.49 2304.88 1290.43 2304.88 1291.54C2304.88 1292.6 2304.45 1293.52 2303.6 1294.29C2302.78 1295.05 2301.79 1295.44 2300.61 1295.44Z" fill="${params.colorC}"/>
<path d="M2256.86 1260.84C2256.86 1258.28 2257.54 1256.07 2258.89 1254.19C2260.25 1252.27 2262.15 1250.78 2264.59 1249.72C2267.03 1248.65 2269.87 1248.12 2273.12 1248.12C2276.06 1248.12 2278.61 1248.61 2280.78 1249.59C2283 1250.57 2284.76 1251.98 2286.07 1253.81C2287.38 1255.64 2288.15 1257.84 2288.37 1260.39H2283.43C2283.15 1257.84 2282.12 1255.85 2280.31 1254.45C2278.5 1253 2276.08 1252.27 2273.06 1252.27C2269.67 1252.27 2266.94 1253.04 2264.86 1254.58C2262.78 1256.11 2261.74 1258.18 2261.74 1260.78C2261.74 1262.78 2262.37 1264.44 2263.64 1265.77C2264.9 1267.04 2266.8 1267.98 2269.33 1268.58L2277.67 1270.5C2281.46 1271.35 2284.33 1272.8 2286.27 1274.85C2288.21 1276.85 2289.19 1279.41 2289.19 1282.52C2289.19 1285.12 2288.51 1287.4 2287.15 1289.36C2285.8 1291.32 2283.88 1292.83 2281.39 1293.9C2278.91 1294.97 2275.99 1295.5 2272.65 1295.5C2269.53 1295.5 2266.75 1295.01 2264.31 1294.03C2261.92 1293.05 2260.02 1291.64 2258.62 1289.81C2257.27 1287.98 2256.48 1285.82 2256.25 1283.35H2261.26C2261.4 1285.78 2262.51 1287.72 2264.59 1289.17C2266.66 1290.58 2269.35 1291.28 2272.65 1291.28C2276.22 1291.28 2279.04 1290.51 2281.12 1288.98C2283.25 1287.44 2284.31 1285.37 2284.31 1282.77C2284.31 1280.69 2283.67 1279 2282.41 1277.72C2281.19 1276.4 2279.27 1275.44 2276.65 1274.85L2268.45 1272.93C2264.65 1272.07 2261.76 1270.65 2259.77 1268.64C2257.83 1266.6 2256.86 1264 2256.86 1260.84Z" fill="${params.colorC}"/>
<path d="M2221.31 1294.73H2216.43V1248.95H2234.52C2239.4 1248.95 2243.24 1250.14 2246.05 1252.53C2248.89 1254.87 2250.31 1258.09 2250.31 1262.18C2250.31 1265.46 2249.43 1268.21 2247.67 1270.43C2245.95 1272.65 2243.54 1274.16 2240.42 1274.97L2250.79 1294.73H2245.37L2235.47 1275.74H2221.31V1294.73ZM2221.31 1253.1V1271.58H2234.66C2238 1271.58 2240.6 1270.75 2242.45 1269.09C2244.35 1267.42 2245.3 1265.17 2245.3 1262.31C2245.3 1259.33 2244.33 1257.04 2242.39 1255.47C2240.44 1253.89 2237.82 1253.1 2234.52 1253.1H2221.31Z" fill="${params.colorC}"/>
<path d="M2207.58 1271.84C2207.58 1276.49 2206.58 1280.58 2204.6 1284.12C2202.65 1287.66 2199.94 1290.45 2196.46 1292.49C2192.99 1294.5 2188.99 1295.5 2184.47 1295.5C2179.95 1295.5 2175.93 1294.5 2172.41 1292.49C2168.93 1290.45 2166.22 1287.66 2164.27 1284.12C2162.33 1280.58 2161.36 1276.49 2161.36 1271.84C2161.36 1267.19 2162.33 1263.1 2164.27 1259.56C2166.26 1255.98 2168.99 1253.19 2172.47 1251.19C2175.95 1249.14 2179.95 1248.12 2184.47 1248.12C2189.03 1248.12 2193.03 1249.14 2196.46 1251.19C2199.94 1253.19 2202.65 1255.98 2204.6 1259.56C2206.58 1263.1 2207.58 1267.19 2207.58 1271.84ZM2202.56 1271.84C2202.56 1268 2201.8 1264.66 2200.26 1261.8C2198.72 1258.9 2196.6 1256.64 2193.89 1255.02C2191.22 1253.4 2188.08 1252.59 2184.47 1252.59C2180.9 1252.59 2177.76 1253.4 2175.05 1255.02C2172.34 1256.64 2170.21 1258.9 2168.68 1261.8C2167.19 1264.66 2166.44 1268 2166.44 1271.84C2166.44 1275.63 2167.19 1278.98 2168.68 1281.88C2170.21 1284.78 2172.34 1287.04 2175.05 1288.66C2177.76 1290.28 2180.9 1291.09 2184.47 1291.09C2188.08 1291.09 2191.22 1290.28 2193.89 1288.66C2196.6 1287.04 2198.72 1284.78 2200.26 1281.88C2201.8 1278.98 2202.56 1275.63 2202.56 1271.84Z" fill="${params.colorC}"/>
<path d="M2144.43 1250.99V1294.73H2139.55V1250.99H2144.43ZM2124.64 1253.17V1248.95H2159.41V1253.17H2124.64Z" fill="${params.colorC}"/>
<path d="M2090.49 1294.73H2085.54L2103.36 1248.95H2109.39L2127.42 1294.73H2122.34L2117.59 1282.58H2095.23L2090.49 1294.73ZM2105.73 1255.4L2096.72 1278.55H2116.04L2106.95 1255.4C2106.86 1255.06 2106.75 1254.72 2106.62 1254.38C2106.48 1254.04 2106.39 1253.76 2106.34 1253.55C2106.3 1253.76 2106.21 1254.06 2106.07 1254.44C2105.98 1254.79 2105.87 1255.11 2105.73 1255.4Z" fill="${params.colorC}"/>
<path d="M2080.57 1294.73H2051.5V1248.95H2080.51V1253.17H2053.33L2056.38 1250.67V1269.66H2078.27V1273.82H2056.38V1293L2053.33 1290.51H2080.57V1294.73Z" fill="${params.colorC}"/>
<path d="M2013.04 1294.73H2008.16V1248.95H2026.25C2031.13 1248.95 2034.97 1250.14 2037.77 1252.53C2040.62 1254.87 2042.04 1258.09 2042.04 1262.18C2042.04 1265.46 2041.16 1268.21 2039.4 1270.43C2037.68 1272.65 2035.26 1274.16 2032.15 1274.97L2042.52 1294.73H2037.09L2027.2 1275.74H2013.04V1294.73ZM2013.04 1253.1V1271.58H2026.39C2029.73 1271.58 2032.33 1270.75 2034.18 1269.09C2036.08 1267.42 2037.03 1265.17 2037.03 1262.31C2037.03 1259.33 2036.05 1257.04 2034.11 1255.47C2032.17 1253.89 2029.55 1253.1 2026.25 1253.1H2013.04Z" fill="${params.colorC}"/>
<path d="M1978.57 1295.43C1974.05 1295.43 1970.07 1294.45 1966.64 1292.49C1963.25 1290.49 1960.59 1287.72 1958.64 1284.18C1956.75 1280.64 1955.8 1276.53 1955.8 1271.84C1955.8 1267.15 1956.77 1263.04 1958.71 1259.5C1960.65 1255.92 1963.36 1253.15 1966.84 1251.19C1970.32 1249.18 1974.32 1248.18 1978.84 1248.18C1982.36 1248.18 1985.55 1248.8 1988.39 1250.03C1991.24 1251.27 1993.64 1253.02 1995.58 1255.28C1997.52 1257.49 1998.81 1260.14 1999.44 1263.21H1994.22C1993.18 1259.88 1991.29 1257.28 1988.53 1255.41C1985.77 1253.53 1982.48 1252.59 1978.64 1252.59C1975.11 1252.59 1971.99 1253.4 1969.28 1255.02C1966.62 1256.6 1964.54 1258.84 1963.05 1261.74C1961.6 1264.59 1960.88 1267.96 1960.88 1271.84C1960.88 1275.63 1961.63 1278.98 1963.12 1281.88C1964.61 1284.78 1966.69 1287.04 1969.35 1288.66C1972.02 1290.23 1975.11 1291.02 1978.64 1291.02C1982.48 1291.02 1985.82 1290.11 1988.67 1288.27C1991.51 1286.44 1993.45 1284.01 1994.49 1280.98H1999.71C1998.99 1283.84 1997.61 1286.35 1995.58 1288.53C1993.59 1290.7 1991.13 1292.41 1988.19 1293.64C1985.25 1294.84 1982.05 1295.43 1978.57 1295.43Z" fill="${params.colorC}"/>
<path d="M1901.52 1294.73H1896.64V1248.95H1914.74C1919.61 1248.95 1923.45 1250.14 1926.26 1252.53C1929.1 1254.87 1930.53 1258.09 1930.53 1262.18C1930.53 1265.46 1929.64 1268.21 1927.88 1270.43C1926.17 1272.65 1923.75 1274.16 1920.63 1274.97L1931 1294.73H1925.58L1915.68 1275.74H1901.52V1294.73ZM1901.52 1253.1V1271.58H1914.87C1918.21 1271.58 1920.81 1270.75 1922.66 1269.09C1924.56 1267.42 1925.51 1265.17 1925.51 1262.31C1925.51 1259.33 1924.54 1257.04 1922.6 1255.47C1920.65 1253.89 1918.03 1253.1 1914.74 1253.1H1901.52Z" fill="${params.colorC}"/>
<path d="M1887.79 1271.84C1887.79 1276.49 1886.8 1280.58 1884.81 1284.12C1882.86 1287.66 1880.15 1290.45 1876.68 1292.49C1873.2 1294.5 1869.2 1295.5 1864.68 1295.5C1860.16 1295.5 1856.14 1294.5 1852.62 1292.49C1849.14 1290.45 1846.43 1287.66 1844.48 1284.12C1842.54 1280.58 1841.57 1276.49 1841.57 1271.84C1841.57 1267.19 1842.54 1263.1 1844.48 1259.56C1846.47 1255.98 1849.21 1253.19 1852.68 1251.19C1856.16 1249.14 1860.16 1248.12 1864.68 1248.12C1869.24 1248.12 1873.24 1249.14 1876.68 1251.19C1880.15 1253.19 1882.86 1255.98 1884.81 1259.56C1886.8 1263.1 1887.79 1267.19 1887.79 1271.84ZM1882.77 1271.84C1882.77 1268 1882.01 1264.66 1880.47 1261.8C1878.93 1258.9 1876.81 1256.64 1874.1 1255.02C1871.43 1253.4 1868.29 1252.59 1864.68 1252.59C1861.11 1252.59 1857.97 1253.4 1855.26 1255.02C1852.55 1256.64 1850.43 1258.9 1848.89 1261.8C1847.4 1264.66 1846.65 1268 1846.65 1271.84C1846.65 1275.63 1847.4 1278.98 1848.89 1281.88C1850.43 1284.78 1852.55 1287.04 1855.26 1288.66C1857.97 1290.28 1861.11 1291.09 1864.68 1291.09C1868.29 1291.09 1871.43 1290.28 1874.1 1288.66C1876.81 1287.04 1878.93 1284.78 1880.47 1281.88C1882.01 1278.98 1882.77 1275.63 1882.77 1271.84Z" fill="${params.colorC}"/>
<path d="M1812.64 1248.95V1294.73H1807.76V1248.95H1812.64ZM1836.36 1253.17H1807.96V1248.95H1836.36V1253.17ZM1832.49 1274.59H1807.96V1270.43H1832.49V1274.59Z" fill="${params.colorC}"/>
<path d="M1780.11 1271.84C1780.11 1276.49 1779.12 1280.58 1777.13 1284.12C1775.19 1287.66 1772.47 1290.45 1769 1292.49C1765.52 1294.5 1761.52 1295.5 1757 1295.5C1752.48 1295.5 1748.46 1294.5 1744.94 1292.49C1741.46 1290.45 1738.75 1287.66 1736.8 1284.12C1734.86 1280.58 1733.89 1276.49 1733.89 1271.84C1733.89 1267.19 1734.86 1263.1 1736.8 1259.56C1738.79 1255.98 1741.53 1253.19 1745 1251.19C1748.48 1249.14 1752.48 1248.12 1757 1248.12C1761.56 1248.12 1765.56 1249.14 1769 1251.19C1772.47 1253.19 1775.19 1255.98 1777.13 1259.56C1779.12 1263.1 1780.11 1267.19 1780.11 1271.84ZM1775.09 1271.84C1775.09 1268 1774.33 1264.66 1772.79 1261.8C1771.25 1258.9 1769.13 1256.64 1766.42 1255.02C1763.75 1253.4 1760.61 1252.59 1757 1252.59C1753.43 1252.59 1750.29 1253.4 1747.58 1255.02C1744.87 1256.64 1742.75 1258.9 1741.21 1261.8C1739.72 1264.66 1738.97 1268 1738.97 1271.84C1738.97 1275.63 1739.72 1278.98 1741.21 1281.88C1742.75 1284.78 1744.87 1287.04 1747.58 1288.66C1750.29 1290.28 1753.43 1291.09 1757 1291.09C1760.61 1291.09 1763.75 1290.28 1766.42 1288.66C1769.13 1287.04 1771.25 1284.78 1772.79 1281.88C1774.33 1278.98 1775.09 1275.63 1775.09 1271.84Z" fill="${params.colorC}"/>
<path d="M1700.12 1248.95V1294.73H1695.24V1248.95H1700.12ZM1713.74 1277.91H1699.11V1273.76H1712.79C1716.32 1273.76 1719.05 1272.8 1720.99 1270.88C1722.94 1268.92 1723.91 1266.38 1723.91 1263.27C1723.91 1260.11 1722.94 1257.64 1720.99 1255.85C1719.05 1254.02 1716.41 1253.1 1713.07 1253.1H1697V1248.95H1713.74C1716.86 1248.95 1719.55 1249.54 1721.81 1250.74C1724.11 1251.93 1725.87 1253.61 1727.09 1255.79C1728.36 1257.96 1728.99 1260.48 1728.99 1263.33C1728.99 1266.1 1728.36 1268.6 1727.09 1270.81C1725.87 1273.03 1724.11 1274.78 1721.81 1276.06C1719.55 1277.29 1716.86 1277.91 1713.74 1277.91Z" fill="${params.colorC}"/>
<path d="M1654.14 1260.84C1654.14 1258.28 1654.82 1256.07 1656.17 1254.19C1657.53 1252.27 1659.43 1250.78 1661.87 1249.72C1664.31 1248.65 1667.15 1248.12 1670.41 1248.12C1673.34 1248.12 1675.9 1248.61 1678.06 1249.59C1680.28 1250.57 1682.04 1251.98 1683.35 1253.81C1684.66 1255.64 1685.43 1257.84 1685.65 1260.39H1680.71C1680.44 1257.84 1679.4 1255.85 1677.59 1254.45C1675.78 1253 1673.37 1252.27 1670.34 1252.27C1666.95 1252.27 1664.22 1253.04 1662.14 1254.58C1660.06 1256.11 1659.02 1258.18 1659.02 1260.78C1659.02 1262.78 1659.65 1264.44 1660.92 1265.77C1662.18 1267.04 1664.08 1267.98 1666.61 1268.58L1674.95 1270.5C1678.74 1271.35 1681.61 1272.8 1683.55 1274.85C1685.5 1276.85 1686.47 1279.41 1686.47 1282.52C1686.47 1285.12 1685.79 1287.4 1684.43 1289.36C1683.08 1291.32 1681.16 1292.83 1678.67 1293.9C1676.19 1294.97 1673.27 1295.5 1669.93 1295.5C1666.81 1295.5 1664.04 1295.01 1661.6 1294.03C1659.2 1293.05 1657.3 1291.64 1655.9 1289.81C1654.55 1287.98 1653.76 1285.82 1653.53 1283.35H1658.55C1658.68 1285.78 1659.79 1287.72 1661.87 1289.17C1663.95 1290.58 1666.63 1291.28 1669.93 1291.28C1673.5 1291.28 1676.32 1290.51 1678.4 1288.98C1680.53 1287.44 1681.59 1285.37 1681.59 1282.77C1681.59 1280.69 1680.96 1279 1679.69 1277.72C1678.47 1276.4 1676.55 1275.44 1673.93 1274.85L1665.73 1272.93C1661.93 1272.07 1659.04 1270.65 1657.06 1268.64C1655.11 1266.6 1654.14 1264 1654.14 1260.84Z" fill="${params.colorC}"/>
<path d="M1614.15 1294.73H1609.27V1248.95H1614.22L1642.07 1288.72H1640.92V1248.95H1645.73V1294.73H1640.92L1613 1254.96H1614.15V1294.73Z" fill="${params.colorC}"/>
<path d="M1597.47 1248.95V1294.73H1592.59V1248.95H1597.47Z" fill="${params.colorC}"/>
</g>
<defs>
<clipPath id="clip0_2071_687">
<rect width="966.5" height="1345.5" fill="white" transform="translate(489 346)"/>
</clipPath>
<clipPath id="clip1_2071_687">
<rect width="966.5" height="1345.5" fill="white" transform="translate(1530 346)"/>
</clipPath>
</defs>
</svg>
`

export const Mockup_SVG = {
	simpleDesignSvg,
	design,
}
