import { atom } from "recoil"
import { AtomConversationsRecord } from "./Types"


const defaultValue: AtomConversationsRecord = {
	activeConversationIndex: 0,
	conversations: [],
	isNewChat: true,
}

export const atomConversationsRecord = atom<AtomConversationsRecord>({
	key: "ConversationsRecord",
	default: defaultValue,
})
