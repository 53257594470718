/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import Shadow from "./images/shadow.png"
import Lights from "./images/lights.png"
import RightBagMask from "./images/rightBag_mask.png"
import LeftBagMask from "./images/leftBag_mask.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/design/y5UsL35vcLmufMsTJ93EOe/Assistant-Mockups?node-id=136-307&t=IgE407doKjZSveaN-0

const mockupId = FontsBrandingMockupID.fonts_branding_mockup_3
const mockupWidth = 1987.08
const mockupHeight = 1324.72

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const ret = await props.semaphore.add(async () => {


		const [
			backgroundSprite, shadowSprite,
			lightsSprite, rightBagMaskSprite,
			leftBagMaskSprite,
		] = await MockupUtils.loadAssets([
			Background, Shadow, Lights, RightBagMask,
			LeftBagMask,

		])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight
		const mockupShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(mockupShape)
		mockupContainer.addChild(backgroundSprite)

		const bagWidth = 521
		const bagHeight = 1094.22
		const bagY = 58

		// Right Bag
		const rightBagContainer = new PIXI.Container()
		const rightBagDesignContainer = new PIXI.Container()
		rightBagDesignContainer.width = bagWidth
		rightBagDesignContainer.height = bagHeight
		const rightBagShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: bagWidth,
			height: bagHeight,
		})
		rightBagDesignContainer.addChild(rightBagShape)


		const characterText = await MockupUtils.createText({
			text: "C",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 500,
			color: "#000000",
			fontSize: 700,
			trim: true,
			padding: 200,
			fitText: {
				containerHeight: bagWidth,
			},
		})

		characterText.position.set(-(characterText.width * 0.1), bagHeight - characterText.height)
		rightBagDesignContainer.addChild(characterText)

		rightBagDesignContainer.position.set(1094, bagY)
		rightBagContainer.addChild(rightBagDesignContainer)
		rightBagContainer.addChild(rightBagMaskSprite)
		rightBagContainer.mask = rightBagMaskSprite

		mockupContainer.addChild(rightBagContainer)


		// Left Bag
		const leftBagContainer = new PIXI.Container()
		const leftBagDesignContainer = new PIXI.Container()
		leftBagDesignContainer.width = bagWidth
		leftBagDesignContainer.height = bagHeight
		const leftBagShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: bagWidth,
			height: bagHeight,
		})
		leftBagDesignContainer.addChild(leftBagShape)

		const leftBagDecorationHeight = 247.06
		const leftBagDecoration = MockupUtils.drawRect({
			color: "#000000",
			width: bagWidth,
			height: leftBagDecorationHeight,
		})

		leftBagDecoration.position.set(0, bagHeight - leftBagDecorationHeight)
		leftBagDesignContainer.addChild(leftBagDecoration)

		const topText = await MockupUtils.createText({
			text: "Craft your own",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 500,
			color: "#000000",
			fontSize: 40,
			trim: true,
		})

		topText.position.set((bagWidth - topText.width) / 2, 808)
		leftBagDesignContainer.addChild(topText)

		const bottomText = await MockupUtils.createText({
			text: "path.",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 500,
			color: "#E3D7C2",
			fontSize: 40,
			trim: true,
		})

		bottomText.position.set((bagWidth - bottomText.width) / 2, 867)
		leftBagDesignContainer.addChild(bottomText)

		leftBagDesignContainer.position.set(361, bagY)
		leftBagContainer.addChild(leftBagDesignContainer)
		leftBagContainer.addChild(leftBagMaskSprite)
		leftBagContainer.mask = leftBagMaskSprite

		mockupContainer.addChild(leftBagContainer)


		lightsSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(lightsSprite)
		mockupContainer.addChild(shadowSprite)

		const imageData = MockupUtils.exportImage({
			app: props.app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!

}

export const Fonts_Mockup_Branding_3: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
