import React from "react"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import RelatedIcon from "deblank-common/src/assets/images/icon-library/related.svg"
import CloseIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import ArrowLeftIcon from "deblank-common/src/assets/images/icon-library/arrow-left.svg"
import StepsController from "./StepsController/StepsController"
import Styles from "./TopBar.module.scss"
import { RelatedActionContext, RelatedActionData } from "../../RelatedActionsConfigs/RelatedActionsTypes"

type Props<T,> = {
	selectedStepIndex: number,
	relatedActionData: RelatedActionData<T>,
	relatedActionContext: RelatedActionContext<T>,
	onCleanUpRelatedState: () => void,
	onNextStep: () => void,
	onPrevStep: () => void,

}

const TopBar = <T,>(props: Props<T>) => {
	return (
		<div className={Styles.top_bar}>
			<div className={Styles.left_column}>
				{props.selectedStepIndex === 0
					&& <RelatedIcon className={Styles.related_icon} />}
				{props.relatedActionData.config.steps.length > 1
					&& props.selectedStepIndex > 0
					&& <ButtonIcon type="button"
						onClick={props.onPrevStep}
						customStyles={{
							variant: "ghost",
						}}
						iconSVGComponent={ArrowLeftIcon}
					/>
				}
				<p className={Styles.only_shows_on_desktop}>{props.relatedActionData.label}</p>
			</div>
			<div className={Styles.right_column}>
				{props.relatedActionData.config.steps.length > 1 && <StepsController
					onNextStep={props.onNextStep}
					selectedStepIndex={props.selectedStepIndex}
					stepsAmount={props.relatedActionData.config.steps.length}
					stepCompleted={!!props.relatedActionData.config.steps[props.selectedStepIndex]
						.onGetValue(props.relatedActionContext)}
					isNextActionDisabled={props.relatedActionData.config.steps[props.selectedStepIndex].required
						&& !props.relatedActionData.config.steps[props.selectedStepIndex]
							.onGetValue(props.relatedActionContext)}
				/>}
				<div className={Styles.only_shows_on_desktop}>
					<ButtonIcon type="button"
						onClick={props.onCleanUpRelatedState}
						customStyles={{
							variant: "ghost",
						}}
						iconSVGComponent={CloseIcon}
					/>
				</div>
			</div>

		</div>
	)
}

export default TopBar