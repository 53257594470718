import AuthenticationPage from "@/pages/AuthenticationPage/AuthenticationPage"
import { createFileRoute } from "@tanstack/react-router"

type AuthSearchParams = {
	token: string,
}

export const Route = createFileRoute("/authentication")({
	component: AuthenticationPage,
	validateSearch: (search: Record<string, unknown>): AuthSearchParams => {
		return {
			token: (search.token as string) || "",
		}
	},
})