import React from "react"
import { DisplayWidgetType } from "deblank-api-types"
import WidgetWrapper from "./WidgetWrapperByType/WidgetWrapper"
import { CommonWidgetWrapperProps } from "./WidgetWrapperTypes"
import { useRecoilValue } from "recoil"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"


const WidgetWrapperSelector = (props: CommonWidgetWrapperProps) => {

	const { type, } = useRecoilValue(selectorsWidgets.outputById(props.idRef.widgetId))

	if (type === DisplayWidgetType.Text) {
		return (props.children)
	}

	return (
		<WidgetWrapper {...props} />
	)
}

export default WidgetWrapperSelector
