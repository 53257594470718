/* eslint-disable max-len */
import React from "react"
import Styles from "./InstagramFooter.module.scss"
import InstagramLogo from "deblank-common/src/assets/images/social-media-icons/instagram.svg"
import CloseIcon from "deblank-common/src/assets/images/icons/cross.svg"

type Props = {
	onClose?: () => void,
}

const InstagramFooter = (props: Props) => {
	return (
		<footer className={Styles.footer}>
			<div className={Styles.gradient_border} />
			<div className={Styles.content}>
				<InstagramLogo className={Styles.logo} />
				<p className={Styles.message}>
					Follow our private Instagram <a href="https://instagram.com/vip_deblank" target="_blank" className={Styles.link} rel="noreferrer">@vip_deblank</a> for updates exclusively for Alpha testers!
				</p>
			</div>

			{props.onClose
				&& <button onClick={props.onClose} className={Styles.close_button}>
					<CloseIcon />
				</button>
			}
		</footer>
	)
}

export default InstagramFooter