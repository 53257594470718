import { useContext } from "react"
import { useRecoilValue } from "recoil"
import { widgetAction } from "../../pages/AssistantPage/components/CurrentConversation/Widgets/WidgetsTypes"
import PinIcon from "deblank-common/src/assets/images/icon-library/pin.svg"
import UnpinIcon from "deblank-common/src/assets/images/icon-library/pinned.svg"
import OpenExternalIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { DisplayWidgetType, IdRefWidgetWithPaginationItem } from "deblank-api-types"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { selectorsPinned } from "@/recoil/ConversationsRecord/Selectors/Pinned"
import { settersPinned } from "@/recoil/ConversationsRecord/Setters/Pinned"


const usePresetActions = (params: {
	idRef: IdRefWidgetWithPaginationItem,
	widgetType: DisplayWidgetType,
}) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const isOutputPinned = useRecoilValue(selectorsPinned.isOutputPinned({
		idRef: params.idRef,
	}))

	const togglePinItem = settersPinned.useTogglePinItem()
	const togglePinnedSidebar = settersUiTemporary.useSetIsOpenPinned()
	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()

	const handleTogglePin = () => {
		togglePinItem({
			idRef: params.idRef,
		})

		trackConversationEvent({
			eventName: isOutputPinned ? "Unpin" : "Pin",
			widgetType: params.widgetType,
			outputId: params.idRef.widgetId,
			responseId: params.idRef.messageId,
		})

		togglePinnedSidebar({ isOpen: true, })
	}

	const handleSendToChat = () => {
		setOutputIdSelected({
			outputIdSelected: params.idRef,
		})
		trackConversationEvent({
			eventName: "SendToChat",
			widgetType: params.widgetType,
			outputId: params.idRef.widgetId,
			responseId: params.idRef.messageId,
		})
	}

	const pinPresetAction: widgetAction = {
		text: isOutputPinned ? "Unpin" : "Pin",
		icon: isOutputPinned ? UnpinIcon : PinIcon,
		onClick: handleTogglePin,
	}

	const sendToChatPresetAction: widgetAction = {
		text: "Send to chat",
		icon: OpenExternalIcon,
		onClick: handleSendToChat,
	}

	return {
		pinPresetAction,
		sendToChatPresetAction,
	}

}


export default usePresetActions
