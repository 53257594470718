import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { produce } from "immer"
import { useContext } from "react"
import { useRecoilCallback } from "recoil"
import { v4 } from "uuid"
import { atomConversationsRecord } from "../Atom"
import { Message, PendingMessage } from "../Types"
import { defaultOutputsIdCounts } from "../utils/defaultOutputsIdCounts"


const useUpdateActiveConversationIndex = () => {
	return useRecoilCallback(({ set, }) => (params: {
		newIndex: number,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.activeConversationIndex = params.newIndex
			draft.isNewChat = false
		}))
	})
}

const useNewChat = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	return useRecoilCallback(({ set, }) => () => {
		trackConversationEvent({
			eventName: "NewChat",
		})

		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.isNewChat = true
		}))
	})
}

const useAddNewConversation = () => {
	return useRecoilCallback(({ set, }) => (userMessage: string,) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const message: Message = {
				id: "message-0",
				state: "loading",
				userMessage: {
					message: userMessage,
					idRefs: null,
				},
				outputsIdsByIterations: [],
				relatedActions: [],
				generatedByWidget: null,
			}
			const pendingMessage: PendingMessage = {
				messageId: "message-0",
				userMessage: {
					message: userMessage,
					idRefs: null,
				},
				isAction: false,
				isEdit: false,
				currentPage: null,
			}

			draft.conversations.push({
				id: v4(),
				creationDate: new Date().toISOString(),
				internalState: undefined,
				outputs: {},
				pinnedItems: [],
				messages: { "message-0": message, },
				pendingMessage: pendingMessage,
				pendingNotifications: null,
				debugHistory: [],
				outputsIdCounts: defaultOutputsIdCounts,
				colorsPlaygroundIdRef: null,
				fontPlaygroundIdRef: null,
				imageModalIdRef: null,
			})
			draft.activeConversationIndex = draft.conversations.length - 1
			draft.isNewChat = false
		}))
	})
}

const useClearPendingNotificationsInActiveConversation = () => {
	return useRecoilCallback(({ set, }) => () => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const activeConversation = draft.conversations[draft.activeConversationIndex]
			activeConversation.pendingNotifications = null
		}))
	})

}

export const settersConversations = {
	useUpdateActiveConversationIndex,
	useAddNewConversation,
	useClearPendingNotificationsInActiveConversation,
	useNewChat,
}
