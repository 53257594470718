import { v4 } from "uuid"

const savedImages: { [id: string]: string, } = {}

//Mock save images

export const saveImage = (params: {
	dataUrl: string,
}) => {
	const id = v4()

	savedImages[id] = params.dataUrl

	return id
}

export const getImage = (params: {
	id: string,
}) => {
	return {
		dataUrl: savedImages[params.id],
	}
}
