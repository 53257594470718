import { MockupCategory } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, CustomSelectValue, RelatedActionContext, RelatedActionData,
	SelectType, TwoStepAction
} from "../RelatedActionsTypes"
import PackagingImage from "@/assets/colorMockupCategory/packaging_option.png"
import PosterImage from "@/assets/colorMockupCategory/poster_option.png"
import PrintImage from "@/assets/colorMockupCategory/print_option.png"
import UIImage from "@/assets/colorMockupCategory/ui_option.png"
import { ColorsRelatedActions, DisplayWidgetType, EntryMockupColorsResultWithoutId } from "deblank-api-types"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"

type StepState1 = ColorSelectValue

type StepState2 = CustomSelectValue

type RelatedState = TwoStepAction<StepState1, StepState2>

const Number_Of_Mockups = 3

export const Color_Related_Action_Config_3
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			(data: RelatedState) => {
				const output: EntryMockupColorsResultWithoutId = {
					type: DisplayWidgetType.MockupColors,
					metadata: {
						category: data.step2.value as MockupCategory,
						palette: data.step1.widgetItemDetails.palette,
					},
					data: {
						isPaginated: true,
						pages: [],
					},
				}

				context.onAddCreateNotifications({
					notifications: [
						{
							relatedActions: [],
							generatedByWidget: context.idRef,
							output,
							userMessage: {
								message: "Apply Color Palette on a Mockup",
								idRefs: [data.step1.idRef,],
							},
							internalMessageForAgent: `The user created ${Number_Of_Mockups} mockups of
 the category ${data.step2.value} using the following colors: ${data.step1.widgetItemDetails.palette}.`,
						},
					],
				})

			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
		}

		const handleStep2 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step2
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: CustomSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step2: {
								value: data.value,
							},
						}
					})
				},
		}

		return {
			id: ColorsRelatedActions.ApplyColorPaletteOnMockup,
			label: "Apply Color Palette on a Mockup",
			config: {
				processAction: handleProcessAction,
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
					step2: {
						value: "",
					},
				},
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
					{
						title: "Select the type of mockup",
						componentType: ConfigStepType.select,
						selectType: SelectType.custom,
						options: [
							{
								label: "Packaging",
								value: MockupCategory.packaging,
								thumbnailImage: PackagingImage,
							},
							{
								label: "UI",
								value: MockupCategory.ui,
								thumbnailImage: UIImage,
							},
							{
								label: "Branding",
								value: MockupCategory.branding,
								thumbnailImage: PrintImage,
							},
							{
								label: "Poster",
								value: MockupCategory.posters,
								thumbnailImage: PosterImage,
							},
						],
						required: true,
						onSelect: handleStep2.set,
						onGetValue: handleStep2.get,
					},
				],
			},
		}

	})()
