import React from "react"
import Styles from "./InitialMessage.module.scss"
import PromptToolbar from "../PromptToolbar/PromptToolbar"
import { m } from "framer-motion"

const InitialMessage = () => {
	return (
		<m.div
			initial={{ opacity: 0, scale: 0.9, }}
			animate={{ opacity: 1, scale: 1, }}
			exit={{ opacity: 0, scale: 0.9, }}
			className={Styles.container}
			transition={{ duration: 0.1, ease: "easeOut", }}
		>
			<h1 className={Styles.message}>Your <span>starting point.</span></h1>
			<PromptToolbar />
		</m.div>
	)
}

export default InitialMessage