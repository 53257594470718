import { Font_Related_Action_Config_1 } from "./Font_Related_Action_Config_1"
import { Font_Related_Action_Config_2 } from "./Font_Related_Action_Config_2"
import { Font_Related_Action_Config_3 } from "./Font_Related_Action_Config_3"
// import { Font_Related_Action_Config_4 } from "./Font_Related_Action_Config_4"

export const FontRelatedActions = {
	[Font_Related_Action_Config_1.id]: Font_Related_Action_Config_1,
	[Font_Related_Action_Config_2.id]: Font_Related_Action_Config_2,
	[Font_Related_Action_Config_3.id]: Font_Related_Action_Config_3,
	//Se apaga el font accessibilty hasta tener nueva definición
	//[Font_Related_Action_Config_4.id]: Font_Related_Action_Config_4,
}

export const FontPairingRelatedActions = {
	[Font_Related_Action_Config_1.id]: Font_Related_Action_Config_1,
}
