import { selector, selectorFamily } from "recoil"
import { atomFontsCache } from "./Atom"
import { FontFileCache, FontFromCache, FontsById, } from "./Types"
import { FontUtils } from "@/utils/FontUtils"

const prefixKey = (key: string) => `FontCache_${key}`

const pendingSlugs = selector<string[]>({
	key: prefixKey("pendingFonts"),
	get: ({ get, }) => {
		const fonts = get(atomFontsCache).pendingSlugs
		return fonts
	},
})

const fontList = selector<FontFileCache[]>({
	key: prefixKey("fontList"),
	get: ({ get, }) => {
		const fonts = get(atomFontsCache).fonts
		return Object.values(fonts)
	},
})

const fontDictionary = selector<FontsById>({
	key: prefixKey("fontDictionary"),
	get: ({ get, }) => {
		const fonts = get(atomFontsCache).fonts
		return fonts
	},
})

const fontFromCache = selectorFamily<FontFromCache | null, string | undefined>({
	key: prefixKey("fontFromCache"),
	get: (slug) => ({ get, }) => {
		if (!slug) {
			return null
		}

		const fonts = get(atomFontsCache).fonts
		const font = fonts[slug]

		if (font) {
			return {
				fontCSSProperties: {
					fontFamily: `${font.name}, ${font.type}`,
				},
				metadata: {
					name: font.name,
					slug: font.slug,
					vendor: font.vendor,
					type: font.type,
					hasItalics: font.hasItalics,
					use: font.use,
					weights: font.weights,
					isCSSLoaded: font.isCSSLoaded,
					weightsCode: font.weights.map(weight => weight.code),
					linkToVendor: `${FontUtils.vendorLinks[font.vendor]}${font.slug}`,
				},
			}
		} else {
			return null
		}
	},
})


export const selectorsFontCache = {
	fontList,
	pendingSlugs,
	fontDictionary,
	fontFromCache,
}
