/* eslint-disable no-nested-ternary */
import React, { useState } from "react"
import Styles from "./InputPercentage.module.scss"
import classNames from "classnames"
import Spinner from "../Spinner/Spinner"

type CustomSelectStyles = {
	inputVariant: "current_color",
}

type Props = {
	value: number,
	placeholder?: string,
	disabled?: boolean,
	isLoading?: boolean,
	customStyles?: CustomSelectStyles,
	onChange: (value: number) => void,
	min: number,
	max: number,
	step?: number,
	icon?: {
		SVGComponent: React.FunctionComponent,
	},
}

const InputPercentage = React.forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLInputElement>,
) => {

	const [inputValue, setInputValue,] = useState(props.value.toString())

	const labelClasses = classNames({
		[Styles.label]: true,
		[Styles.disabled]: props.disabled,
	})

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const rawValue = e.target.value
		const numericValue = parseFloat(rawValue)

		if (rawValue === "") {
			setInputValue("")
		} else {
			if (!isNaN(numericValue)) {
				setInputValue(numericValue.toString())
			}
			if ((numericValue < props.min)
				|| (numericValue > props.max)
			) {
				props.onChange(numericValue < props.min ? props.min : props.max)
			} else {
				props.onChange(numericValue)
			}
		}
	}

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const rawValue = e.target.value
		const numericValue = parseFloat(rawValue)

		if (isNaN(numericValue) || rawValue === "") {
			setInputValue(props.min.toString())
			props.onChange(props.min)
		} else if (numericValue < props.min) {
			setInputValue(props.min.toString())
			props.onChange(props.min)
		} else if (numericValue > props.max) {
			setInputValue(props.max.toString())
			props.onChange(props.max)
		}
	}



	return <label className={labelClasses}>

		<div className={Styles.content_icon}>
			{props.isLoading
				? <Spinner />
				: props.icon
					? <props.icon.SVGComponent />
					: <></>
			}
		</div>

		<div className={Styles.content_input}>
			<input type="number"
				className={Styles.input}
				value={inputValue}
				autoComplete="off"
				spellCheck="false"
				min={props.min}
				max={props.max}
				step={props.step || 1}
				disabled={props.disabled}
				ref={ref}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
			/>
			<span className={Styles.percentage}>
				<span className={Styles.character}>
					%
				</span>
			</span>
		</div>
	</label>
})

InputPercentage.displayName = "InputPercentage"

export default InputPercentage
