import { Output } from "deblank-api-types"
import { selectorFamily, selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { OutputsById } from "../Types"
import { prefixRecoilKey } from "./Common"

const outputById = selectorFamily<Output, string>({
	key: prefixRecoilKey("outputById"),
	get: (id) => ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]!

		return currentConversation.outputs![id]
	},
})

const getOutputListInCurrentConversation = selector<OutputsById | null>({
	key: prefixRecoilKey("getOutputListInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]

		return currentConversation && currentConversation.outputs
			? currentConversation.outputs
			: null
	},
})

const activeConversationOutputs = selector<OutputsById>({
	key: prefixRecoilKey("activeConversationOutputs"),
	get: ({ get, }) => {
		const { activeConversationIndex, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationIndex]!

		return currentConversation.outputs!
	},
})


export const selectorsWidgets = {
	outputById,
	getOutputListInCurrentConversation,
	activeConversationOutputs,
}
