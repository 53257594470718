/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import TabletMask from "./images/tablet_mask.png"
import Tablet from "./images/tablet.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"
// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const mockupWidth = 1495.5
const mockupHeight = 1064.5

const mockupId = ColorUIMockupID.colors_ui_mockup_1

type ColorAssignmentType = {
	design: {
		colorA: string,
		colorB: string,
		colorC: string,
		colorD: string,
		upperButtonColor: string,
		lowerButtonColor: string,
		middleCalendarColor: string,
	},
	mainBackgroundColor: string,

}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, getTextColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = "#FFFFFF"

	const colorsWithoutBackground = colors.filter((color) => color !== backgroundColor)

	const designColorPalette = getColorPalette({
		colors: colorsWithoutBackground,
		backgroundColor: backgroundColor,
		minColors: params.minColors,

	})

	const palette = await getGroupedColors({
		palette: designColorPalette,
		imageSvg: Mockup_SVG.simpleDesign,
		backgroundColor: backgroundColor,
		numberOfGroups: 4,
	},)


	const upperButtonColor = getTextColor({ backgroundColor: backgroundColor, colorPalette: ["#000000", "#FFFFFF",], })
	const lowerButtonColor = getTextColor({ backgroundColor: palette.groupA!, colorPalette: ["#000000", "#FFFFFF",], })
	const middleCalendarColor = getTextColor({ backgroundColor: palette.groupC!, colorPalette: ["#000000", "#FFFFFF",], })

	return {
		colorAssignment: {
			design: {
				colorA: palette.groupA!,
				colorB: palette.groupB!,
				colorC: palette.groupC!,
				colorD: backgroundColor,
				upperButtonColor,
				lowerButtonColor,
				middleCalendarColor,
			},
			mainBackgroundColor: backgroundColor,
		},
		openAiCosts: {
			completion_cost: palette.usage!.completion_cost,
			prompt_cost: palette.usage!.prompt_cost,
		},
	}
}


const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 4,
		})

		const [tabletMaskSprite, tabletSprite,] = await MockupUtils.loadAssets([
			TabletMask, Tablet,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundMask = new PIXI.Container()
		const backgroundShape = MockupUtils.drawRect({
			color: colorAssignment.mainBackgroundColor,
			width: mockupWidth,
			height: mockupHeight,
		})
		backgroundMask.addChild(backgroundShape)
		mockupContainer.addChild(backgroundMask)
		mockupContainer.addChild(tabletSprite)

		const getDesign = async () => {
			const designContainer = new PIXI.Container()
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.designSvg(colorAssignment!.design!))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)
			return designContainer
		}
		const uiDesignContainer = new PIXI.Container()
		const uiDesign = await getDesign()
		uiDesignContainer.addChild(tabletMaskSprite)
		uiDesignContainer.mask = tabletMaskSprite
		const designProjection = MockupUtils.project({
			app: app,
			container: uiDesign,
			projectionPoints: {
				topLeft: { x: 230, y: 170, },
				topRight: { x: 1264.5, y: 170, },
				bottomRight: { x: 1264.5, y: 894.5, },
				bottomLeft: { x: 230, y: 894.5, },
			},
		})
		uiDesignContainer.addChild(designProjection)

		mockupContainer.addChild(uiDesignContainer)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!
}

export const Color_Mockup_UI_1: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
