import LoginPage from "../pages/LoginPage/LoginPage"
import { createFileRoute } from "@tanstack/react-router"

type LoginSearchParams = {
	token?: string,
}

export const Route = createFileRoute("/login")({
	component: LoginPage,
	validateSearch: (search: Record<string, unknown>): LoginSearchParams => {
		return {
			token: (search.token as string) || "",
		}
	},
})