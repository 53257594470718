/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react"
import * as PIXI from "pixi.js"
import { ColorPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams, GroupedColorPalette } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import BagLight from "./images/bag_light.png"
import BagMask from "./images/bag_mask.png"
import BagShadow from "./images/bag_shadow.png"
import Placeholder from "./images/placeholder.svg"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 2214 * SCALE

const mockupId = ColorPackagingMockupID.colors_packaging_mockup_3

type ColorAssignmentType = {
	background: { backgroundShape: string, },
	bagDesign: { background: string, colorA: string, colorB: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = "#FFFFFF"

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const paletteFromAi = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)

	const getFilteredPalette = () => {
		let filteredGroups: GroupedColorPalette = {}
		const newColorPalette: string[] = []
		let newColorToAdd = params.minColors - 1
		const { usage, ...AIPalette } = paletteFromAi
		Object.entries(AIPalette).forEach(group => {
			if (designColorPalette.includes(group[1])) {
				newColorPalette.push(group[1])
			} else {
				const newColorIndex = designColorPalette.length >= newColorToAdd + 1 ? newColorToAdd : Math.floor(Math.random() * designColorPalette.length)
				newColorToAdd++
				const newColor = designColorPalette[newColorIndex]
				newColorPalette.push(newColor)
			}
		})
		newColorPalette.forEach((color, index) => {
			filteredGroups = {
				...filteredGroups,
				[`group${String.fromCharCode(65 + index)}`]: color,
			}
		})
		return filteredGroups
	}

	const palette = getFilteredPalette()

	return {
		colorAssignment: {
			background: { backgroundShape: palette.groupB!, },
			bagDesign: { background: palette.groupA!, colorA: palette.groupB!, colorB: palette.groupC!, },
		},
		openAiCosts: {
			completion_cost: paletteFromAi.usage!.completion_cost,
			prompt_cost: paletteFromAi.usage!.prompt_cost,
		},
	}

}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 5,
		})
		const [bagMaskSprite, shadowsSprite,
			lightsSprite,] = await MockupUtils.loadAssets([
				BagMask, BagShadow, BagLight,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
			rounding: 16,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		// Add background
		const backgroundContainer = new PIXI.Container()
		const backgroundTexture = await PIXI.Texture.fromURL(Mockup_SVG.backgroundSVG(colorAssignment.background))
		const backgroundSprite = new PIXI.Sprite(backgroundTexture)
		backgroundContainer.addChild(backgroundSprite)

		mockupContainer.addChild(backgroundContainer)

		//Add shadowSprite
		shadowsSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadowsSprite)

		// Add bag
		const bagDesignContainer = new PIXI.Container()
		const bagDesignTexture = await PIXI.Texture.fromURL(Mockup_SVG.designSVG(colorAssignment.bagDesign))
		const bagDesignSprite = new PIXI.Sprite(bagDesignTexture)
		bagDesignContainer.addChild(bagDesignSprite)

		const bagProjection = MockupUtils.project({
			app: app,
			container: bagDesignContainer,
			projectionPoints: {
				topLeft: { x: 1065 * SCALE, y: 356 * SCALE, },
				topRight: { x: 1922 * SCALE, y: 355 * SCALE, },
				bottomRight: { x: 1874 * SCALE, y: 1840 * SCALE, },
				bottomLeft: { x: 1128 * SCALE, y: 1840 * SCALE, },
			},
		})

		const bagProjectionContainer = new PIXI.Container()
		bagProjectionContainer.addChild(bagProjection)
		bagProjectionContainer.addChild(bagMaskSprite)
		bagProjectionContainer.mask = bagMaskSprite
		mockupContainer.addChild(bagProjectionContainer)

		//Add lightsSprite

		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(lightsSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!
}

export const Color_Mockup_Packaging_3: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
