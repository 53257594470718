export const getAccessors = <T>(
	keyParam: string,
	getStorage: () => Storage,
	getInitial: () => T
) => {
	const keyStorage = `${keyParam}`

	const getter = (): T => {
		const storage = getStorage()
		const content = storage[keyStorage]
		if (content) {
			return JSON.parse(content)
		} else {
			const value: T = getInitial()
			if (value) {
				storage[keyStorage] = JSON.stringify(value)
			}
			return value
		}
	}

	const setter = (content: T) => {
		const storage = getStorage()
		storage[keyStorage] = JSON.stringify(content)
	}

	const deleter = () => {
		const storage = getStorage()
		storage.removeItem(keyStorage)
	}

	return [getter, setter, deleter,] as const
}
