import React, { useState, useEffect } from "react"
import Styles from "./InputSelect.module.scss"
import { InputSelectValue } from "../../../../../RelatedActionsConfigs/RelatedActionsTypes"

type Props = {
	onSelect: (value: InputSelectValue,) => void,
	value?: InputSelectValue,
	onNextAction?: () => void,
	placeholder?: string,
}

const InputSelect = (props: Props) => {
	const [value, setValue,] = useState("")

	useEffect(() => {
		props.onSelect({ value, })
	}, [value,])

	return (
		<li className={Styles.wrapper}>
			<label>
				<input
					type="text"
					className={Styles.input}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder={props.placeholder ?? ""}
				/>
			</label>
		</li>
	)
}

export default InputSelect
