import React from "react"
import Styles from "./HistoryItem.module.scss"
import classNames from "classnames"
import { ChatHistory } from "../ConversationHistory"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"

type Props = {
	selected: boolean,
	data: ChatHistory,
}

const HistoryItem = (props: Props) => {

	const itemClasses = classNames(Styles.history_item, {
		[Styles.history_item_active]: props.selected,
	})

	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const changeCurrentConversation = settersConversations.useUpdateActiveConversationIndex()

	const handleChangeConversation = () => {
		changeCurrentConversation({ newIndex: props.data.index, })
	}

	return (
		<li>
			<button
				className={itemClasses}
				disabled={props.selected || someMessageIsLoading}
				onClick={handleChangeConversation}
			>
				{props.data.name}
			</button>
		</li>
	)
}

export default HistoryItem
