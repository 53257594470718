import React, { useEffect } from "react"
import Styles from "./WidgetRelatedActionFont.module.scss"
import { useRecoilValue } from "recoil"
import classNames from "classnames"
import FontSelectCard from "./FontSelectCard/FontSelectCard"
import { FontSelectValue } from "../WidgetFontsTypes"
import { DisplayWidgetType, IdRefWidget } from "deblank-api-types"
import { selectorsRelatedActions } from "@/recoil/ConversationsRecord/Selectors/RelatedActions"


type Props = {
	value: FontSelectValue,
	onSelect: (value: FontSelectValue,) => void,
	onNextAction: () => void,
	idRef: IdRefWidget,
}

const WidgetRelatedActionFont = (props: Props) => {
	const widgetItems = useRecoilValue(selectorsRelatedActions.widgetItemsForRelatedActions(props.idRef))

	// If there is only one option, select it and go to the next action
	// This is going to set the state and activate a flag that executes the next action.
	useEffect(() => {
		if (widgetItems && widgetItems.length === 1) {
			const widget = widgetItems[0].type === DisplayWidgetType.Fonts && widgetItems[0]
			if (widget) {
				props.onSelect({
					idRef: widget.idRef,
					widgetItemDetails: widget.widgetData,
					metadata: widget.metadata,
				})
				props.onNextAction()
			}
		}
	}, [widgetItems,])


	const handleSelect = (
		idRef: FontSelectValue["idRef"],
		widgetItemDetails: FontSelectValue["widgetItemDetails"],
		metadata: FontSelectValue["metadata"],
	) => (e: React.MouseEvent) => {

		e.preventDefault()
		e.stopPropagation()
		props.onSelect({
			idRef,
			widgetItemDetails,
			metadata,
		})
	}

	return (
		<>
			{widgetItems && widgetItems.map((widget) => {
				if (widget.type !== DisplayWidgetType.Fonts) {
					return null
				}

				const fontClass = classNames({
					[Styles.font]: true,
					[Styles.font_selected]: props.value.idRef.widgetItemId === widget.idRef.widgetItemId,
				})
				return (
					<li key={widget.idRef.widgetItemId} className={Styles.list_item}>
						<button
							onClick={handleSelect(
								widget.idRef,
								widget.widgetData,
								widget.metadata,
							)}
							className={fontClass}
						>
							<FontSelectCard fontData={widget.widgetData} />
						</button>
					</li>
				)
			})}
		</>
	)
}

export default WidgetRelatedActionFont
