type JsonValue = {
	[key: string]: string | number | boolean | JsonValue | JsonValue[],
}

export function parseJsonProperties<T extends JsonValue | JsonValue[]>(obj: T): T {
	// Check if the input is not an object or is null
	if (typeof obj !== "object" || obj === null) {
		return obj
	}

	// If obj is an array, create a new array and apply the function recursively
	if (Array.isArray(obj)) {
		return obj.map(item => parseJsonProperties(item)) as T
	}

	// Create a new object to hold the parsed properties
	const result: JsonValue = {}

	// Iterate over each property in the object
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key]

			// Check if the property's value is a string that could be JSON
			if (typeof value === "string") {
				try {
					// Try parsing it as JSON
					const parsed = JSON.parse(value)

					// If successful, recursively parse the resulting object or array
					result[key] = typeof parsed === "object" ? parseJsonProperties(parsed) : value
				} catch (error) {
					// If there's a parsing error, just copy the original string
					result[key] = value
				}
			} else if (typeof value === "object") {
				// Recursively parse if the value is an object or an array
				result[key] = parseJsonProperties(value as JsonValue | JsonValue[])
			} else {
				result[key] = value as string | number | boolean | JsonValue | JsonValue[]
			}
		}
	}

	return result as T
}
