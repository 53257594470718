/* eslint-disable max-len */
import { DisplayWidgetType, OutputWithoutIds, PendingNotificationCreateWithoutId } from "deblank-api-types"

export const createRegenerateNotificationMessage = (type: DisplayWidgetType): Omit<PendingNotificationCreateWithoutId, "type"> => {
	let outputMessage: string

	switch (type) {
		case DisplayWidgetType.Colors:
			outputMessage = `I've seen you regenerated a few times. Could you share more details to help me improve the results?

Are there specific colors or tones you prefer (e.g., warm, cool, neutral)? Is there a particular mood or emotion you want the color palette to convey?`
			break
		case DisplayWidgetType.Fonts:
			outputMessage = `I've seen you regenerated a few times. Could you share more details to help me improve the results?

Are there any specific fonts or styles you prefer (e.g., serif, sans- serif, script)? Do you have examples of fonts you like?`
			break
		case DisplayWidgetType.Images:
			outputMessage = `I've noticed you've regenerated a few times. Could you provide more details to help me enhance the results?

Are there any specific styles or themes you prefer (e.g., abstract, realistic, minimalistic)? Are there any colors, elements, or details that are essential to include or avoid in the images?`
			break
		case DisplayWidgetType.MockupFonts:
		case DisplayWidgetType.MockupColors:
			outputMessage = `I've noticed you've regenerated a few times. Could you provide more details to help me enhance the results?

Would you like to explore other types of mockups (Print/Branding, Posters, Packaging or UI Design)?`
			break
		default:
			outputMessage = "I've seen you regenerated a few times. Could you share more details to help me improve the results?"
			break
	}

	const output: OutputWithoutIds = {
		type: DisplayWidgetType.Text,
		metadata: null,
		data: {
			isPaginated: false,
			results: [
				{
					title: "Follow-up question",
					message: outputMessage,
				},
			],
		},
	}

	return {
		internalMessageForAgent: "The user has regenerated the results 3 times, user asked for more details",
		output,
		userMessage: null,
		relatedActions: [],
	}
}
