import React from "react"
import Styles from "./WidgetBubbleUserMessageImage.module.scss"
import { CommonWidgetImageItemProps } from "../WidgetImagesTypes"


const WidgetBubbleUserMessageImage = (props: CommonWidgetImageItemProps) => {

	return (
		<figure className={Styles.selected_image}>
			<img src={props.widgetItemDetails.imageUrl} alt="ai generated" />
		</figure>
	)
}

export default WidgetBubbleUserMessageImage
