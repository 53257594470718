/* eslint-disable no-useless-escape */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import Mask1 from "./images/mask_1.png"
import Mask2 from "./images/mask_2.png"
import Mask3 from "./images/mask_3.png"
import Mask4 from "./images/mask_4.png"
import Shadow1 from "./images/shadow_1.png"
import Shadow2 from "./images/shadow_2.png"
import Shadow3 from "./images/shadow_3.png"
import TopWatch from "./images/top_watch.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/TUew0SeCWGow3Z5EUILdGt/Mockups?type=design&node-id=1722-3398&mode=design&t=WVp28g8B5eiwr7pH-0

const SCALE = 0.5

const mockupWidth = 2980 * SCALE
const mockupHeight = 1635 * SCALE

const screenDesignWidth = 540 * SCALE
const screenDesignHeight = 660 * SCALE

const mockupId = ColorUIMockupID.colors_ui_mockup_3

type ColorAssignmentType = {
	colorA: string,
	colorB: string,
	colorC: string,
	colorD: string,
	colorE: string,
	colorF: string,
	textColor: string,
	buttonTextColor: string,
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, getTextColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = "#FFFFFF"

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const palette = await getGroupedColors({
		palette: designColorPalette,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)

	return {
		colorAssignment: {
			colorA: palette.groupA!,
			colorB: palette.groupB!,
			colorC: palette.groupC!,
			colorD: palette.groupD!,
			colorE: palette.groupE!,
			colorF: palette.groupF!,
			textColor: getTextColor({
				backgroundColor: palette.groupF!,
				colorPalette: ["#FFFFFF", "#000000",],
			}),
			buttonTextColor: getTextColor({
				backgroundColor: palette.groupB!,
				colorPalette: ["#FFFFFF", "#000000",],
			}),
		},
		openAiCosts: {
			completion_cost: palette.usage!.completion_cost,
			prompt_cost: palette.usage!.prompt_cost,
		},
	}


}

const getMockupImage = async (
	props: GetMockupImageProps<ColorsTemplateMockupParams>
) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 7,
		})

		const [watchMask_1_Sprite, watchMask_2_Sprite,
			watchMask_3_Sprite, watchMask_4_Sprite,
			shadow_1_Sprite, shadow_2_Sprite,
			shadow_3_Sprite, top_watch_Sprite,
		] = await MockupUtils.loadAssets([
			Mask1, Mask2, Mask3, Mask4,
			Shadow1, Shadow2, Shadow3, TopWatch,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#FFFFFF",
			rounding: 16,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundContainer = new PIXI.Container()
		const backgroundShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#F5F5F5",
		})
		backgroundContainer.addChild(backgroundShape)
		mockupContainer.addChild(backgroundContainer)

		const getFirstScreenDesign = async () => {
			const designContainer = new PIXI.Container()
			designContainer.width = screenDesignWidth
			designContainer.height = screenDesignHeight
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.firstScreenSvg({
				colorF: colorAssignment!.colorF,
				text: colorAssignment!.textColor,
			}).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			const container = new PIXI.Container()
			container.width = mockupWidth
			container.height = mockupHeight

			const projection = MockupUtils.project({
				app: app,
				container: designContainer,
				projectionPoints: {
					topLeft: { x: 233 * SCALE, y: 504 * SCALE, },
					topRight: { x: 767 * SCALE, y: 504 * SCALE, },
					bottomRight: { x: 767 * SCALE, y: 1130 * SCALE, },
					bottomLeft: { x: 233 * SCALE, y: 1130 * SCALE, },
				},
			})


			container.addChild(watchMask_1_Sprite)
			container.addChild(projection)
			container.mask = watchMask_1_Sprite

			return container
		}

		const getSecondScreenDesign = async () => {
			const designContainer = new PIXI.Container()
			designContainer.width = screenDesignWidth
			designContainer.height = screenDesignHeight
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.secondScreenDesignSvg({
				colorF: colorAssignment!.colorF,
				colorB: colorAssignment!.colorB,
				buttonLabel: colorAssignment!.buttonTextColor,
				textColor: colorAssignment!.textColor,
			}).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			const container = new PIXI.Container()
			container.width = mockupWidth
			container.height = mockupHeight

			const projection = MockupUtils.project({
				app: app,
				container: designContainer,
				projectionPoints: {
					topLeft: { x: 955 * SCALE, y: 494 * SCALE, },
					topRight: { x: 1495 * SCALE, y: 494 * SCALE, },
					bottomRight: { x: 1495 * SCALE, y: 1154 * SCALE, },
					bottomLeft: { x: 955 * SCALE, y: 1154 * SCALE, },
				},
			})


			container.addChild(watchMask_2_Sprite)
			container.addChild(projection)
			container.mask = watchMask_2_Sprite

			return container
		}

		const getThirdScreenDesign = async () => {
			const designContainer = new PIXI.Container()
			designContainer.width = screenDesignWidth
			designContainer.height = screenDesignHeight
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.thirdScreenDesignSvg({
				colorF: colorAssignment!.colorF,
				colorB: colorAssignment!.colorB,
				buttonLabel: colorAssignment!.buttonTextColor,
				textColor: colorAssignment!.textColor,
			}).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			const container = new PIXI.Container()
			container.width = mockupWidth
			container.height = mockupHeight

			const projection = MockupUtils.project({
				app: app,
				container: designContainer,
				projectionPoints: {
					topLeft: { x: 1589 * SCALE, y: 494 * SCALE, },
					topRight: { x: 2129 * SCALE, y: 494 * SCALE, },
					bottomRight: { x: 2129 * SCALE, y: 1154 * SCALE, },
					bottomLeft: { x: 1589 * SCALE, y: 1154 * SCALE, },
				},
			})


			container.addChild(watchMask_3_Sprite)
			container.addChild(projection)
			container.mask = watchMask_3_Sprite

			return container
		}

		const getFourthScreenDesign = async () => {
			const designContainer = new PIXI.Container()
			designContainer.width = screenDesignWidth
			designContainer.height = screenDesignHeight
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.fourthScreenDesignSvg({
				colorB: colorAssignment!.colorB,
			}).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			const container = new PIXI.Container()
			container.width = mockupWidth
			container.height = mockupHeight

			const projection = MockupUtils.project({
				app: app,
				container: designContainer,
				projectionPoints: {
					topLeft: { x: 2222 * SCALE, y: 494 * SCALE, },
					topRight: { x: 2762 * SCALE, y: 494 * SCALE, },
					bottomRight: { x: 2762 * SCALE, y: 1154 * SCALE, },
					bottomLeft: { x: 2222 * SCALE, y: 1154 * SCALE, },
				},
			})


			container.addChild(watchMask_4_Sprite)
			container.addChild(projection)
			container.mask = watchMask_4_Sprite

			return container
		}



		const firstScreenDesign = await getFirstScreenDesign()
		const secondScreenDesign = await getSecondScreenDesign()
		const thirdScreenDesign = await getThirdScreenDesign()
		const fourthScreenDesign = await getFourthScreenDesign()

		mockupContainer.addChild(firstScreenDesign)
		mockupContainer.addChild(secondScreenDesign)
		mockupContainer.addChild(thirdScreenDesign)
		mockupContainer.addChild(fourthScreenDesign)


		//Add shadows
		shadow_1_Sprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(shadow_1_Sprite)
		shadow_2_Sprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadow_2_Sprite)
		shadow_3_Sprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadow_3_Sprite)

		// Add top watch
		mockupContainer.addChild(top_watch_Sprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!

}

export const Color_Mockup_UI_3: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: "TODO",
}
