import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { ImagesRelatedActions } from "deblank-api-types"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"

type Step1 = ImageSelectValue[]

type Image_Related_3_State = OneStepAction<Step1>

export const Image_Related_Action_Config_3
	= ((): RelatedActionData<Image_Related_3_State> => {

		const handleProcessAction = (context: RelatedActionContext<Image_Related_3_State>) =>
			// eslint-disable-next-line require-await
			async () => {
				const imageIds = context.currentValue.step1.map(image => image.idRef)

				await context.onAddPendingMessage({
					message: "Upscale the selected images.",
					idRefs: imageIds,
					// prompt: `Upscale the selected images. The selected images are: ${imageIds.join(", ")}.`,
					prompt: "Upscale the selected images.",
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Image_Related_3_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Image_Related_3_State>) =>
				(data: ImageSelectValue[]) => {
					context.onSetState(prev => ({
						...prev,
						step1: data,
					}))
				},
		}

		return {
			id: ImagesRelatedActions.UpscaleImages,
			label: "Upscale Images",
			config: {
				defaultState: {
					step1: [],
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select images to upscale",
						componentType: ConfigStepType.select,
						selectType: SelectType.imagesMultiSelect,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
					},
				],
			},
		}

	})()
