import React from "react"
import Styles from "./LoadingView.module.scss"
import Spinner, { SpinnerSize } from "../Spinner/Spinner"

type Props = {
	showMessage?: boolean,
}

const LoadingView = (props: Props) => {
	return (
		<section className={Styles.wrapper}>
			<div className={Styles.content}>
				<Spinner size={SpinnerSize.Small} />
				{props.showMessage && (
					<>
						<p className={Styles.main_message}>We are verifying your info</p>
						<p className={Styles.secondary_message}>This shouldn’t take long</p>
					</>
				)}
			</div>
		</section>
	)
}

export default LoadingView
